define("shop/pods/components/elements/search-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "syaSrbmi",
    "block": "[[[11,0],[16,0,[29,[\"nav-item__search-btn \",[52,[30,1],\"is-active\"]]]],[4,[38,1],[\"click\",[30,0,[\"toggle\"]]],null],[12],[1,\"\\n  \"],[10,3],[14,0,\"button button--no-border\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@icon\"],[\"fas fa-search\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@showShopSearchFilter\"],false,[\"if\",\"on\",\"elements/element-icon\"]]",
    "moduleName": "shop/pods/components/elements/search-btn/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});