define("shop/pods/components/social-login/component", ["exports", "@ember/component", "@ember/service", "@ember/object"], function (_exports, _component, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    router: (0, _service.inject)(),
    configService: (0, _service.inject)('config'),
    allowGuest: true,
    isZizz: (0, _object.computed)('website.shopKey', function () {
      var _this$website, _this$website$_data, _this$website2;

      if (((_this$website = this.website) === null || _this$website === void 0 ? void 0 : (_this$website$_data = _this$website._data) === null || _this$website$_data === void 0 ? void 0 : _this$website$_data._key) === '852189325') {
        return true;
      }

      if (((_this$website2 = this.website) === null || _this$website2 === void 0 ? void 0 : _this$website2.shopKey) === '852277271') {
        return true;
      }

      return false;
    }),
    actions: {
      continueAsGuest() {
        return this.router.transitionTo('shop.checkout.details');
      }

    }
  });

  _exports.default = _default;
});