define("shop/pods/structured-data/service", ["exports", "@ember/service", "@ember/object", "ramda", "shop/utils/nventor"], function (_exports, _service, _object, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let StructuredDataService = (_dec = (0, _service.inject)('website'), (_class = class StructuredDataService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "websiteService", _descriptor, this);
    }

    website({
      data
    }) {
      const url = window.location.origin;
      const path = window.location.pathname; // @TODO: Should re-enable when adding no crawl headers on response
      // const potentialAction = this.getPotentialActions({ url, path })

      const structuredData = {
        '@type': 'WebSite',
        url,
        name: (data === null || data === void 0 ? void 0 : data.name) || '',
        alternateName: (data === null || data === void 0 ? void 0 : data.name) || '' // potentialAction

      }; // description

      const description = data === null || data === void 0 ? void 0 : data.description;

      if (description) {
        structuredData.description = description;
      } // sameAs


      const sameAs = [];
      const youtubeUrl = data === null || data === void 0 ? void 0 : data.youtubeUrl;

      if (youtubeUrl) {
        sameAs.push(youtubeUrl);
      }

      const instagramUrl = data === null || data === void 0 ? void 0 : data.instagramUrl;

      if (instagramUrl) {
        sameAs.push(instagramUrl);
      }

      const facebookUrl = data === null || data === void 0 ? void 0 : data.facebookUrl;

      if (facebookUrl) {
        sameAs.push(facebookUrl);
      }

      if (_nventor.default.isNotNilOrEmpty(sameAs)) {
        structuredData.sameAs = sameAs;
      } // potentialAction
      // "potentialAction": {
      //   "@type": "SearchAction",
      //   "target": "https://www.example.com/search?q={search_term_string}",
      //   "query-input": "required name=search_term_string"
      // },


      return structuredData;
    }

    getPotentialActions({
      url,
      path
    }) {
      const isShopPath = path.includes('/shop/');

      if (!isShopPath) {
        return {};
      }

      const isShopResultPath = path.includes('/results');

      if (isShopResultPath) {
        return {};
      }

      const shopPath = R.pipe(R.split('/'), R.reject(R.anyPass([R.isNil, R.isEmpty])), R.take(2), R.join('/'))(path);
      const urlTemplate = `${url}/${shopPath}/results?query={search_term_string}`;
      return {
        '@type': 'SearchAction',
        target: {
          '@type': 'EntryPoint',
          urlTemplate
        },
        'query-input': 'required name=search_term_string'
      };
    }

    logo({
      data
    }) {
      var _data$shop, _data$shop$_data, _data$shop2, _data$shop2$_data, _data$shop3, _data$shop3$_data;

      const url = window.location.origin;
      const address = (data === null || data === void 0 ? void 0 : data.companyAddress) || (data === null || data === void 0 ? void 0 : (_data$shop = data.shop) === null || _data$shop === void 0 ? void 0 : (_data$shop$_data = _data$shop._data) === null || _data$shop$_data === void 0 ? void 0 : _data$shop$_data.companyAddress);
      const telephone = (data === null || data === void 0 ? void 0 : data.companyTel) || (data === null || data === void 0 ? void 0 : (_data$shop2 = data.shop) === null || _data$shop2 === void 0 ? void 0 : (_data$shop2$_data = _data$shop2._data) === null || _data$shop2$_data === void 0 ? void 0 : _data$shop2$_data.companyTel);
      const description = (data === null || data === void 0 ? void 0 : data.description) || (data === null || data === void 0 ? void 0 : (_data$shop3 = data.shop) === null || _data$shop3 === void 0 ? void 0 : (_data$shop3$_data = _data$shop3._data) === null || _data$shop3$_data === void 0 ? void 0 : _data$shop3$_data.description);
      const structuredData = {
        '@type': 'Organization',
        url: url,
        name: (data === null || data === void 0 ? void 0 : data.name) || ''
      };

      if (address) {
        structuredData.address = {
          '@type': 'PostalAddress',
          'streetAddress': address,
          'addressCountry': 'TW'
        };
      }

      if (description) {
        structuredData.description = description;
      }

      if (telephone) {
        structuredData.telephone = telephone;
      }

      const logo = data === null || data === void 0 ? void 0 : data.logo;

      if (logo) {
        const image = this._getImageUrl(data === null || data === void 0 ? void 0 : data.logo);

        structuredData.image = image;
        structuredData.logo = image;
      }

      return structuredData;
    }

    shop({
      data,
      additionalData
    }) {
      var _websiteData, _websiteData2, _websiteData3, _websiteData4, _websiteData5;

      const url = window.location.origin;
      const website = (additionalData === null || additionalData === void 0 ? void 0 : additionalData.website) || (additionalData === null || additionalData === void 0 ? void 0 : additionalData.model) || {};
      let websiteData;

      if (_nventor.default.isNotNilOrEmpty(website)) {
        websiteData = (website === null || website === void 0 ? void 0 : website._data) || {};
      }

      const name = (data === null || data === void 0 ? void 0 : data.companyName) || (data === null || data === void 0 ? void 0 : data.name) || ((_websiteData = websiteData) === null || _websiteData === void 0 ? void 0 : _websiteData.companyName) || ((_websiteData2 = websiteData) === null || _websiteData2 === void 0 ? void 0 : _websiteData2.name);
      const address = (data === null || data === void 0 ? void 0 : data.companyAddress) || ((_websiteData3 = websiteData) === null || _websiteData3 === void 0 ? void 0 : _websiteData3.companyAddress);
      const telephone = (data === null || data === void 0 ? void 0 : data.companyTel) || ((_websiteData4 = websiteData) === null || _websiteData4 === void 0 ? void 0 : _websiteData4.companyTel);
      const structuredData = {
        '@type': 'LocalBusiness',
        'name': name,
        'url': url
      };

      if (telephone) {
        structuredData.telephone = telephone;
      }

      if (address) {
        structuredData.address = {
          '@type': 'PostalAddress',
          'streetAddress': address,
          'addressCountry': 'TW'
        };
      } // location


      let storeLocation = ((_websiteData5 = websiteData) === null || _websiteData5 === void 0 ? void 0 : _websiteData5.displayedStoreLocation) || []; // @TODO: ONLY SHOW WHEN TOGGLE IS ON
      // storeLocation = R.prepend({
      //   name: name,
      //   address: websiteData?.companyAddress || data?.companyAddress,
      //   telephone: websiteData?.companyTel || data?.companyTel,
      //   openHours: websiteData?.openHours || data?.openHours
      // })(storeLocation)

      const storeLocationsData = R.map(location => {
        return {
          '@type': 'Place',
          'name': location === null || location === void 0 ? void 0 : location.name,
          'url': url,
          'address': {
            '@type': 'PostalAddress',
            'streetAddress': location === null || location === void 0 ? void 0 : location.address
          },
          'telephone': location === null || location === void 0 ? void 0 : location.telephone,
          'openingHoursSpecification': this._getFormattedOpeningHours({
            data: location
          })
        };
      })(storeLocation);

      if (_nventor.default.isNotNilOrEmpty(storeLocationsData)) {
        structuredData.location = storeLocationsData;
      }

      return structuredData;
    }

    faq({
      data
    }) {
      const faqs = data.faqs || [];

      if (_nventor.default.isNilOrEmpty(faqs)) {
        return;
      }

      const structuredData = {
        '@type': 'FAQPage',
        'mainEntity': R.map(faq => {
          return {
            '@type': 'Question',
            'name': faq.question,
            'acceptedAnswer': {
              '@type': 'Answer',
              'text': faq.answer
            }
          };
        })(faqs)
      };
      return structuredData;
    }

    news({
      data
    }) {
      var _document;

      const structuredData = {
        '@type': 'NewsArticle',
        'headline': data === null || data === void 0 ? void 0 : data.name,
        'articleBody': data.description,
        'author': [{
          '@type': 'Organization',
          'name': this.websiteService.website.companyName,
          'url': window.location.origin
        }]
      };
      const url = (_document = document) === null || _document === void 0 ? void 0 : _document.URL;

      if (url) {
        structuredData.url = url;
      }

      if (data !== null && data !== void 0 && data.createdDateZ) {
        structuredData.datePublished = data === null || data === void 0 ? void 0 : data.createdDateZ;
      }

      const images = this._getImages(data);

      if (_nventor.default.isNotNilOrEmpty(images)) {
        structuredData.image = images;
      }

      return structuredData;
    }

    blog({
      data
    }) {
      var _document2;

      const structuredData = {
        '@type': 'BlogPosting',
        'headline': data === null || data === void 0 ? void 0 : data.name,
        'articleBody': data.description,
        'author': [{
          '@type': 'Organization',
          'name': this.websiteService.website.companyName,
          'url': window.location.origin
        }]
      };
      const url = (_document2 = document) === null || _document2 === void 0 ? void 0 : _document2.URL;

      if (url) {
        structuredData.url = url;
      }

      if (data !== null && data !== void 0 && data.createdDateZ) {
        structuredData.datePublished = data === null || data === void 0 ? void 0 : data.createdDateZ;
      }

      const images = this._getImages(data);

      if (_nventor.default.isNotNilOrEmpty(images)) {
        structuredData.image = images;
      }

      return structuredData;
    }

    product({
      data,
      additionalData
    }) {
      var _data, _data2, _data3, _data4, _data5, _data6, _document3, _data9, _document4, _data10, _data11, _data13;

      const model = additionalData.model || {};
      data = model.serialize(); // need to serialize to prevent set to ember object

      data.uploadedFiles = (model === null || model === void 0 ? void 0 : model.images) || [];
      const priceRange = (model === null || model === void 0 ? void 0 : model.hasPriceRange) || [];

      if (_nventor.default.isNotNilOrEmpty(priceRange)) {
        const sortedPriceRange = R.sort((a, b) => a - b, priceRange);
        data.lowPrice = R.head(sortedPriceRange);
        data.highPrice = R.last(sortedPriceRange);
      } else {
        data.lowPrice = data.priceFinal;
        data.highPrice = data.price;
      }

      data.currentPrice = model.currentPrice;
      const itemStatus = additionalData === null || additionalData === void 0 ? void 0 : additionalData.status;
      const productStatusSchema = {
        preOrder: 'https://schema.org/PreOrder',
        outOfStock: 'https://schema.org/OutOfStock',
        soldOut: 'https://schema.org/SoldOut',
        inStock: 'https://schema.org/InStock'
      };
      let availability = '';

      if (itemStatus !== null && itemStatus !== void 0 && itemStatus.isPreOrder) {
        availability = productStatusSchema.preOrder;
      } else if (itemStatus !== null && itemStatus !== void 0 && itemStatus.isOutOfStock) {
        availability = productStatusSchema.outOfStock;
      } else if (itemStatus !== null && itemStatus !== void 0 && itemStatus.isSoldOut) {
        availability = productStatusSchema.soldOut;
      } else {
        availability = productStatusSchema.inStock;
      }

      const isSet = ((_data = data) === null || _data === void 0 ? void 0 : _data.isSet) || false;
      const isSetWithChoices = ((_data2 = data) === null || _data2 === void 0 ? void 0 : _data2.isSetWithChoices) || false;
      const hasVariants = ((_data3 = data) === null || _data3 === void 0 ? void 0 : _data3.hasVariants) || false;
      const isAutomation = ((_data4 = data) === null || _data4 === void 0 ? void 0 : _data4.isAutomation) || false;

      if (isAutomation) {
        return;
      }

      let offers = {
        '@type': 'Offer',
        'priceCurrency': 'TWD',
        'price': ((_data5 = data) === null || _data5 === void 0 ? void 0 : _data5.currentPrice) || ((_data6 = data) === null || _data6 === void 0 ? void 0 : _data6.priceFinal),
        'itemCondition': 'https://schema.org/NewCondition',
        'availability': availability
      };

      if (isSet || isSetWithChoices || hasVariants) {
        var _data7, _data8;

        offers = {
          '@type': 'AggregateOffer',
          'priceCurrency': 'TWD',
          'lowPrice': (_data7 = data) === null || _data7 === void 0 ? void 0 : _data7.lowPrice,
          'highPrice': (_data8 = data) === null || _data8 === void 0 ? void 0 : _data8.highPrice,
          'itemCondition': 'https://schema.org/NewCondition',
          'availability': availability
        };
      }

      const structuredData = {
        '@id': (_document3 = document) === null || _document3 === void 0 ? void 0 : _document3.URL,
        '@type': 'Product',
        'name': (_data9 = data) === null || _data9 === void 0 ? void 0 : _data9.item,
        'url': (_document4 = document) === null || _document4 === void 0 ? void 0 : _document4.URL,
        'productID': (_data10 = data) === null || _data10 === void 0 ? void 0 : _data10.itemKey,
        'offers': offers
      };

      if ((_data11 = data) !== null && _data11 !== void 0 && _data11.description) {
        var _data12;

        structuredData.description = (_data12 = data) === null || _data12 === void 0 ? void 0 : _data12.description.replace(/[\n\t]/gm, ' ').trim();
      }

      if ((_data13 = data) !== null && _data13 !== void 0 && _data13.brand) {
        var _data14;

        structuredData.brand = {
          '@type': 'Brand',
          'name': (_data14 = data) === null || _data14 === void 0 ? void 0 : _data14.brand
        };
      }

      const images = this._getImages(data);

      if (_nventor.default.isNotNilOrEmpty(images)) {
        structuredData.image = images;
      }

      return structuredData;
    }

    breadcrumb({
      data,
      additionalData
    }) {
      const url = window.location.origin;

      const getListItem = (page, pageIndex = 1) => {
        const itemUrl = `${url}${page === null || page === void 0 ? void 0 : page.url}`;
        let pageStructuredData = [{
          '@type': 'ListItem',
          'position': pageIndex,
          'name': page.name,
          'item': itemUrl
        }];

        if (_nventor.default.isNotNilOrEmpty(page === null || page === void 0 ? void 0 : page.children)) {
          const childrenpageStructuredData = R.pipe(R.pathOr([], ['children']), R.map(childPage => getListItem(childPage, pageIndex + 1)))(page);
          pageStructuredData = pageStructuredData.concat(childrenpageStructuredData);
        }

        return R.flatten(pageStructuredData);
      };

      const breadcrumbList = R.pipe(R.pathOr([], ['model', 'websitePageStructure']), R.map(page => {
        return {
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          name: 'Breadcrumb',
          itemListElement: getListItem(page)
        };
      }))(additionalData);
      return breadcrumbList;
    }

    _getImages(ProductModel) {
      const images = (ProductModel === null || ProductModel === void 0 ? void 0 : ProductModel.images) || (ProductModel === null || ProductModel === void 0 ? void 0 : ProductModel.uploadedFiles) || [];
      const imagesUrls = R.pipe(R.pluck('uuid'), R.map(imageUuid => this._getImageUrl(imageUuid)))(images);
      return imagesUrls;
    }

    _getImageUrl(imageUuid) {
      return `https://ucarecdn.com/${imageUuid}/-/format/webp/-/progressive/yes/`;
    }

    _getFormattedOpeningHours({
      data
    }) {
      const openHours = (data === null || data === void 0 ? void 0 : data.openHours) || {};
      const hasBreakTime = openHours.hasBreakTime || false;
      let openingHoursData = [];
      R.forEachObjIndexed((value, key) => {
        const notOpen = value.open === 'none';

        if (key !== 'hasBreakTime' && !notOpen) {
          openingHoursData.push({
            '@type': 'OpeningHoursSpecification',
            'dayOfWeek': key.charAt(0).toUpperCase() + key.slice(1),
            'opens': value.open,
            'closes': value.close
          });
          const hasOpenAfterBreak = value.openAfterBreak !== 'none';
          const hasCloseAfterBreak = value.closeAfterBreak !== 'none';

          if (hasBreakTime && (hasOpenAfterBreak || hasCloseAfterBreak)) {
            openingHoursData.push({
              '@type': 'OpeningHoursSpecification',
              'dayOfWeek': key.charAt(0).toUpperCase() + key.slice(1),
              'opens': value.openAfterBreak,
              'closes': value.closeAfterBreak
            });
          }
        }
      })(openHours);
      return openingHoursData;
    }

    getPermanentStructuredData() {
      const structuredDataScript = document.querySelector(`script[type="application/ld+json"]`);
      const contentString = structuredDataScript === null || structuredDataScript === void 0 ? void 0 : structuredDataScript.innerHTML;
      let existingGraph = [];

      if (contentString) {
        const content = JSON.parse(contentString);
        existingGraph = content['@graph'] || [];
      }

      return R.pipe(R.map(data => {
        var _data$additionalPrope;

        const hasPermanentAttribute = (data === null || data === void 0 ? void 0 : (_data$additionalPrope = data.additionalProperty) === null || _data$additionalPrope === void 0 ? void 0 : _data$additionalPrope.name) === 'isPermanent';

        if (hasPermanentAttribute) {
          return data;
        }

        return {};
      }), R.reject(_nventor.default.isNilOrEmpty))(existingGraph) || [];
    }

    getStructuredData({
      type,
      data,
      additionalData,
      isPermanent = false
    }) {
      const structuredData = this[type]({
        data,
        type,
        additionalData
      });

      if (isPermanent) {
        structuredData.additionalProperty = {
          '@type': 'PropertyValue',
          'name': 'isPermanent',
          'value': type
        };
      }

      return structuredData;
    }

    setupStructuredData({
      data,
      isNewStruct = false,
      wrapOnGraphKey = true
    }) {
      if (!isNewStruct) {
        const existingGraphs = document.querySelectorAll(`script[type="application/ld+json"]`);
        R.forEach(existingGraph => {
          existingGraph.remove();
        })(existingGraphs);
      }

      const script = document.createElement('script');
      script.type = 'application/ld+json';
      let structuredData = data;

      if (wrapOnGraphKey) {
        structuredData = {
          '@context': 'https://schema.org',
          '@graph': data
        };
      }

      script.innerHTML = JSON.stringify(structuredData, null, 2);
      document.head.appendChild(script);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "news", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "news"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "blog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "blog"), _class.prototype)), _class));
  _exports.default = StructuredDataService;
});