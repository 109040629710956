define("shop/pods/shop/service", ["exports", "ramda", "@ember/service", "@ember/application", "ember-concurrency", "@ember/object", "shop/utils/nventor"], function (_exports, R, _service, _application, _emberConcurrency, _object, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    ajax: (0, _service.inject)(),
    config: (0, _service.inject)(),
    tapPay: (0, _service.inject)(),
    checkoutService: (0, _service.inject)('checkout'),
    router: (0, _service.inject)(),
    websiteService: (0, _service.inject)('website'),
    storage: (0, _service.inject)(),
    cookie: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    isShopLoginSuccess: false,
    loginErrorMessage: '',
    campaignPassword: '',
    threeHours: 3 * 60 * 60 * 1000,

    init() {
      this._super(...arguments);

      (0, _object.set)(this, 'tempCache', {});
    },

    getShopModelFrom(website) {
      const shopData = website.get('_data.shop');
      const applicationInstance = (0, _application.getOwner)(this);
      const shopModel = applicationInstance.lookup('shop:main');
      shopModel.populate(shopData);
      return shopModel;
    },

    setPromoCode(promoCode = '') {
      // no need to set by shopKey, so that url ?promo= does not need to be in shop route
      // if invalid promo code it will be automatically removed
      this.storage.setValueWithShopKey('promoCode', promoCode);
    },

    getShopWithoutWebsiteTask: (0, _emberConcurrency.task)(function* ({
      params
    }) {
      // this should never run for websites with shop. that is fetched by application route
      let url = params.shop_url;

      if (!url) {
        return this.router.transitionTo('/'); // throw new Error('no shop url')
      }

      url = `/api/public/channels/shop/campaigns/${url}`;
      params = R.omit(['shop_url', 'page', 'count', 'sortBy', 'tags'])(params);
      const res = yield this.websiteService.request(url, {
        method: 'GET',
        data: params
      });

      if (res.data) {
        const applicationInstance = (0, _application.getOwner)(this);
        const shopModel = applicationInstance.lookup('shop:main');
        shopModel.populate(res.data);
        return shopModel;
      }

      throw new Error('no data');
    }).drop(),
    getShopItemsByItemKeysTask: (0, _emberConcurrency.task)(function* ({
      shop,
      params = {},
      cache = false
    }) {
      if (!shop) {
        return [];
      }

      if (!(shop !== null && shop !== void 0 && shop.isAllowShop)) {
        return [];
      }

      let url = shop.get('_data.url');
      url = `/api/public/channels/shop/campaigns/${url}/items/keys`;
      const toCacheParams = R.clone(params);
      toCacheParams.url = url;
      let cachedData;

      if (cache) {
        cachedData = yield this.websiteService.getComponentCache('shopByItemKeys', toCacheParams);

        if (!_nventor.default.isNilOrEmpty(cachedData)) {
          return cachedData;
        }
      }

      const res = yield this.websiteService.request(url, {
        method: 'POST',
        data: params
      });
      const data = res.data;

      if (cache && _nventor.default.isNotNilOrEmpty(data)) {
        yield this.websiteService.setComponentCache('shopByItemKeys', toCacheParams, data);
      }

      return data;
    }).maxConcurrency(3).enqueue(),
    getShopItemsTask: (0, _emberConcurrency.task)(function* ({
      shop,
      params = {},
      cache = false
    }) {
      if (!(shop !== null && shop !== void 0 && shop.isAllowShop)) {
        return [];
      }

      let url = shop.get('_data.url');
      url = `/api/public/channels/shop/campaigns/${url}/items`;
      let toCacheParams = R.clone(params);
      toCacheParams.url = url;
      toCacheParams = R.pick(['count', 'page', 'prices', 'query', 'shop_url', 'sortBy', 'tags', 'url'])(toCacheParams);
      let cachedData;

      if (cache) {
        cachedData = yield this.websiteService.getComponentCache('shopItems', toCacheParams);

        if (!_nventor.default.isNilOrEmpty(cachedData)) {
          return cachedData;
        }
      }

      const res = yield this.websiteService.request(url, {
        method: 'GET',
        data: params
      });
      res.data.items = R.pipe(R.pathOr([], ['data', 'details']), R.map(detailData => {
        const itemModel = shop.createAndPopulateShopItem(detailData);
        return itemModel;
      }))(res);
      const data = res.data;

      if (cache && _nventor.default.isNotNilOrEmpty(data)) {
        yield this.websiteService.setComponentCache('shopItems', toCacheParams, data);
      }

      return data;
    }).maxConcurrency(3).enqueue(),
    getShopItemTask: (0, _emberConcurrency.task)(function* ({
      shop,
      url,
      itemKey,
      tags,
      query,
      data
    }) {
      if (!shop.isAllowShop) {
        return {};
      }

      url = url || shop.get('_data.url');
      url = `/api/public/channels/shop/campaigns/${url}/items/${itemKey}`;
      data = data || {};

      if (tags) {
        data.tags = tags;
      }

      if (query) {
        data.query = query;
      }

      const res = yield this.websiteService.request(url, {
        method: 'GET',
        data
      });

      if (res.data) {
        return shop.createAndPopulateShopItem(res.data);
      }

      throw new Error('no data');
    }).enqueue(),
    getToValidateCheckoutData: (0, _emberConcurrency.task)(function* ({
      user,
      checkoutData = {},
      hasAddress,
      validate = false
    }) {
      const toCheckoutData = yield this.getCheckoutDataTask.perform({
        user,
        checkoutData,
        hasAddress,
        validate
      });
      return R.omit(['creditCards', 'creditCard', 'paymentMethods', 'addresses'])(toCheckoutData);
    }),
    getCheckoutDataTask: (0, _emberConcurrency.task)(function* ({
      shop,
      user,
      checkoutData = {},
      hasAddress,
      validate = true,
      validateUser = true,
      tapPayUseFields = false,
      creditCardPaymentProvider = ''
    }) {
      if (!user) {
        return {};
      }

      let userData = user.serialize();
      userData = R.omit(['creditCards', 'paymentMethods'], userData);
      userData.taxNumber = checkoutData.taxNumber;
      userData.companyName = checkoutData.companyName;
      userData.twEInvoiceCarrierType = checkoutData.twEInvoiceCarrierType;
      userData.twEInvoiceCarrierId = checkoutData.twEInvoiceCarrierId;
      userData.twEInvoicePrint = checkoutData.twEInvoicePrint;
      user.set('_data.twEInvoicePrint', checkoutData.twEInvoicePrint);
      const isConvenienceStore = (checkoutData === null || checkoutData === void 0 ? void 0 : checkoutData.isConvenienceStore) || false;
      const country = R.pathOr('', ['checkoutAddressData', 'country'], checkoutData);
      const countryCode = R.pathOr('', ['checkoutAddressData', 'countryCode'], checkoutData);

      try {
        if (validate) {
          user.validate('checkout', userData);
        }

        if (hasAddress) {
          // @TODO: validate address for convenience store on a different schema
          if (validate && !isConvenienceStore) {
            // user.validate('address', checkoutData.get('checkoutAddressData'))
            const checkoutAddressData = checkoutData.get('checkoutAddressData');

            if (checkoutAddressData.hasPostCode) {
              user.validate('address', checkoutAddressData);
            } else {
              user.validate('addressPostCodeNotRequired', checkoutAddressData);
            }
          }

          const addressString = yield this.websiteService.getCountryAddressStringTask.perform({
            country,
            addressObj: checkoutData.checkoutAddressData
          });
          checkoutData.set('address', addressString);

          if (user.get('isGuest')) {
            checkoutData.set('country', country);
            checkoutData.set('countryCode', countryCode);
          } else {
            checkoutData.set('country', user.get('_data.country'));
            checkoutData.set('countryCode', user.get('_data.countryCode'));
          }
        } else {
          const pickupAddress = checkoutData.get('isPickup');
          checkoutData.set('address', pickupAddress);
        }
      } catch (e) {
        e.message = 'Checkout Validation Error';
        throw e;
      }

      if (checkoutData.paymentMethod === 'creditCard' && shop) {
        if (creditCardPaymentProvider === 'tapPay') {
          try {
            const useExistingCreditCard = R.path(['checkoutCreditCardData', 'useExistingCreditCard'])(user);
            const ccId = R.path(['checkoutCreditCardData', 'ccId'])(user);

            if (ccId && useExistingCreditCard) {
              userData = R.assocPath(['creditCard', 'useExistingCreditCard'], true)(userData);
              userData = R.assocPath(['creditCard', 'ccId'], ccId)(userData);
            } else {
              const primeData = yield this.tapPay.getPrimeTask.perform({
                tapPayUseFields
              });
              const rememberCreditCard = R.path(['checkoutCreditCardData', 'rememberCreditCard'])(user);
              userData = R.assocPath(['creditCard', 'primeData'], primeData)(userData);
              userData = R.assocPath(['creditCard', 'rememberCreditCard'], rememberCreditCard)(userData);
            }
          } catch (e) {
            const originalMessage = R.path(['message'])(e) || '';
            throw new Error(`TapPayError__${originalMessage}`);
          }
        } else if (creditCardPaymentProvider === 'payuni') {
          const ccId = R.path(['checkoutCreditCardData', 'ccId'])(user);

          if (ccId) {
            userData = R.assocPath(['creditCard', 'useExistingCreditCard'], true)(userData);
            userData = R.assocPath(['creditCard', 'ccId'], ccId)(userData);
          }
        } else {
          userData.creditCard = {};
        }
      } else if ( // @TODO: hotfix. this should be refactored,
      // linePay should have a provider from api
      checkoutData.paymentMethod === 'linePay' && creditCardPaymentProvider !== 'payuni' && shop) {
        const primeData = yield this.tapPay.getLinePayPrimeTask.perform();
        userData = R.assocPath(['linePay', 'primeData'], primeData)(userData);
      } else {
        userData.creditCard = {};
      }

      let convenienceStoreData = {};

      if (isConvenienceStore) {
        const addressString = yield this.websiteService.getCountryAddressStringTask.perform({
          country,
          addressObj: checkoutData.convenienceStoreData
        });
        convenienceStoreData = {
          addressData: checkoutData.convenienceStoreData,
          address: addressString
        };
      } else {
        checkoutData.addressData = checkoutData.checkoutAddressData;
      }

      const toCheckoutData = R.mergeRight(userData)(checkoutData);
      toCheckoutData.name = toCheckoutData.name || userData.name;
      toCheckoutData.telephone = toCheckoutData.telephone || userData.telephone;
      return R.mergeRight(toCheckoutData)(convenienceStoreData);
    }),
    validateCartItemsTask: (0, _emberConcurrency.task)(function* ({
      shop,
      checkoutData,
      user,
      hasAddress,
      addedToCart,
      initiateCheckout,
      forceValidation = false,
      isPaymentPage = false
    }) {
      var _website$_data;

      const DEBOUNCE_MS = 450;
      yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);
      const url = shop.get('url');
      const website = this.websiteService.website;
      const websiteKey = website.get('websiteKey');
      const shopKey = website.get('shopKey');
      let rid = '';
      let clickId = '';
      const podKey = (website === null || website === void 0 ? void 0 : (_website$_data = website._data) === null || _website$_data === void 0 ? void 0 : _website$_data.podKey) || '';

      if (podKey) {
        const shopDotComTrackingData = this.websiteService.getTrackingDataForTwShopDotCom();
        rid = (shopDotComTrackingData === null || shopDotComTrackingData === void 0 ? void 0 : shopDotComTrackingData.rid) || '';
        clickId = (shopDotComTrackingData === null || shopDotComTrackingData === void 0 ? void 0 : shopDotComTrackingData.clickId) || '';
      }

      let toValidateCheckoutData = yield this.getToValidateCheckoutData.perform({
        user,
        checkoutData,
        hasAddress
      });
      const cart = shop.getCartItemsData();
      const currentValidationChecksum = yield this.calculateValidationChecksum(cart);

      if (!this.websiteService.isCheckoutRoute && !forceValidation) {
        const storedValidationChecksum = this.getValidationChecksum();

        if (storedValidationChecksum) {
          if (currentValidationChecksum === storedValidationChecksum) {
            return;
          }
        }
      }

      const automations = shop.getCartAutomationsData();
      const token = website.membersService.getToken(website);
      const tokenData = this.get('websiteService.membersService.tokenData') || {};

      if (R.isEmpty(toValidateCheckoutData)) {
        toValidateCheckoutData = tokenData;
      }

      if (!R.isEmpty(toValidateCheckoutData)) {
        (0, _object.set)(toValidateCheckoutData, 'memberLevelId', R.propOr('', 'memberLevelId')(tokenData));
      }

      const isInitiateCheckout = R.pipe(R.pathOr('', ['location', 'pathname']), R.includes('/checkout/summary'))(window);
      let meta;

      try {
        if (isInitiateCheckout) {
          meta = this.websiteService.getPixelData({
            eventName: 'initiateCheckout'
          });
        } else {
          meta = R.clone(this.websiteService.getPixelData({
            eventName: 'addToCart'
          }));

          this.websiteService._fbResetEventId('addToCart');
        }
      } catch (error) {
        console.error(error);
      }

      const payload = {
        cart,
        automations,
        user: toValidateCheckoutData,
        url,
        websiteKey,
        shopKey,
        token,
        addedToCart,
        initiateCheckout,
        rid,
        clickId,
        meta
      };

      const validated = _object.default.create({});

      shop.set('validated', validated);

      try {
        var _res$data;

        const res = yield this.websiteService.requestCheckout(`/api/public/channels/shop/checkout/validate/${url}`, {
          method: 'POST',
          data: payload
        });

        if (_nventor.default.isNotNilOrEmpty(res)) {
          shop.set('hasCartValidationError', false);
        }

        const validatedData = R.path(['data'])(res) || [];
        shop.validateCartItems(validatedData);
        shop.validateAutomationItems(validatedData);
        shop.addMustAddAutomationItemsToCart();
        shop.addMustAddBetweenAutomationItemsToCart();
        shop.set('updated', Math.random());
        shop.set('recurringOrders', (res === null || res === void 0 ? void 0 : (_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.recurringOrders) || []);
        const shippingFeeData = R.pathOr({}, ['data', 'shippingFeeData'])(res);
        shop.set('shippingFeeData', _object.default.create(shippingFeeData));
        const discountAmount = R.path(['data', 'discountAmount'])(res) || 0;
        shop.set('discountAmount', discountAmount);
        const promoCode = R.path(['data', 'promoCode'])(res) || '';
        shop.set('promoCode', promoCode);
        const shippingFeeTotal = R.path(['data', 'shippingFeeTotal'])(res) || '';
        shop.set('shippingFeeTotal', shippingFeeTotal);
        const paymentFeeTotal = R.path(['data', 'paymentFeeTotal'])(res) || '';
        shop.set('paymentFeeTotal', paymentFeeTotal);
        const subTotal = R.path(['data', 'subTotal'])(res) || '';
        shop.set('subTotal', subTotal);
        const rewardAmount = R.path(['data', 'rewardAmount'])(res) || 0;
        shop.set('rewardAmount', rewardAmount);
        const rewardPointApplied = R.path(['data', 'rewardPointApplied'])(res) || 0;
        shop.set('rewardPointApplied', rewardPointApplied);
        const rewardPointEarned = R.path(['data', 'rewardPointEarned'])(res) || 0;
        shop.set('rewardPointEarned', rewardPointEarned);
        const maxRewardPointsApplied = R.path(['data', 'maxRewardPointsApplied'])(res) || 0;
        shop.set('maxRewardPointsApplied', maxRewardPointsApplied);
        const spendRequirementAmount = R.pathOr(0, ['data', 'spendRequirementAmount'])(res);
        shop.set('spendRequirementAmount', spendRequirementAmount);
        const inclTotal = R.pathOr(0, ['data', 'inclTotal'])(res);
        const paymentFeeAndShippingFee = R.add(paymentFeeTotal, shippingFeeTotal);
        const itemOnlyTotal = R.subtract(inclTotal, paymentFeeAndShippingFee);
        const rewardSpendDifference = R.subtract(spendRequirementAmount, itemOnlyTotal);
        shop.set('rewardSpendDifference', rewardSpendDifference);
        shop.set('itemOnlyTotal', itemOnlyTotal);
        const transporters = R.pipe(R.pathOr([], ['shippingFeeData', 'combinedZones']), R.map(R.pick(['transporterKey', 'transporterLabel'])))(validatedData);
        shop.set('transporters', transporters);

        this._setCartItemsWarning({
          shop,
          validatedData
        });

        const allowedPaymentMethods = R.path(['data', 'shippingFeeData', 'availablePaymentMethods'])(res);

        if (!_nventor.default.isNilOrEmpty(allowedPaymentMethods)) {
          shop.set('_data.paymentMethods', allowedPaymentMethods);
          shop.set('updated', Math.random());
        }

        const latestCart = shop.getCartItemsData();
        const latestValidationChecksum = yield this.calculateValidationChecksum(latestCart);
        this.setValidationChecksum(latestValidationChecksum);
        this.websiteService.reInitPixelWithExternalId(validatedData);
        const hasIncompleteAutomationsMustAddAndChooseBetween = (shop === null || shop === void 0 ? void 0 : shop.hasIncompleteAutomationsMustAddAndChooseBetween) || false;

        if (isPaymentPage && hasIncompleteAutomationsMustAddAndChooseBetween) {
          this.router.transitionTo('shop.checkout.details');
        }

        return {
          shippingFeeData
        };
      } catch (error) {
        this.setCartItemErrors({
          error,
          shop,
          validated
        });
        shop.set('hasCartValidationError', true);

        if (!this.config.isProduction) {
          throw error;
        }

        return {};
      }
    }).restartable(),

    _setCartItemsWarning({
      shop,
      validatedData
    }) {
      const hasOverLimitedQtyPerCheckout = R.pipe(R.propOr([], 'cart'), R.pluck('isNotAllowedReason'), R.flatten, R.find(R.equals('over limited qty per checkout')), _nventor.default.isNotNilOrEmpty)(validatedData);
      shop.set('hasOverLimitedQtyPerCheckout', hasOverLimitedQtyPerCheckout);
    },

    setCartItemErrors({
      error,
      shop,
      validated
    }) {
      if (!validated) {
        validated = _object.default.create({});
      }

      let salesLimitedQtyCartItems = R.pathOr([], ['payload', 'data', 'salesLimitedQty'], error);
      const cartItemsWithCustomizationsError = R.pathOr([], ['payload', 'data', 'cartItemsWithCustomizationsError'], error);

      if (!R.isEmpty(cartItemsWithCustomizationsError)) {
        validated.set('cartItemsWithCustomizationsError', cartItemsWithCustomizationsError);
      }

      if (!R.isEmpty(salesLimitedQtyCartItems)) {
        salesLimitedQtyCartItems = R.map(item => {
          item.calculatedTotalAvailableQtyBeforeCart = parseInt(item.calculatedTotalAvailableQtyBeforeCart) || 0;

          if (item.mustRemove) {
            // item.hasRemainingAvailableQty = false
            return item;
          } else {
            const hasRemainingAvailableQty = parseInt(item.calculatedTotalAvailableQtyBeforeCart || 0) > 0;
            item.hasRemainingAvailableQty = hasRemainingAvailableQty;
          }

          return _object.default.create(item);
        })(salesLimitedQtyCartItems);
        const limitedQtyCartItems = R.filter(item => {
          if (item.mustRemove) {
            return false;
          }

          const isNotAllowedReason = item.isNotAllowedReason || [];
          const hasOverLimitedQty = R.pipe(R.find(R.equals('over limited qty')), _nventor.default.isNotNilOrEmpty)(isNotAllowedReason);

          if (item.isNotAllowed && hasOverLimitedQty) {
            return true;
          }

          return item.hasRemainingAvailableQty;
        })(salesLimitedQtyCartItems);
        validated.set('limitedQtyCartItems', limitedQtyCartItems);
        const removedCartItems = R.reject(item => {
          if (item.mustRemove) {
            return false;
          }

          const isNotAllowedReason = item.isNotAllowedReason || [];
          const hasOverLimitedQty = R.pipe(R.find(R.equals('over limited qty')), _nventor.default.isNotNilOrEmpty)(isNotAllowedReason);

          if (item.isNotAllowed && hasOverLimitedQty) {
            return true;
          }

          return item.hasRemainingAvailableQty;
        })(salesLimitedQtyCartItems);
        R.forEach(this._removeCartItem(shop), removedCartItems);
        validated.set('removedCartItems', removedCartItems);
        shop.set('validated', validated);
        return true;
      }

      return false;
    },

    _removeCartItem: R.curry((shop, item) => {
      const group = 'cartItems';
      shop.removeCartItem(group, item);
    }),

    _getSetDetailsRequiredQty(itemKey, item, isInnerChild) {
      const setDetails = R.propOr([], 'setDetails', item);
      const hasChildItemRequiredQty = R.reduce((acc, choice) => {
        if (choice.itemKey === itemKey) {
          const choiceQty = parseInt(choice.qty) || 0;
          acc = acc + choiceQty;
        }

        return acc;
      }, 0)(setDetails);

      if (R.gt(hasChildItemRequiredQty, 0) && isInnerChild) {
        const parentQty = parseInt(item.qty) || 0;
        return parentQty * hasChildItemRequiredQty;
      }

      if (R.gt(hasChildItemRequiredQty, 0)) {
        return hasChildItemRequiredQty;
      }

      return 0;
    },

    _checkAvailableQty(cartItem, limitedQtyCartItem, requiredQty, availableQty) {
      if (requiredQty <= availableQty) {
        return false;
      }

      const cartSalesLimitedQtyCartItems = cartItem.salesLimitedQtyCartItems || [];

      if (!R.find(R.propEq('itemKey', limitedQtyCartItem.itemKey), cartSalesLimitedQtyCartItems)) {
        cartSalesLimitedQtyCartItems.push(limitedQtyCartItem);
      }

      cartItem.set('calculatedTotalAvailableQtyBeforeCart', availableQty);
      cartItem.set('salesLimitedQtyCartItems', cartSalesLimitedQtyCartItems);
      return cartItem;
    },

    _setRemainingQty(limitedQtyCartItem, requiredQty) {
      const currentAvailableQty = parseInt(limitedQtyCartItem.get('calculatedTotalAvailableQtyBeforeCart')) || 0;
      const remainingAvailableQty = currentAvailableQty - requiredQty;
      limitedQtyCartItem.set('calculatedTotalAvailableQtyBeforeCart', remainingAvailableQty);
    },

    getBulkDiscountDescription({
      data,
      isApplyToAllProducts = false
    }) {
      const when = data.when;
      const criteria = data.criteria;
      const criteriaValue = data.value;
      const discountType = data.discountType;
      let discountValue = data.discountAmount;
      let discountDesc = `NT ${discountValue}`;

      if (discountType === 'rate') {
        discountValue = data.discountRate;
        discountDesc = `${discountValue}%`;
      }

      if (criteria && criteriaValue && discountType && discountValue) {
        let whenDescription = '';

        if (when === 'itemQty' && isApplyToAllProducts) {
          whenDescription = this.intl.t('apply all bulk discount when buy items', {
            discount: discountDesc,
            criteria: this.intl.t(criteria),
            criteriaValue
          });
        }

        if (when === 'itemQty' && !isApplyToAllProducts) {
          whenDescription = this.intl.t('bulk discount when buy items', {
            discount: discountDesc,
            criteria: this.intl.t(criteria),
            criteriaValue
          });
        }

        if (when === 'itemValue' && isApplyToAllProducts) {
          whenDescription = this.intl.t('apply all bulk discount when items value', {
            discount: discountDesc,
            criteria: this.intl.t(criteria),
            criteriaValue
          });
        }

        if (when === 'itemValue' && !isApplyToAllProducts) {
          whenDescription = this.intl.t('bulk discount when items value', {
            discount: discountDesc,
            criteria: this.intl.t(criteria),
            criteriaValue
          });
        }

        return whenDescription;
      }

      return '';
    },

    shopLoginTask: (0, _emberConcurrency.task)(function* ({
      password,
      shop
    } = {}) {
      if (_nventor.default.isNotNilOrEmpty(shop) && password) {
        var _shop$_data, _shop$_data2;

        const data = {
          shopKey: (shop === null || shop === void 0 ? void 0 : shop.shopKey) || (shop === null || shop === void 0 ? void 0 : (_shop$_data = shop._data) === null || _shop$_data === void 0 ? void 0 : _shop$_data._key) || '',
          password
        };
        const shopUrl = (shop === null || shop === void 0 ? void 0 : shop.url) || (shop === null || shop === void 0 ? void 0 : (_shop$_data2 = shop._data) === null || _shop$_data2 === void 0 ? void 0 : _shop$_data2.url) || '';
        const res = yield this.websiteService.request(`/api/public/channels/shop/campaigns/${shopUrl}/login`, {
          method: 'POST',
          data
        });

        if (res.data === true) {
          shop.set('isShopLoginSuccess', true);
          this.set('loginErrorMessage', '');
          this.storage.setValueWithShopKey('isAllowed', true, this.threeHours);
          window.location.reload();
        } else {
          shop.set('isShopLoginSuccess', false);
          this.set('loginErrorMessage', 'error_password');
        }
      } else {
        this.set('loginErrorMessage', 'error_password');
      }
    }),

    calculateValidationChecksum(cartData) {
      return this.websiteService.hashSHA256(cartData);
    },

    setValidationChecksum(validationChecksum) {
      const thirtyMin = 2.592e+9;
      return this.storage.setValue('validationChecksum', validationChecksum, this.websiteService.website, thirtyMin);
    },

    getValidationChecksum() {
      return this.storage.getValue('validationChecksum', this.websiteService.website);
    },

    isCartItemCustomizationsSimilar: R.curry((item, cartItem) => {
      const cartItemSelectedCustomizations = (cartItem === null || cartItem === void 0 ? void 0 : cartItem.selectedCustomizations) || [];
      const itemSelectedCustomizations = item.get('selectedCustomizations') || [];

      if (R.isEmpty(cartItemSelectedCustomizations) && R.isEmpty(itemSelectedCustomizations)) {
        return true;
      }

      if (cartItemSelectedCustomizations.length !== itemSelectedCustomizations.length) {
        return false;
      }

      return R.pipe(R.map(cartItemSelectedCustomization => {
        var _cartItemSelectedCust;

        let cartItemSelectedCustomizationNameValueCombination = `${cartItemSelectedCustomization === null || cartItemSelectedCustomization === void 0 ? void 0 : cartItemSelectedCustomization.selectedCustomizationName}-${cartItemSelectedCustomization === null || cartItemSelectedCustomization === void 0 ? void 0 : cartItemSelectedCustomization.selectedCustomizationValue}`;

        if (cartItemSelectedCustomization !== null && cartItemSelectedCustomization !== void 0 && (_cartItemSelectedCust = cartItemSelectedCustomization.selectedCustomizationObj) !== null && _cartItemSelectedCust !== void 0 && _cartItemSelectedCust.isCustomResponse) {
          var _cartItemSelectedCust2;

          const customResponse = R.trim((cartItemSelectedCustomization === null || cartItemSelectedCustomization === void 0 ? void 0 : (_cartItemSelectedCust2 = cartItemSelectedCustomization.selectedCustomizationObj) === null || _cartItemSelectedCust2 === void 0 ? void 0 : _cartItemSelectedCust2.customResponse) || '');

          if (customResponse) {
            cartItemSelectedCustomizationNameValueCombination = `${cartItemSelectedCustomizationNameValueCombination}-${customResponse}`;
          }
        }

        const itemSelectedCustomizationNameValueCombination = R.pipe(R.find(R.propEq('selectedCustomizationIndex', cartItemSelectedCustomization === null || cartItemSelectedCustomization === void 0 ? void 0 : cartItemSelectedCustomization.selectedCustomizationIndex)), itemSelectedCustomization => {
          var _itemSelectedCustomiz;

          let nameValueCombination = '';

          if (itemSelectedCustomization) {
            nameValueCombination = `${itemSelectedCustomization === null || itemSelectedCustomization === void 0 ? void 0 : itemSelectedCustomization.selectedCustomizationName}-${itemSelectedCustomization === null || itemSelectedCustomization === void 0 ? void 0 : itemSelectedCustomization.selectedCustomizationValue}`;
          }

          if (itemSelectedCustomization !== null && itemSelectedCustomization !== void 0 && (_itemSelectedCustomiz = itemSelectedCustomization.selectedCustomizationObj) !== null && _itemSelectedCustomiz !== void 0 && _itemSelectedCustomiz.isCustomResponse) {
            var _itemSelectedCustomiz2;

            const customResponse = R.trim((itemSelectedCustomization === null || itemSelectedCustomization === void 0 ? void 0 : (_itemSelectedCustomiz2 = itemSelectedCustomization.selectedCustomizationObj) === null || _itemSelectedCustomiz2 === void 0 ? void 0 : _itemSelectedCustomiz2.customResponse) || '');

            if (customResponse) {
              nameValueCombination = `${nameValueCombination}-${customResponse}`;
            }
          }

          return nameValueCombination;
        })(itemSelectedCustomizations);

        if (R.equals(cartItemSelectedCustomizationNameValueCombination, itemSelectedCustomizationNameValueCombination)) {
          return true;
        }

        return false;
      }), R.all(R.equals(true)))(cartItemSelectedCustomizations);
    })
  });

  _exports.default = _default;
});