define("shop/pods/cookie/service", ["exports", "@ember/service", "ramda"], function (_exports, _service, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // @TODO: test
  var _default = _service.default.extend({
    websiteService: (0, _service.inject)('website'),
    maxAge: 259200,

    // 3 days in seconds

    /**
     * Add podKey to the token
     * @param {String} key
     * @param {Object} website
     * @returns {String} podKey_key, if no website is provided, return only key
     * */
    _appendPodKey(key, website = {}) {
      if (!R.isEmpty(website)) {
        const podKey = website.get('podKey');
        return `${podKey}_${key}`;
      }

      return key;
    },

    /**
     * Add podKey to the token
     * @param {String} key
     * @returns {String} shopKey_key, if no shopKey if found, return only key
     * */
    _appendShopKey(key) {
      const shopKey = R.pathOr('', ['websiteService', 'website', 'shopKey'])(this);

      if (shopKey) {
        return `${shopKey}_${key}`;
      }

      return key;
    },

    /**
     * stores to cookie
     * @param {Object} website
     * @param {String} key
     * @param {Mixin} val
     * @param {String} maxAge in second
     * @param {String} expires UTC date string
     * @void
     */
    setValue(key, val, website = {}, maxAge = '', expires = '') {
      var _val$toString;

      const cookieKey = this._appendPodKey(key, website);

      const valString = (val === null || val === void 0 ? void 0 : (_val$toString = val.toString) === null || _val$toString === void 0 ? void 0 : _val$toString.call(val)) || '';

      if (valString) {
        let domainStr = ''; // will cause no updates in dev, and default is already current domain

        const host = this.websiteService.getHost() || '';

        if (host) {
          domainStr = `; domain=${host}`;
        }

        let expStr = '';

        if (expires && typeof expires === 'string') {
          expStr = `; expires=${expires}`;
        }

        let maxAgeStr = '';

        if (maxAge) {
          maxAgeStr = `; max-age=${maxAge}`;
        } // httpOnly added will not be recorded
        // secure added will cause safari not be able to set


        try {
          document.cookie = `${cookieKey}=${valString}${expStr}${maxAgeStr}; path=/; SameSite=lax`;
        } catch (error) {
          return null;
        }
      }
    },

    /**
     * Get value from cookie
     * @param {string} key
     * @param {Object} website
     * @return {null|String}
     */
    getValue(key, website = {}) {
      try {
        var _document;

        const cookieKey = this._appendPodKey(key, website);

        const allCookies = ((_document = document) === null || _document === void 0 ? void 0 : _document.cookie) || '';

        if (!allCookies) {
          return '';
        }

        const cookie = R.pipe(R.split('; '), R.find(row => R.startsWith(cookieKey + '=')(row)))(allCookies);

        if (cookie) {
          var _cookie$split;

          return (cookie === null || cookie === void 0 ? void 0 : (_cookie$split = cookie.split('=')) === null || _cookie$split === void 0 ? void 0 : _cookie$split[1]) || null;
        }

        if (cookie) {
          return cookie;
        }

        return '';
      } catch (error) {
        return null;
      }
    },

    /**
     * stores to cookie
     * @param {String} key
     * @param {Mixin} val
     * @param {String} maxAge in second
     * @param {String} expires UTC date string
     * @void
     */
    setValueWithShopKey(key, val, maxAge = '', expires = '') {
      var _val$toString2;

      const cookieKey = this._appendShopKey(key);

      const valString = (val === null || val === void 0 ? void 0 : (_val$toString2 = val.toString) === null || _val$toString2 === void 0 ? void 0 : _val$toString2.call(val)) || '';

      if (valString) {
        let domainStr = ''; // will cause no updates in dev, and default is already current domain

        const host = this.websiteService.getHost() || '';

        if (host) {
          domainStr = `; domain=${host}`;
        }

        let expStr = '';

        if (expires && typeof expires === 'string') {
          expStr = `; expires=${expires}`;
        }

        let maxAgeStr = '';
        maxAge = maxAge || (this === null || this === void 0 ? void 0 : this.maxAge) || '';

        if (maxAge) {
          maxAgeStr = `; max-age=${maxAge}`;
        } // httpOnly added will not be recorded
        // secure added will cause safari not be able to set


        try {
          document.cookie = `${cookieKey}=${valString}${expStr}${maxAgeStr}; path=/; SameSite=lax`;
        } catch (error) {
          return null;
        }
      }
    },

    /**
     * Get value from cookie
     * @param {string} key
     * @return {null|String}
     */
    getValueWithShopKey(key) {
      try {
        var _document2;

        const cookieKey = this._appendShopKey(key);

        const allCookies = ((_document2 = document) === null || _document2 === void 0 ? void 0 : _document2.cookie) || '';

        if (!allCookies) {
          return '';
        }

        const cookie = R.pipe(R.split('; '), R.find(row => R.startsWith(cookieKey + '=')(row)))(allCookies);

        if (cookie) {
          var _cookie$split2;

          return (cookie === null || cookie === void 0 ? void 0 : (_cookie$split2 = cookie.split('=')) === null || _cookie$split2 === void 0 ? void 0 : _cookie$split2[1]) || null;
        }

        return '';
      } catch (error) {
        return null;
      }
    }

  });

  _exports.default = _default;
});