define("shop/mixins/images", ["exports", "ramda", "@ember/object", "@ember/object/mixin", "jquery", "shop/utils/nventor", "@ember/service"], function (_exports, R, _object, _mixin, _jquery, _nventor, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global CSSParser */

  /**
   * mixin for automatic image tranformations
   */
  var _default = _mixin.default.create({
    websiteService: (0, _service.inject)('website'),
    imgWidth: (0, _object.computed)('width', function () {
      return parseInt(this.width) || 180;
    }),
    imgHeight: (0, _object.computed)('imgWidth', 'shop._data.productsImagesRatio', function () {
      const ratio = this.get('shop._data.productsImagesRatio') || 'oneByOne';

      if (ratio === 'oneByOne') {
        return this.imgWidth;
      }

      if (ratio === 'twoByThree') {
        return this.twoByThreeHeight;
      }

      if (ratio === 'threeByTwo') {
        return this.threeByTwoHeight;
      }

      if (ratio === 'fourByThree') {
        return this.fourByThreeHeight;
      }

      if (ratio === 'threeByFour') {
        return this.threeByFourHeight;
      }
    }),
    twoByThreeHeight: (0, _object.computed)('imgWidth', function () {
      const width = this.imgWidth;
      return Math.round(width * 3 / 2, 0);
    }),
    threeByTwoHeight: (0, _object.computed)('imgWidth', function () {
      const width = this.imgWidth;
      return Math.round(width * 2 / 3, 0);
    }),
    fourByThreeHeight: (0, _object.computed)('imgWidth', function () {
      const width = this.imgWidth;
      return Math.round(width * 3 / 4, 0);
    }),
    threeByFourHeight: (0, _object.computed)('imgWidth', function () {
      const width = this.imgWidth;
      return Math.round(width * 4 / 3, 0);
    }),
    // used for item images where height is not auto
    imgSrcTransformUrl: (0, _object.computed)('imgWidth', 'imgHeight', function () {
      let imgWidth = this.imgWidth;
      const imgHeight = Math.round(this.imgHeight);

      if (imgWidth > 3000) {
        imgWidth = 3000;
      }

      imgWidth = Math.round(imgWidth);
      return `/-/resize/${imgWidth}x/-/format/webp/-/progressive/yes/`;
    }),

    /**
     * Returns the image quality based on the given width.
     * Disabled
     * @param {number} width - The width of the image.
     * @return {string} The image quality.
     */
    imageQuality(width) {
      return ''; // width = parseInt(width) || 0
      // if (width > 2000) {
      // }
      //
      // return '-/quality/smart_retina/'
    },

    // used for item images where height is not auto
    imgSrcsetTransformUrl: (0, _object.computed)('imgWidth', 'imgHeight', 'isSvg', function () {
      const imgWidth = parseInt(this.imgWidth);
      const imgHeight = parseInt(this.imgHeight);

      if (this.isSvg) {
        return `/-/resize/${imgWidth}x/${this.imageQuality(imgWidth)}-/format/webp/-/progressive/yes/`;
      }

      let width2x = imgWidth * 2;
      const height2x = Math.round(imgHeight * 2);

      if (width2x > 3000) {
        width2x = 3000;
      }

      width2x = Math.round(width2x);
      return `/-/resize/${width2x}x/${this.imageQuality(width2x)}-/format/webp/-/progressive/yes/ 2x`;
    }),

    getOptimizedImgSrc(src, width) {
      if (width > 3000) {
        width = 3000;
      }

      width = Math.round(width);
      src = _nventor.default.removeTrailingSlash(src);
      return `${src}/-/resize/${width}x/${this.imageQuality(width)}-/format/webp/-/progressive/yes/`;
    },

    getCropScaleImgSrc(src, width, ratio) {
      const [ratioWith, ratioHeight] = ratio.split(':');

      if (width > 1500) {
        width = 1500;
      }

      width = Math.round(width);
      const height = Math.round(width / ratioWith * ratioHeight);
      src = _nventor.default.removeTrailingSlash(src);
      return `${src}/-/scale_crop/${width}x${height}/center/${this.imageQuality(width)}-/format/webp/-/progressive/yes/`;
    },

    getCropScaleImgSrcSet(src, width, ratio) {
      const [ratioWith, ratioHeight] = ratio.split(':');

      if (width > 1500) {
        width = 1500;
      }

      const height = Math.round(width / ratioWith * ratioHeight);
      src = _nventor.default.removeTrailingSlash(src);

      if (this.isSvg) {
        return `${src}/-/scale_crop/${width}x${height}/center/${this.imageQuality(width)}-/format/webp/-/progressive/yes/`;
      }

      const width2x = Math.round(width * 2);
      const height2x = height * 2; // return `${src}-/scale_crop/${width}x${height}/center/-/format/webp/-/progressive/yes/`

      return `${src}/-/scale_crop/${width2x}x${height2x}/center/${this.imageQuality(width2x)}-/format/webp/-/progressive/yes/ 2x`;
    },

    getOptimizedImgSrcset(src, width) {
      src = _nventor.default.removeTrailingSlash(src);

      if (this.isSvg) {
        return `${src}/-/resize/${width}x/${this.imageQuality(width)}-/format/webp/-/progressive/yes/`;
      }

      let width2x = width * 2;

      if (width2x > 3000) {
        width2x = 3000;
      }

      width2x = Math.round(width2x);
      return `${src}/-/resize/${width2x}x/${this.imageQuality(width2x)}-/format/webp/-/progressive/yes/ 2x`;
    },

    _calculateWidthWhenInGroup(image, width, defaultWidth) {
      const isMobile = this.websiteService.isMobile;

      if (!defaultWidth || isMobile) {
        return width;
      }

      try {
        var _image$closest, _image$closest$childr;

        const numberOfItemsInGrid = image.closest('.bigordr-inner-row').childElementCount || ((_image$closest = image.closest('.bigordr-inner-row')) === null || _image$closest === void 0 ? void 0 : (_image$closest$childr = _image$closest.children()) === null || _image$closest$childr === void 0 ? void 0 : _image$closest$childr.length) || 0;

        if (numberOfItemsInGrid !== 0) {
          width = Math.ceil(defaultWidth / numberOfItemsInGrid);
        }
      } catch (e) {
        console.error(e);
      }

      return width;
    },

    optimizeImages(defaultWidth, images) {
      images.each((key, image) => {
        const optimized = R.pathOr('false', ['dataset', 'optimized'])(image);

        if (optimized === 'true') {
          return;
        }

        image.dataset.optimized = 'true';
        image = (0, _jquery.default)(image);
        const ratio = image[0].getAttribute('data-gjs-model-ratio');
        const src = image.attr('src') || image.attr('safeSrc') || '';

        if (R.includes('ucarecdn', src) && R.includes('/-/', src)) {
          return;
        }

        if (R.includes('ucarecdn', src) && R.includes('/gif2video', src)) {
          return;
        }

        if (R.includes('ucarecdn', src) && !R.includes('-/resize', src)) {
          var _image, _contentDisplayArray$;

          const fileType = image.attr('data-file-type') || '';
          const isSvg = R.includes('svg', fileType);
          this.set('isSvg', isSvg);
          let mimeType = image.attr('data-mime-type') || '';

          if (!mimeType) {
            mimeType = image.attr('data-str-mime-type') || '';
          }

          let width = defaultWidth;
          const bigordrGridImagesLength = (_image = image) === null || _image === void 0 ? void 0 : _image.closest('.bigordr-grid-images').length;
          const contentDisplayArray = Array.from(document.querySelectorAll('.section > .content-display'));
          const contentDisplayClientWidth = ((_contentDisplayArray$ = contentDisplayArray.find(element => element === null || element === void 0 ? void 0 : element.clientWidth)) === null || _contentDisplayArray$ === void 0 ? void 0 : _contentDisplayArray$.clientWidth) || 0;
          const imageDataWidthString = image.attr('data-width');
          let imageDataWidth = Number(imageDataWidthString);

          if (Number.isNaN(imageDataWidth)) {
            imageDataWidth = 0;
          }

          if (bigordrGridImagesLength) {
            width = this._calculateWidthWhenInGroup(image, width, defaultWidth);
          } else if (image.hasClass('full-width-image')) {
            // TODO: CALL THIS FN AFTER RENDER SO PARENT CAN HAVE WIDTH
            const imageWidth = defaultWidth; // R.path([0, 'dataset', 'width'])(image)

            let parent = image.parent();
            let parentWidth = defaultWidth;

            if (parent) {
              parent = parent.parent();

              if (parent) {
                parentWidth = parent.outerWidth() || imageWidth || defaultWidth;
              }
            }

            if (parentWidth > width) {
              width = parentWidth;
            }
          } else if (imageDataWidth) {
            width = imageDataWidth;
          } else if (contentDisplayClientWidth) {
            width = contentDisplayClientWidth;
          }

          if (width > defaultWidth) {
            width = defaultWidth;
          } // make width 15% higher than required for better clarity


          width = width * 1.25;
          width = Math.round(width, 0); // image.parent().has('source')

          if (R.includes('gif', mimeType)) {
            const videoElement = this._convertGifToVideo(image, src, width, defaultWidth);

            image.replaceWith(videoElement);
          } else if (ratio) {
            image.attr('src', this.getCropScaleImgSrc(src, width, ratio));
            image.attr('srcset', this.getCropScaleImgSrcSet(src, width, ratio));
          } else {
            image.attr('src', this.getOptimizedImgSrc(src, width));
            image.attr('srcset', this.getOptimizedImgSrcset(src, width));
          }
        }
      });
    },

    _convertGifToVideo(image, src, width) {
      try {
        /** @type {HTMLVideoElement} */
        const videoElement = document.createElement('video');
        const attributes = R.pathOr({}, [0, 'attributes'])(image); // Add image dataset to video element

        for (const index in attributes) {
          const {
            value,
            name
          } = attributes[index];
          const isDataAttribute = name === null || name === void 0 ? void 0 : name.includes('data-');

          if (isDataAttribute !== true && name !== 'style') {
            continue;
          }

          videoElement.setAttribute(name, value);
        }

        src = _nventor.default.removeTrailingSlash(src);
        videoElement.setAttribute('poster', `${src}/-/preview/-/blur/20/`);
        videoElement.setAttribute('width', width);
        videoElement.setAttribute('preload', 'none');
        videoElement.setAttribute('autoplay', 'autoplay');
        videoElement.setAttribute('loop', 'true');
        videoElement.setAttribute('muted', 'true');
        videoElement.setAttribute('defaultmuted', 'true');
        videoElement.setAttribute('webkit-playsinline', 'webkit-playsinline');
        videoElement.setAttribute('playsinline', 'playsinline'); // add images class names one by one to the video

        const classNames = image[0].classList.entries();

        for (const [
          /* key */
        , className] of classNames) {
          videoElement.classList.add(className);
        } // iOS Safari webm playing issue - It should always request mp4 format
        // https://forums.developer.apple.com/forums/thread/748768


        if (!this.websiteService.isUserAgentIOsSafari) {
          const sourceVideoWebM = document.createElement('source');
          sourceVideoWebM.setAttribute('src', `${src}/gif2video/-/format/webm/`);
          sourceVideoWebM.setAttribute('type', 'video/webm');
          videoElement.appendChild(sourceVideoWebM);
        }

        const sourceVideoMp4 = document.createElement('source');
        sourceVideoMp4.setAttribute('src', `${src}/gif2video/-/format/mp4/`);
        sourceVideoMp4.setAttribute('type', 'video/mp4');
        videoElement.appendChild(sourceVideoMp4); // When on load call the play function

        videoElement.addEventListener('loadeddata', () => {
          videoElement.muted = true;
          return videoElement.play();
        });
        videoElement.classList.add('display-block');
        return videoElement;
      } catch (error) {
        console.error('error converting gif to video: ', error);
        return document.createElement('video');
      }
    },

    optimizeBackgroundImages(defaultWidth, css) {
      // IF THERE IS A BACKGROUND IMAGE ERROR
      // RETURN THE ORIGINAL CSS RATHER THAN STOP THE RENDERING PROCESS
      try {
        const parser = new CSSParser();
        const sheet = parser.parse(css, false, true);
        const getBackgroundImage = R.pipe(R.propOr([], 'cssRules'), R.map(jscss => {
          const hasBackgroundImage = R.pipe(R.pathOr('', ['parsedCssText']), R.includes('background-image'))(jscss);

          if (!hasBackgroundImage) {
            return jscss;
          }

          const isMedia = R.has('media')(jscss);

          if (isMedia) {
            const res = getBackgroundImage(jscss);
            jscss.cssRules.clear();
            jscss.cssRules.pushObjects(res);
            return jscss;
          }

          const src = R.pipe(R.propOr([{}], 'declarations'), R.find(R.propEq('property', 'background-image')), R.propOr('', 'valueText'), R.split('url('), R.map(R.split(')')), R.flatten, R.find(R.includes('http')))(jscss);
          const isNotUploadCare = !R.includes('ucarecdn')(src);

          if (isNotUploadCare) {
            return jscss;
          }

          if (R.includes('ucarecdn', src) && R.includes('/-/', src)) {
            return jscss;
          }

          const isBackgroundImageSizeCover = R.pipe(R.propOr([], 'declarations'), R.filter(R.propEq('property', 'background-size')), R.pathEq([0, 'valueText'], 'cover'))(jscss);
          let width = defaultWidth;
          const isMobile = this.websiteService.isMobile;

          if (isBackgroundImageSizeCover && isMobile) {
            width *= 2;
          }

          const optimizedImgSrc = this.getOptimizedImgSrc(src, width);
          const optimizedImgSrcset = R.replace(' 2x', '')(this.getOptimizedImgSrcset(src, width));
          const enhanceBackground = `background-image: -webkit-image-set(
                url(${optimizedImgSrc}) 1x,
                url(${optimizedImgSrcset}) 2x
              );
              background-image: image-set(
                url(${optimizedImgSrc}) 1x,
                url(${optimizedImgSrcset}) 2x
              );`;
          const newCss = R.replace(/}/, `${enhanceBackground}}`)(jscss.parsedCssText);
          const jsCssNew = parser.parse(newCss, false, true);
          return R.pipe(R.propOr([], 'cssRules'), R.head)(jsCssNew);
        }));
        const newSheetCssRules = getBackgroundImage(sheet);
        sheet.cssRules.clear();
        sheet.cssRules.pushObjects(newSheetCssRules);
        return sheet.cssText();
      } catch (error) {
        // const backgroundImagesAreNotBeenOptimized = 'BACKGROUND IMAGES ARE NOT BEEN OPTIMIZED'
        // console.error(backgroundImagesAreNotBeenOptimized)
        // console.error(error)
        // this.websiteService.logErrorTask.perform({
        //   error
        // })
        return css;
      }
    },

    getOptimizedSourceSrcSet(src, width, ratio) {
      if (width > 3000) {
        width = 3000;
      }

      src = _nventor.default.removeTrailingSlash(src);
      width = Math.round(width);

      if (ratio) {
        const [ratioWith, ratioHeight] = R.split(':')(ratio);
        const height = Math.round(width / ratioWith * ratioHeight);
        return `${src}/-/format/webp/${this.imageQuality(width)}-/scale_crop/${width}x${height}/center/-/progressive/yes/`;
      }

      return `${src}/-/format/webp/${this.imageQuality(width)}-/resize/${width}x/-/progressive/yes/`;
    },

    optimizePictureSource(defaultWidth, pictureSources) {
      R.forEach(source => {
        try {
          const sourceSrcSet = source.getAttribute('safesrc');

          if (!sourceSrcSet) {
            return;
          }

          const isUploadcare = R.includes('https://ucarecdn.com')(sourceSrcSet);

          if (!isUploadcare) {
            return;
          }

          if (R.includes('ucarecdn', sourceSrcSet) && R.includes('/-/', sourceSrcSet)) {
            return;
          }

          const img = source.parentElement.querySelector('img');

          if (!img) {
            return;
          } // TODO: CALL THIS FN AFTER RENDER SO PARENT CAN HAVE WIDTH
          // get imgWidth


          let width = defaultWidth;
          const contentDisplay = document.querySelector('.content-display');
          const imageWidthData = img.getAttribute('data-width');
          const bigordrGridImagesLength = img === null || img === void 0 ? void 0 : img.closest('.bigordr-grid-images');

          if (bigordrGridImagesLength) {
            width = this._calculateWidthWhenInGroup(img, width, defaultWidth);
          } else if (imageWidthData) {
            width = Number(imageWidthData);
          } else if (contentDisplay) {
            width = Number(contentDisplay);
          } // make width 15% higher than required for better clarity


          width = width * 1.25;
          width = Math.round(width, 0);

          if (source.getAttribute('data-source') === 'desktop') {
            width = width * 1.5; // media="(min-width: 800px)"

            source.setAttribute('media', '(min-width: 800px)');
          } else if (source.getAttribute('data-source') === 'tablet') {
            // media="(min-width: 500px)"
            source.setAttribute('media', '(min-width: 500px)');
          }

          const srcset = this.getOptimizedImgSrcset(sourceSrcSet, width);
          source.setAttribute('srcset', srcset);
        } catch (e) {
          console.error('error optimizing image', e);
        }
      })(pictureSources);
    },

    _getUrlData(element, menu) {
      const linkType = element.getAttribute('data-href-link-type') || 'url';
      const resourceType = element.getAttribute('data-href-resource-type') || '';
      let href = element.getAttribute('data-href') || '';
      const title = element.getAttribute('data-href-title');
      const target = element.getAttribute('data-href-target');

      if (linkType === 'url') {
        return {
          href,
          title,
          target
        };
      }

      const website = this.website;

      if (!website) {
        // should always have website!
        return {
          href,
          title,
          target
        };
      }

      const resourceKey = element.getAttribute('data-href-resource-key') || '';
      const resourceName = element.getAttribute('data-href-resource-name') || '';

      if (linkType === 'pageAndCategory') {
        const foundMenuItem = R.find(R.propEq('_key', resourceKey))(menu) || {};
        const pageUrl = R.propOr('', 'url')(foundMenuItem);

        if (!pageUrl) {
          return {
            href: '',
            title,
            target
          };
        }

        if (resourceType === 'category') {
          href = `/shop/${website.shopUrlDisplay}/category/${pageUrl}`;
        } else if (foundMenuItem !== null && foundMenuItem !== void 0 && foundMenuItem.isDynamicPage) {
          href = pageUrl;
        } else {
          href = `/${pageUrl}`;
        }
      } else if (resourceType === 'product') {
        href = `/shop/${website.shopUrlDisplay}/item/${resourceKey}`;
      } else if (resourceType === 'blog') {
        href = `/blogs/${resourceKey}/${resourceName}`;
      } else if (resourceType === 'news') {
        href = `/news/${resourceKey}/${resourceName}`;
      } else if (resourceType === 'store-location') {
        href = `/store-location/${resourceKey}`;
      } else if (resourceType === 'flows') {
        href = `/?f=${resourceKey}`;
      }

      return {
        href,
        title,
        target
      };
    },

    /**
     * Wrap element with link, recursively
     * @param {Object} options
     * @param {HTMLDivElement} virtualDom
     * @param {any[]} menu
     * @param {number} retryLimit
     * @returns {*}
     * @private
     */
    _wrapElementsWithLinks({
      virtualDom,
      menu,
      retryLimit
    }) {
      const errorMessage = 'Exit loop, and retry!';

      try {
        const itemsToWrapWithLink = virtualDom.querySelectorAll('[data-href]:not([data-wrapped])') || [];
        itemsToWrapWithLink.forEach(element => {
          // Has reference to virtualDom
          const vDom = element.closest('[data-type=virtual-div]');

          if (!vDom) {
            throw new Error(errorMessage);
          } // DO NOT ALLOW LINKS ON VIDEOS


          const videoWrapper = element.closest('.bigordr-video-outer-wrapper');

          if (videoWrapper) {
            return;
          }

          const urlData = this._getUrlData(element, menu);

          let href = (urlData === null || urlData === void 0 ? void 0 : urlData.href) || '';
          const title = (urlData === null || urlData === void 0 ? void 0 : urlData.title) || '';
          const target = (urlData === null || urlData === void 0 ? void 0 : urlData.target) || '_self';

          if (R.startsWith('//')(href)) {
            href = R.replace('/', '')(href);
          }

          const hasValidHref = R.pipe(R.replace(/^(https|http):\/\//, ''), R.isEmpty, R.not)(href);

          if (!href || !hasValidHref) {
            return null;
          }

          let a = element;

          if (element.tagName !== 'A') {
            a = document.createElement('a');
          }

          a.setAttribute('href', href);
          a.setAttribute('title', title);
          a.setAttribute('target', target);
          element.setAttribute('data-wrapped', 'true');
          const isBlankTarget = R.includes('_blank')(target);

          if (isBlankTarget) {
            a.setAttribute('rel', 'noopener noreferrer');
          }

          const imageParent = element.parentElement;

          if (imageParent.tagName === 'PICTURE') {
            a.innerHTML = imageParent.outerHTML;
            element.parentElement.replaceWith(a);
            return null;
          }

          if (element.tagName !== 'A') {
            a.innerHTML = element.outerHTML;
            element.replaceWith(a);
          }

          return null;
        });
      } catch (error) {
        if (!retryLimit) {
          console.error('Out of retry limit boundaries: ', retryLimit);
          this.websiteService.logErrorTask.perform({
            error: {
              message: '_wrapElementsWithLinks: Out of retry limit boundaries'
            },
            event: 'Global Error',
            page: window.location.href,
            isGlobalError: true
          });
          return;
        }

        if ((error === null || error === void 0 ? void 0 : error.message) === errorMessage) {
          return this._wrapElementsWithLinks({
            virtualDom,
            menu,
            retryLimit: --retryLimit
          });
        }

        console.error('Error: ', error);
      }
    },

    wrapElementsWithLinks(virtualDom) {
      const menu = this.website.getAllPagesFlattened();
      const retryLimit = 1000;
      const options = {
        menu,
        virtualDom,
        retryLimit
      };

      this._wrapElementsWithLinks(options);
    }

  });

  _exports.default = _default;
});