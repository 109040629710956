define("shop/pods/components/filters/filters-menu-mobile/component", ["exports", "@glimmer/component", "@ember/service", "@glimmer/tracking", "@ember/object", "ramda"], function (_exports, _component, _service, _tracking, _object, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FiltersFiltersMenuMobileComponent = (_dec = (0, _service.inject)('filters'), _dec2 = (0, _service.inject)('members'), _dec3 = (0, _service.inject)('header'), _dec4 = (0, _service.inject)('website'), (_class = class FiltersFiltersMenuMobileComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "filtersService", _descriptor2, this);

      _initializerDefineProperty(this, "membersService", _descriptor3, this);

      _initializerDefineProperty(this, "headerService", _descriptor4, this);

      _initializerDefineProperty(this, "websiteService", _descriptor5, this);

      _initializerDefineProperty(this, "header", _descriptor6, this);
    }

    onToggleRedirect(selected) {
      const linkTo = R.propOr('/', 'linkTo')(selected);
      this.router.transitionTo(linkTo);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "filtersService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "membersService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "headerService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "header", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return R.isNil(this.args.header) ? 'categories' : this.args.header;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onToggleRedirect", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onToggleRedirect"), _class.prototype)), _class));
  _exports.default = FiltersFiltersMenuMobileComponent;
});