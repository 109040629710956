define("shop/pods/components/website/website-container/component", ["exports", "ramda", "shop/utils/nventor", "@ember/component", "@ember/object", "@ember/service", "ember-concurrency", "ember-concurrency-decorators", "@ember/runloop", "shop/mixins/automations", "jquery", "shop/mixins/images"], function (_exports, R, _nventor, _component, _object, _service, _emberConcurrency, _emberConcurrencyDecorators, _runloop, _automations, _jquery, _images) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend(_automations.default, _images.default, (_obj = {
    checkoutService: (0, _service.inject)('checkout'),
    router: (0, _service.inject)(),
    websiteService: (0, _service.inject)('website'),
    menuIsAlwaysHidden: false,
    autoValidate: false,
    menuIsTooWide: false,
    defaultHasBreadcrumbs: true,
    // isFiltersMenuOverflowing: false,
    filtersMenuBottom: 0,

    init() {
      this.set('website.userLoaded', false);

      this._super(...arguments);

      (0, _runloop.next)(() => {
        // this.get('mockVerify3dsCall').perform()
        const page = this.page;
        this.setupScripts.perform({
          page
        }); // log memberId if available

        const memberId = this.get('website.user._data.memberId');
        const item = this.item;
        this.websiteService.get('logTask').perform({
          event: 'view',
          eventType: 'view',
          memberId,
          page,
          item
        });
      });
    },

    didInsertElement() {
      this._super(...arguments);

      if (!this.websiteService.isShopIndexRoute && !this.websiteService.isIndexRoute) {
        this.goToTop();
      }

      window.addEventListener('resize', () => this.handleMenuDisplayTask.perform());
      this.handleMenuDisplayTask.perform();
      const images = (0, _jquery.default)(this.element).find('img');
      this.optimizeImages(window.innerWidth, images);
      this.setupAnimations();
    },

    setupAnimations() {
      const delay = 25;

      const onScrollDebounced = () => {
        (0, _runloop.debounce)(this, this.addAnimatedClassToDom, delay);
      };

      (0, _jquery.default)(document).bind('touchmove', onScrollDebounced);
      (0, _jquery.default)(window).bind('scroll', onScrollDebounced);
      setTimeout(() => this.addAnimatedClassToDom(), 500);
    },

    willDestroyElement() {
      this._super(...arguments);

      (0, _jquery.default)(window).off('resize');
      (0, _jquery.default)(window).unbind('scroll');
      (0, _jquery.default)(document).unbind('touchmove');
    },

    *handleMenuDisplayTask() {
      const navbars = document.querySelectorAll('.my-navbar-container');
      const container = (0, _jquery.default)(this.element);
      const isSeparateLine = this.get('website._data.logoMenuPosition') === 'separateLine';
      navbars.forEach(navbar => {
        if (isSeparateLine && !this.websiteService.isMobile) {
          // always shows menu
          this.set('menuIsTooWide', false);
        } else {
          var _document$querySelect, _document$querySelect2;

          let navbarChildrenWidth = 0;

          if (navbar) {
            var _navbarChildren$map;

            const navbarChildren = [...(navbar === null || navbar === void 0 ? void 0 : navbar.children)] || [];
            navbarChildren === null || navbarChildren === void 0 ? void 0 : (_navbarChildren$map = navbarChildren.map) === null || _navbarChildren$map === void 0 ? void 0 : _navbarChildren$map.call(navbarChildren, el => {
              var _el$getBoundingClient;

              const elementData = el === null || el === void 0 ? void 0 : (_el$getBoundingClient = el.getBoundingClientRect) === null || _el$getBoundingClient === void 0 ? void 0 : _el$getBoundingClient.call(el);
              const elementWidth = (elementData === null || elementData === void 0 ? void 0 : elementData.width) || 0;
              navbarChildrenWidth += elementWidth;
            });
            let widthRef = this.get('widthRef');

            if (!widthRef) {
              var _navbar$querySelector;

              const widthRefObj = ((_navbar$querySelector = navbar.querySelector) === null || _navbar$querySelector === void 0 ? void 0 : _navbar$querySelector.call(navbar, '.menu-is-hidden')) || {};
              widthRef = (widthRefObj === null || widthRefObj === void 0 ? void 0 : widthRefObj.scrollWidth) || (widthRefObj === null || widthRefObj === void 0 ? void 0 : widthRefObj.clientWidth);

              if (widthRef) {
                this.set('widthRef', widthRef);
                widthRefObj.style.display = 'none';
              }
            }
          }

          const bodyData = (_document$querySelect = document.querySelector('body')) === null || _document$querySelect === void 0 ? void 0 : (_document$querySelect2 = _document$querySelect.getBoundingClientRect) === null || _document$querySelect2 === void 0 ? void 0 : _document$querySelect2.call(_document$querySelect);
          const bodyWidth = (bodyData === null || bodyData === void 0 ? void 0 : bodyData.width) || 0;
          const tooWide = navbarChildrenWidth > bodyWidth - 15;
          this.set('menuIsTooWide', tooWide);
        }
      });
      const shopProductsColumn = container.find('.shop-products-column');

      if (shopProductsColumn.length > 0) {
        const filtersColumn = container.find('.filters-column');

        if (filtersColumn.is(':visible')) {
          shopProductsColumn[0].className = R.replace('is-12', 'is-11')(shopProductsColumn[0].className);
          filtersColumn.find('.element-checkbox--container').css('width', 'auto');
        } else {
          shopProductsColumn[0].className = R.replace('is-11', 'is-12')(shopProductsColumn[0].className);
        }
      }
    },

    showNavbar: (0, _object.computed)('showAsModal', function () {
      var _this$websiteService;

      const showAsModal = (this === null || this === void 0 ? void 0 : this.showAsModal) || false;

      if (!showAsModal) {
        return true;
      }

      const isMobile = this === null || this === void 0 ? void 0 : (_this$websiteService = this.websiteService) === null || _this$websiteService === void 0 ? void 0 : _this$websiteService.isMobile;

      if (isMobile) {
        return false;
      }

      return true;
    }),
    _hasBreadcrumbs: (0, _object.computed)('hasBreadcrumbs', 'defaultHasBreadcrumbs', function () {
      if (!R.isNil(this.hasBreadcrumbs)) {
        return this.hasBreadcrumbs;
      }

      return this.defaultHasBreadcrumbs;
    }),
    hasTimer: (0, _object.computed)('isShopRoute', 'shop.showCountdownTimer', function () {
      var _this$website;

      if (R.includes('members')(this.router.currentRouteName)) {
        return false;
      }

      const shop = this.shop || (this === null || this === void 0 ? void 0 : (_this$website = this.website) === null || _this$website === void 0 ? void 0 : _this$website.shop);

      if (R.startsWith('shop', this.router.currentRouteName) && shop.showCountdownTimer) {
        return true;
      }

      return false;
    }),
    hasCampaignHeader: (0, _object.computed)('isShopRoute', 'shop.showCampaignHeader', function () {
      var _this$website2;

      if (R.includes('members')(this.router.currentRouteName)) {
        return false;
      }

      const shop = this.shop || (this === null || this === void 0 ? void 0 : (_this$website2 = this.website) === null || _this$website2 === void 0 ? void 0 : _this$website2.shop);

      if (R.startsWith('shop', this.router.currentRouteName) && shop.showCampaignHeader) {
        return true;
      }

      return false;
    }),
    isCheckoutDetailsRoute: (0, _object.computed)('router.currentRouteName', function () {
      const routeName = this.router.get('currentRouteName');

      if (R.startsWith('shop.checkout.details', routeName)) {
        return true;
      }

      if (R.endsWith('members.member', routeName)) {
        return true;
      }

      return false;
    }),
    menuIsHidden: (0, _object.computed)('website.menuIsAlwaysHidden', 'menuIsTooWide', function () {
      if (this.menuIsTooWide) {
        return true;
      }

      if (this.get('website.menuIsAlwaysHidden')) {
        return true;
      }

      if (this.menuIsAlwaysHidden) {
        return true;
      }

      return false;
    }),

    *setupScripts({
      page = {}
    }) {
      const website = this.website;

      if (website.get('hasWebsite')) {
        const scripts = website.get('_data.scripts');
        const headers = website.get('_data.headers');

        this._setupScriptsAndHeaders({
          scripts,
          headers
        });
      }

      if (_nventor.default.isNotNilOrEmpty(page)) {
        const scripts = page === null || page === void 0 ? void 0 : page.scripts;
        const headers = page === null || page === void 0 ? void 0 : page.headers;

        this._setupScriptsAndHeaders({
          scripts,
          headers
        });
      }
    },

    _setupScriptsAndHeaders({
      scripts,
      headers
    }) {
      if (scripts) {
        // scripts should not be enclosed with script tags
        const customScript = document.createElement('script');
        customScript.innerHTML = scripts;

        try {
          const pageScripts = (0, _jquery.default)('head');
          pageScripts.prepend(customScript);
        } catch (e) {
          // do nothing
          console.log('invalid custom scripts');
        }
      }

      if (headers) {
        try {
          const pageScripts = (0, _jquery.default)('head');
          pageScripts.prepend(headers);
        } catch (e) {
          console.log('invalid custom headers');
        }
      }
    },

    goToTop() {
      const body = (0, _jquery.default)('html, body');
      body.stop().animate({
        scrollTop: 0
      }, 500, 'swing');
    },

    isInViewport(element) {
      const rect = element.getBoundingClientRect();
      const clientHeight = window.innerHeight || document.documentElement.clientHeight;
      const elementIsInViewPortX = rect.top <= clientHeight - 20;
      return elementIsInViewPortX;
    },

    *typewriter(el, forward = true, infinite = true, animationDuration) {
      if (typeof el.innerText === 'string') {
        const content = R.clone(el.innerText);
        const contentLength = content.length;

        if (!contentLength || contentLength === 0) {
          return;
        }

        const wrappedTag = el.firstElementChild;
        const tag = wrappedTag === null || wrappedTag === void 0 ? void 0 : wrappedTag.localName;
        const blinkingCursor = document.createElement('span');
        blinkingCursor.classList.add('blinking_cursor');
        let textPosition = forward ? 0 : contentLength;
        let _timeout = 100;

        if (animationDuration) {
          _timeout = parseFloat(animationDuration) * 1000;
        }

        if (forward) {
          while (textPosition <= contentLength) {
            let appendHTML = `${content.substring(0, textPosition)}${blinkingCursor.outerHTML}`;

            if (tag) {
              appendHTML = `<${tag}>${appendHTML}</${tag}>`;
            }

            el.innerHTML = appendHTML;
            yield (0, _emberConcurrency.timeout)(_timeout);
            textPosition++;
          }
        } else {
          while (textPosition >= 0) {
            let appendHTML = `${content.substring(0, textPosition)}${blinkingCursor.outerHTML}`;

            if (tag) {
              appendHTML = `<${tag}>${appendHTML}</${tag}>`;
            }

            el.innerHTML = appendHTML;
            yield (0, _emberConcurrency.timeout)(_timeout);
            textPosition--;
          }
        }

        yield (0, _emberConcurrency.timeout)(2000);
        forward = !forward;
        let appendHTML = `${content}${blinkingCursor.outerHTML}`;

        if (tag) {
          appendHTML = `<${tag}>${appendHTML}</${tag}>`;
        }

        el.innerHTML = appendHTML;

        if (infinite) {
          this.typewriter.perform(el, forward, infinite, animationDuration);
        }
      }
    },

    addAnimatedClassToDom() {
      // Elements except title
      const ANIMATION_CLASS_NAMES = Object.freeze({
        none: '',
        fadeIn: 'bigordr-animate__fade-in',
        fadeInDown: 'bigordr-animate__fade-in-down',
        zoomIn: 'bigordr-animate__zoom-in',
        slideInDown: 'bigordr-animate__slide-in-down',
        rotate: 'bigordr-animate__rotate',
        rotateIn: 'bigordr-animate__rotate-in',
        backInDown: 'bigordr-animate__back-in-down',
        backInUp: 'bigordr-animate__back-in-up',
        flip: 'bigordr-animate__flip',
        flipInX: 'bigordr-animate__flip-in-x',
        flipInY: 'bigordr-animate__flip-in-y',
        flash: 'bigordr-animate__flash',
        pulse: 'bigordr-animate__pulse',
        rubberBand: 'bigordr-animate__rubber-band',
        bounce: 'bigordr-animate__bounce',
        shakeX: 'bigordr-animate__shake-x',
        shakeY: 'bigordr-animate__shake-y',
        swing: 'bigordr-animate__swing',
        tada: 'bigordr-animate__tada',
        wobble: 'bigordr-animate__wobble',
        jello: 'bigordr-animate__jello',
        heartbeat: 'bigordr-animate__heartbeat'
      });
      const domElementsWithAnimation = Array.from(document.querySelectorAll('[data-gjs-animation]:not([data-gjs-animation="none"]')) || [];
      R.forEach(animationDom => {
        const alreadyAnimated = R.includes('animate__animated')(animationDom.classList);

        if (!alreadyAnimated) {
          animationDom.classList.add('u-hidden-placeholder');
        }

        const animationDataset = (animationDom === null || animationDom === void 0 ? void 0 : animationDom.dataset) || {};
        const animationType = animationDataset.gjsAnimation;
        const animationIteration = animationDataset.gjsAnimation__iterationCount;
        const animationDuration = animationDataset.gjsAnimation__duration;

        if (this.isInViewport(animationDom) && !alreadyAnimated) {
          animationDom.classList.remove('u-hidden-placeholder');
          animationDom.classList.add('animate__animated');

          if (animationType) {
            animationDom.classList.add(ANIMATION_CLASS_NAMES[animationType]);

            if (animationIteration === 'infinite') {
              animationDom.classList.add('animate__infinite');
            }

            if (animationDuration) {
              animationDom.style.animationDuration = animationDuration + 's';
            }
          }
        }
      })(domElementsWithAnimation); // Title elements

      const ANIMATION_CLASS_NAMES_TITLE = Object.freeze({ ...ANIMATION_CLASS_NAMES,
        typing: 'bigordr-animate__typing'
      });
      const textsWithAnimation = Array.from(document.querySelectorAll('[data-gjs-text-animation]:not([data-gjs-text-animation="none"]')) || [];
      R.forEach(animationDom => {
        const alreadyAnimated = R.includes('animate__animated')(animationDom.classList);

        if (!alreadyAnimated) {
          animationDom.classList.add('u-hidden-placeholder');
        }

        const animationDataset = (animationDom === null || animationDom === void 0 ? void 0 : animationDom.dataset) || {};
        const animationType = animationDataset.gjsTextAnimation;
        const animationIteration = animationDataset.gjsAnimation__iterationCount;
        const animationDuration = animationDataset.gjsAnimation__duration;

        if (this.isInViewport(animationDom) && !alreadyAnimated) {
          animationDom.classList.remove('u-hidden-placeholder');
          animationDom.classList.add('animate__animated');

          if (animationType) {
            animationDom.classList.add(ANIMATION_CLASS_NAMES_TITLE[animationType]);

            if (ANIMATION_CLASS_NAMES_TITLE[animationType] === ANIMATION_CLASS_NAMES_TITLE.typing) {
              this.typewriter.perform(animationDom, true, animationIteration === 'infinite', animationDuration);
            }

            if (animationIteration === 'infinite') {
              animationDom.classList.add('animate__infinite');
            }

            if (animationDuration) {
              animationDom.style.animationDuration = animationDuration + 's';
            }
          }
        }
      })(textsWithAnimation);
    },

    goToTabLink(tabs, selected) {
      const linkTo = R.propOr('/', 'linkTo')(selected);
      selected.isActive = true;
      this.router.transitionTo(linkTo);
    },

    actions: {
      goToTop() {
        this.goToTop();
      },

      afterVerifyMember() {// intentionally do nothing.
      },

      resetAlternateLoginData() {
        this.website.set('alternateLoginData', false);
      },

      closeLimitedQtyModal() {
        this.set('shop.validated.limitedQtyCartItems', []);
        this.set('shop.validated.removedCartItems', []);
        this.cartChanged();
      },

      clearCart() {
        const msg = this.intl.t('are you sure you want to clear cart?');

        if (window.confirm(msg)) {
          this.shop.cartSuccessClear(); // this.checkoutService.clearCheckoutId()
        }
      },

      transitToCheckout() {
        this.checkoutService.clearCheckoutId();
        const website = this.website;
        return this.router.transitionTo('shop.checkout.summary', website === null || website === void 0 ? void 0 : website.shopUrlDisplay);
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "handleMenuDisplayTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_obj, "handleMenuDisplayTask"), _obj), _applyDecoratedDescriptor(_obj, "setupScripts", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_obj, "setupScripts"), _obj), _applyDecoratedDescriptor(_obj, "typewriter", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_obj, "typewriter"), _obj), _applyDecoratedDescriptor(_obj, "goToTabLink", [_object.action], Object.getOwnPropertyDescriptor(_obj, "goToTabLink"), _obj)), _obj));

  _exports.default = _default;
});