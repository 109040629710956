define("shop/pods/components/cart/checkout-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GSJcsySo",
    "block": "[[[41,[28,[37,1],[[30,1,[\"cartItems\"]],[28,[37,2],[[30,1,[\"_data\",\"isDisallowShopping\"]]],null]],null],[[[41,[51,[33,4]],[[[1,\"    \"],[8,[39,5],null,[[\"@classNames\",\"@onSubmit\"],[\"has-animation-quick checkout-btn button button--action-final button--solid\",[28,[37,6],[[30,0,[\"closeModalForShopItemAndRedirectToCheckout\"]]],null]]],[[\"default\"],[[[[1,\"\\n\"],[41,[33,7],[[[1,\"        \"],[8,[39,8],null,[[\"@icon\"],[\"fas fa-shopping-bag\"]],null],[1,\"\\n\"]],[]],[[[41,[33,9],[[[1,\"          \"],[10,1],[15,0,[29,[\"has-animation-quick shopping-cart__number \",[52,[33,10,[\"isAdding\"]],\"shopping-cart__number--adding\"]]]],[12],[1,[34,9]],[13],[1,\"\\n\"]],[]],null],[1,\"        \\n        \"],[8,[39,8],null,[[\"@icon\"],[\"fas fa-shopping-bag\"]],null],[1,\"\\n        \\n        \"],[10,1],[14,0,\"element-btn__icon-spacer\"],[12],[13],[1,\"\\n        \\n        \"],[1,[28,[35,11],[[33,12]],null]],[1,\"\\n        \\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[]],null]],[\"@shop\"],false,[\"if\",\"and\",\"not\",\"unless\",\"automation\",\"elements/element-btn\",\"fn\",\"iconOnly\",\"elements/element-icon\",\"totalItems\",\"shop\",\"tt\",\"label\"]]",
    "moduleName": "shop/pods/components/cart/checkout-btn/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});