define("shop/pods/blogs/blog/model", ["exports", "ramda", "@ember/object"], function (_exports, R, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend({
    populate(data) {
      data = {
        _data: data
      };
      this.setProperties(data);
    },

    tagNames: (0, _object.computed)('_data.tags.[]', function () {
      const tags = this.get('_data.tags') || [];
      return R.pluck('label')(tags);
    }),
    images: (0, _object.computed)('_data.uploadedFiles.[]', function () {
      const images = this.get('_data.uploadedFiles') || [];
      return R.sortWith([R.ascend(R.prop('timestampZ'))])(images);
    }),
    defaultImageBackgroundColor: (0, _object.computed)('images', '_data.defaultImage', function () {
      var _this$imagesService, _this$imagesService$g;

      return this === null || this === void 0 ? void 0 : (_this$imagesService = this.imagesService) === null || _this$imagesService === void 0 ? void 0 : (_this$imagesService$g = _this$imagesService.getBackgroundRGBColor) === null || _this$imagesService$g === void 0 ? void 0 : _this$imagesService$g.call(_this$imagesService, {
        pathArray: ['defaultImageData', 'mainColors'],
        model: this
      });
    }),
    defaultImageData: (0, _object.computed)('images', '_data.defaultImage', function () {
      const images = this.images || [];
      let uuid;

      if (this.get('_data.defaultImage')) {
        uuid = this.get('_data.defaultImage');

        if (uuid) {
          const found = R.find(R.propEq('uuid', uuid))(images);

          if (found) {
            return found;
          }
        }
      }

      if (images.get('firstObject')) {
        const firstImage = this.images.get('firstObject');

        if (firstImage) {
          return firstImage;
        }
      }

      return false;
    })
  });

  _exports.default = _default;
});