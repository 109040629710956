define("shop/pods/components/layouts/nav-vertical/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BotgCBlI",
    "block": "[[[10,0],[15,0,[29,[\"nav-menu__container vertical \",[30,1,[\"menuPosition\"]]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"nav-menu__content\"],[12],[1,\"\\n    \"],[1,[28,[35,0],null,[[\"website\",\"menu\",\"toggle\",\"isVertical\",\"initialIsExpanded\",\"isExpanded\"],[[30,1],[30,1,[\"menu\"]],[30,2],true,[30,1,[\"menuExpandSubMenus\"]],[30,1,[\"menuExpandSubMenus\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@website\",\"@toggle\"],false,[\"layouts/nav-menu-nested\"]]",
    "moduleName": "shop/pods/components/layouts/nav-vertical/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});