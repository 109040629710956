define("shop/pods/components/shop/checkout/payment-page/component", ["exports", "ramda", "@ember/component", "@ember/service", "ember-concurrency", "@ember/runloop", "@ember/object", "shop/mixins/checkout", "shop/utils/nventor", "shop/mixins/big"], function (_exports, R, _component, _service, _emberConcurrency, _runloop, _object, _checkout, _nventor, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend(_checkout.default, _big.default, (_obj = {
    ajax: (0, _service.inject)(),
    googleService: (0, _service.inject)('google'),
    router: (0, _service.inject)(),
    tapPay: (0, _service.inject)(),
    shopService: (0, _service.inject)('shop'),
    checkoutService: (0, _service.inject)('checkout'),
    allowWithPromoCode: true,
    isEditingCreditCard: false,
    tagName: '',
    error: '',
    successfulPayment: null,
    tapPayUseFields: true,
    tapPayLinePay: false,
    hasInstallments: false,
    isShowPromoCodeContainer: false,
    isShowReferralCodeContainer: false,

    init() {
      this._super(...arguments);

      this.set('requiredAttrs', ['name', 'email', 'telephone']);
      const storedCheckoutData = this.storage.getValueWithShopKey('checkoutData') || {};

      const checkoutData = _object.default.create(storedCheckoutData);

      this.set('checkoutData', checkoutData);
      this.set('shop.checkoutData', checkoutData);
      this.set('checkoutData.rewardPointApplied', 0);
      this.set('checkoutData.agreeToTermsForRecurring', false);
      const user = this.user;
      const checkoutCreditCardData = user.getAndReturnDefaultCreditCard({
        shop: this.shop
      });
      user.set('checkoutCreditCardData', checkoutCreditCardData);
      this.setupTask.perform();
      const promoCode = this.storage.getValueWithShopKey('promoCode') || (checkoutData === null || checkoutData === void 0 ? void 0 : checkoutData.promoCode);

      if (promoCode) {
        this.hasAppliedPromoCode = true;
      }
    },

    unableToSpend: (0, _object.computed)('shop.{rewardSpendDifference,checkoutData.promoCode,_data.rewardData}', 'user.{_data.validPoints,_data.memberLevelId}', 'website.isAllowSpendReward', function () {
      const website = this.website;
      const shop = this.shop;
      const rewardSpendDifference = shop.get('rewardSpendDifference');

      if (rewardSpendDifference > 0) {
        return true;
      }

      const user = this.user;
      const userValidPoints = user.get('_data.validPoints');

      if (website.isAllowSpendReward && userValidPoints > 0) {
        const promoCode = shop.get('checkoutData.promoCode');
        const memberLevelId = user.get('_data.memberLevelId');
        const rewardData = shop.get('_data.rewardData');
        let findMemberLevelBy;

        if (memberLevelId) {
          findMemberLevelBy = R.propEq('memberLevelId', memberLevelId);
        }

        if (!memberLevelId) {
          findMemberLevelBy = R.propEq('requiredPoints', 0);
        }

        const allowWithPromoCode = R.pipe(R.propOr([], 'memberLevels'), R.find(findMemberLevelBy), R.propOr([], 'rewardsEvents'), R.find(R.propEq('type', 'order')), R.propOr(false, 'allowWithPromoCode'))(rewardData);
        this.set('allowWithPromoCode', allowWithPromoCode);

        if (!allowWithPromoCode && promoCode) {
          return true;
        }
      }

      return false;
    }),
    hasCheckoutError: (0, _object.computed)('checkoutService.checkoutError', function () {
      var _this$checkoutService;

      const checkoutError = this === null || this === void 0 ? void 0 : (_this$checkoutService = this.checkoutService) === null || _this$checkoutService === void 0 ? void 0 : _this$checkoutService.checkoutError;

      if (checkoutError) {
        return true;
      }

      return false;
    }),
    containsRecurringItems: (0, _object.computed)('shop.cartItems', function () {
      const cartItems = R.pathOr([], ['shop', 'cartItems'])(this);

      if (R.find(R.propEq('isRecurring', true))(cartItems)) {
        return true;
      }

      return false;
    }),
    hasAgreeToAllTerms: (0, _object.computed)('containsRecurringItems', 'checkoutData.agreeToTermsForRecurring', function () {
      const checkoutData = this.checkoutData;

      if (this.containsRecurringItems) {
        return checkoutData.agreeToTermsForRecurring;
      }

      return true;
    }),
    zeroPointApplied: (0, _object.computed)('checkoutData.rewardPointApplied', function () {
      var _this$checkoutData;

      const rewardPointApplied = (this === null || this === void 0 ? void 0 : (_this$checkoutData = this.checkoutData) === null || _this$checkoutData === void 0 ? void 0 : _this$checkoutData.rewardPointApplied) || 0;

      if (parseInt(rewardPointApplied) === 0) {
        return true;
      }

      return false;
    }),
    selectedInstallmentDataForBank: (0, _object.computed)('creditCardInstallmentsBank', 'shop.{_data.creditCardInstallmentMinRequiredAmount,_data.creditCardInstallmentsDataByBank,inclTotal}', function () {
      var _this$shop;

      const creditCardInstallmentsDataByBank = (this === null || this === void 0 ? void 0 : (_this$shop = this.shop) === null || _this$shop === void 0 ? void 0 : _this$shop.creditCardInstallmentsDataByBank) || [];
      const creditCardInstallmentsBank = this.creditCardInstallmentsBank;

      if (!this.creditCardInstallmentsBank) {
        return {};
      }

      const installmentDataForBank = R.find(R.propEq('bank', creditCardInstallmentsBank))(creditCardInstallmentsDataByBank);
      return installmentDataForBank || {};
    }),
    allowedInstallmentPeriodsForBank: (0, _object.computed)('creditCardInstallmentsBank', 'shop.{_data.creditCardInstallmentMinRequiredAmount,_data.creditCardInstallmentsDataByBank,inclTotal}', function () {
      return this._getAllowedInstallmentPeriodsForBank({
        creditCardInstallmentsBank: this.creditCardInstallmentsBank
      });
    }),

    _getAllowedInstallmentPeriodsForBank({
      creditCardInstallmentsBank
    }) {
      const shop = (this === null || this === void 0 ? void 0 : this.shop) || {};
      const selectedInstallmentDataForBank = this.selectedInstallmentDataForBank;

      if (_nventor.default.isNilOrEmpty(selectedInstallmentDataForBank)) {
        return [];
      }

      const inclTotal = shop === null || shop === void 0 ? void 0 : shop.inclTotal;
      const creditCardInstallmentMinRequiredAmount = selectedInstallmentDataForBank === null || selectedInstallmentDataForBank === void 0 ? void 0 : selectedInstallmentDataForBank.minRequiredAmountPerPeriod;

      if (_nventor.default.isNilOrEmpty(creditCardInstallmentMinRequiredAmount)) {
        return [];
      }

      const periods = selectedInstallmentDataForBank.periodsModels || [];
      return R.filter(periodData => {
        const period = periodData.value;

        if (!period) {
          return false;
        }

        const amountPerPeriodBig = this.newBig(inclTotal).div(period);

        if (amountPerPeriodBig.gte(creditCardInstallmentMinRequiredAmount)) {
          return true;
        }

        return false;
      })(periods);
    },

    // hasAllowedInstallmentPeriods: computed('allowedInstallmentPeriods', function () {
    //   const allowedInstallmentPeriods = this?.allowedInstallmentPeriods || []
    //   return (allowedInstallmentPeriods.length >= 1)
    // }),
    // hasAllowedInstallmentPeriods: computed('creditCardInstallmentsDataByBank', function () {
    //   const creditCardInstallmentsDataByBank = this?.creditCardInstallmentsDataByBank || []
    //   const a = R.reduce((acc, installmentsData) => {
    //   }, false)(creditCardInstallmentsDataByBank)
    //   return return a
    // }),
    requiredTotalForInstallmentsForBank: (0, _object.computed)('selectedInstallmentDataForBank', function () {
      const selectedInstallmentDataForBank = this.selectedInstallmentDataForBank;

      if (!selectedInstallmentDataForBank) {
        return 0;
      }

      const minRequiredAmountPerPeriod = parseInt(selectedInstallmentDataForBank === null || selectedInstallmentDataForBank === void 0 ? void 0 : selectedInstallmentDataForBank.minRequiredAmountPerPeriod) || 0;
      const periods = selectedInstallmentDataForBank.periodsModels || [];

      if (_nventor.default.isNotNilOrEmpty(periods) && minRequiredAmountPerPeriod) {
        let minInstallmentPeriod = R.pipe(R.head, R.propOr(0, 'value'))(periods);
        minInstallmentPeriod = parseInt(minInstallmentPeriod) || 0;
        return R.multiply(minInstallmentPeriod, minRequiredAmountPerPeriod);
      }

      return 0;
    }),
    hasMetMinRequiredAmountForAllInstallments: (0, _object.computed)('requiredTotalForInstallmentsForBank', 'shop.inclTotal', function () {
      var _this$shop2;

      const requiredTotalForInstallmentsForBank = Number(this.requiredTotalForInstallmentsForBank) || false;
      const inclTotal = Number((_this$shop2 = this.shop) === null || _this$shop2 === void 0 ? void 0 : _this$shop2.inclTotal) || 0;

      if (inclTotal >= requiredTotalForInstallmentsForBank) {
        return true;
      }

      return false;
    }),
    applyRewardTask: (0, _emberConcurrency.task)(function* (method, point) {
      this.set('hasRewardError', false);
      const user = this.user;
      const shop = this.shop;
      const hasAddress = this.hasAddress;
      const checkoutData = this.checkoutData;
      let rewardPointApplied = this.get('checkoutData.rewardPointApplied') || 0;
      rewardPointApplied = parseInt(rewardPointApplied) || 0;

      if (method === 'click') {
        rewardPointApplied = rewardPointApplied + Number(point);
      }

      checkoutData.set('rewardPointApplied', rewardPointApplied);
      user.set('rewardPointApplied', rewardPointApplied);

      if (!R.isNil(rewardPointApplied) && !R.isEmpty(rewardPointApplied)) {
        yield this.shopService.get('validateCartItemsTask').perform({
          shop,
          user,
          hasAddress,
          checkoutData,
          forceValidation: true
        });

        if (shop.get('rewardAmount')) {
          this.set('hasRewardPointApplied', true);
        } else {
          this.set('hasRewardError', true);
        }

        checkoutData.set('rewardPointApplied', shop.get('rewardPointApplied'));
        this.storage.setValueWithShopKey('checkoutData', checkoutData);
      }
    }),

    onAgreeToTerms() {
      this.set('checkoutData.agreeToTermsForRecurring', true);
    },

    toggleTapPayLinePay(value = false) {
      this.set('tapPayLinePay', value);
    },

    hasCompleteCreditCardInfoTapPay() {
      var _this$checkoutData2, _user$checkoutCreditC, _user$checkoutCreditC2, _user$checkoutCreditC3, _user$checkoutCreditC4;

      if ((this === null || this === void 0 ? void 0 : (_this$checkoutData2 = this.checkoutData) === null || _this$checkoutData2 === void 0 ? void 0 : _this$checkoutData2.paymentMethod) !== 'creditCard') {
        return true;
      }

      if (this.hasInstallments) {
        if (this.creditCardInstallmentProvider === 'ecPay') {
          return true;
        }
      }

      if (this.shop.isEcPay) {
        return true;
      }

      if (this.shop.isPayuni) {
        return true;
      }

      const user = this.user;

      if (user !== null && user !== void 0 && (_user$checkoutCreditC = user.checkoutCreditCardData) !== null && _user$checkoutCreditC !== void 0 && _user$checkoutCreditC.useExistingCreditCard) {
        return true;
      }

      if (this.shop.isTapPay) {
        return this.tapPay.hasCompleteCreditCardInfoTapPay;
      }

      if (!(user !== null && user !== void 0 && (_user$checkoutCreditC2 = user.checkoutCreditCardData) !== null && _user$checkoutCreditC2 !== void 0 && _user$checkoutCreditC2.ccNumber)) {
        return false;
      }

      if (!(user !== null && user !== void 0 && (_user$checkoutCreditC3 = user.checkoutCreditCardData) !== null && _user$checkoutCreditC3 !== void 0 && _user$checkoutCreditC3.ccExpiry)) {
        return false;
      }

      if (!(user !== null && user !== void 0 && (_user$checkoutCreditC4 = user.checkoutCreditCardData) !== null && _user$checkoutCreditC4 !== void 0 && _user$checkoutCreditC4.ccSecureCode)) {
        return false;
      }

      return true;
    },

    checkoutNowTask: (0, _emberConcurrency.task)(function* () {
      var _this$checkoutData3, _this$checkoutData4;

      if (!this.hasCompleteCreditCardInfoTapPay()) {
        _nventor.default.goToClassName('checkout-payment-page__credit-card-container', -70);

        return false;
      }

      let creditCardPaymentProvider = '';

      if ((this === null || this === void 0 ? void 0 : (_this$checkoutData3 = this.checkoutData) === null || _this$checkoutData3 === void 0 ? void 0 : _this$checkoutData3.paymentMethod) === 'creditCard' || (this === null || this === void 0 ? void 0 : (_this$checkoutData4 = this.checkoutData) === null || _this$checkoutData4 === void 0 ? void 0 : _this$checkoutData4.paymentMethod) === 'linePay') {
        creditCardPaymentProvider = this.getCreditCardPaymentProvider();
        this.shop.resetRecheckDates(); // if (this?.shop?.isCreditCardUnderMaintenance) {
        //   const message = R.pathOr('銀行正在維護系統中，請您選擇其他付款方式，造成您的不便敬請見諒', [0, 'shopMessage'])(this?.shop?.maintenanceWindowsForCreditCard)
        //   this.website.set('errorMessages', [
        //     {
        //       html: message,
        //       translate: false
        //     }
        //   ])
        //   window.scrollTo(0, 0)
        //   return false
        // }
      }

      this.set('checkoutValidationError', '');
      this.set('isCheckoutBtnDisabled', true); // const user = this.get('user')

      const website = this.website;
      const user = website.get('user');
      const shop = website.get('shop');
      const checkoutData = this.checkoutData || {};

      if (this.checkoutService.checkoutId) {
        checkoutData.checkoutId = this.checkoutService.checkoutId;
      } else {
        this.checkoutService.setupCheckoutId();
        checkoutData.checkoutId = this.checkoutService.checkoutId;
      } // @NOTE: ALWAYS REMOVE CHECKOUT ID BEFORE CHECKOUT
      // @NOTE: THIS SHOULD BE ADDED WHEN CHECKOUT ID BEHAVIOUR IS FIXED


      this.checkoutService.clearCheckoutId();

      if (_nventor.default.isNilOrEmpty(checkoutData)) {
        this.router.transitionTo('shop.checkout.details');
      }

      const hasAddress = this.hasAddress;

      try {
        var _shop$_data;

        const toCheckoutData = yield this.shopService.getCheckoutDataTask.perform({
          shop,
          user,
          checkoutData,
          hasAddress,
          validateUser: false,
          tapPayUseFields: this.tapPayUseFields,
          tapPayLinePay: this.tapPayLinePay,
          creditCardPaymentProvider
        });
        const hasInstallments = (this === null || this === void 0 ? void 0 : this.hasInstallments) || false;
        const creditCardInstallmentPeriod = (this === null || this === void 0 ? void 0 : this.creditCardInstallmentPeriod) || 0;

        if (hasInstallments && R.gt(creditCardInstallmentPeriod, 0)) {
          toCheckoutData.paymentMethodIsInstallments = true;
          toCheckoutData.paymentMethodInstallmentsCount = this === null || this === void 0 ? void 0 : this.creditCardInstallmentPeriod;
          toCheckoutData.paymentMethodInstallmentsBank = this === null || this === void 0 ? void 0 : this.creditCardInstallmentBank;
          toCheckoutData.paymentMethodInstallmentsProvider = this === null || this === void 0 ? void 0 : this.creditCardInstallmentProvider;
        } else {
          toCheckoutData.paymentMethodIsInstallments = false;
          toCheckoutData.paymentMethodInstallmentsCount = '';
        }

        const randomTimer = this.newBig(this.getRandomIntInclusive(1, 5)).times(300).toFixed();
        yield (0, _emberConcurrency.timeout)(randomTimer); // use REDIS
        // const checkoutCount = yield this.getcheckoutCountTask.perform()
        // const randomTimer = this.newBig(checkoutCount).times(1000).toFixed()
        // yield timeout(randomTimer)

        const checkoutFormKey = (shop === null || shop === void 0 ? void 0 : (_shop$_data = shop._data) === null || _shop$_data === void 0 ? void 0 : _shop$_data.checkoutFormKey) || '';

        if (checkoutFormKey) {
          const checkoutFormUid = this.checkoutService.getCheckoutFormUid();
          const checkoutFormFlowKey = checkoutFormKey;
          toCheckoutData.checkoutFormUid = checkoutFormUid;
          toCheckoutData.checkoutFormFlowKey = checkoutFormFlowKey;
        }

        const checkoutResult = yield this.websiteService.checkoutTask.perform({
          user,
          userData: toCheckoutData
        });

        if (_nventor.default.isNotNilOrEmpty(checkoutResult)) {
          this._clearPromoCode();

          this.checkoutService.clearCheckoutFormUid();
        }

        return checkoutResult;
      } catch (e) {
        this.set('isCheckoutBtnDisabled', false);
        const serverErrorMsg = R.path(['payload', 'message'])(e) || false;
        const hasExpectedError = this.shopService.setCartItemErrors({
          error: e,
          shop,
          validated: false
        });

        if (hasExpectedError) {// console.log('do nothing')
        } else if (e.message === 'Checkout Validation Error') {
          this.checkoutService.clearCheckoutId(); // something went wrong with forming checkout data

          this.set('checkoutValidationError', 'error occurred');
          this.websiteService.setErrors(e, {
            errorMessage: 'error occurred, please contact us'
          });
        } else if (e.message === 'Validation Error') {
          this.checkoutService.clearCheckoutId(); // client side credit card validation error

          this.websiteService.setErrors(e, {
            errorMessage: 'error occurred, please contact us'
          });
        } else if (serverErrorMsg === 'validation error' || serverErrorMsg === 'incorrect credit card details') {
          var _e$payload, _e$payload$data;

          this.checkoutService.clearCheckoutId(); // gmo card error

          const ccError = e === null || e === void 0 ? void 0 : (_e$payload = e.payload) === null || _e$payload === void 0 ? void 0 : (_e$payload$data = _e$payload.data) === null || _e$payload$data === void 0 ? void 0 : _e$payload$data.errorMessage;

          if (ccError === 'cc_error_use_existing_card') {
            this.resetAndUseExistingCreditCard();
          }

          this.websiteService.setErrors(e, {
            errorMessage: 'error occurred, please contact us'
          });
        } else {
          // unknown & credit card errors
          // const errorData = R.path(['data'])(e) || {}
          // errorData.message = R.path(['message'])(e) || ''
          // errorData.stack = R.path(['stack'])(e) || ''
          const toCheckoutData = yield this.shopService.getCheckoutDataTask.perform({
            user,
            checkoutData,
            hasAddress,
            validate: false,
            validateUser: false,
            creditCardPaymentProvider
          });
          yield this.websiteService.get('logErrorTask').perform({
            error: e,
            checkoutData: toCheckoutData,
            automations: shop.getCartAutomationsData(),
            cart: shop.getCartItemsData()
          }); // toCheckoutData = R.clone(toCheckoutData)
          // toCheckoutData.creditCard = {}
          // let userData
          // try {
          //   userData = JSON.parse(JSON.stringify(toCheckoutData))
          // } catch (e) {
          //   userData = { error: 'error stringify user data' }
          // }
          // let toSendErrors
          // try {
          //   toSendErrors = JSON.parse(JSON.stringify(errorData))
          // } catch (e) {
          //   toSendErrors = { error: 'to send error stringify error' }
          // }
          // yield this.websiteService.get('logTask').perform({
          //   event: 'checkoutValidationError',
          //   eventType: 'error',
          //   page: 'checkout/detail-page',
          //   errors: toSendErrors || 'validationError',
          //   data: {
          //     errors: toSendErrors || 'validationError',
          //     version: config.APP.version,
          //     userData,
          //     automations: shop.getCartAutomationsData(),
          //     cart: shop.getCartItemsData()
          //   }
          // })

          if (R.includes('TapPayError', e.message)) {
            let errorMessage = 'invalid credit card or bank processing error';
            const cannotGetPrime = R.pipe(R.pathOr('', ['message']), R.includes('prime'))(e);

            if (cannotGetPrime) {
              // error message concat ['invalid credit card or bank processing error']
              errorMessage = 'an error occurred with your credit card processing. please try entering your credit card again manually without autocomplete';
            }

            this.checkoutService.clearCheckoutId();
            this.websiteService.setErrors(e, {
              errorMessage
            });
          } else {
            this.checkoutService.clearCheckoutId();
            this.websiteService.setErrors({}, {
              errorMessage: 'error occurred, please contact us'
            });
          }
        }
      }
    }).drop(),

    getCreditCardPaymentProvider() {
      let creditCardPaymentProvider;
      const shop = this.shop;

      if (shop !== null && shop !== void 0 && shop.isTapPay) {
        creditCardPaymentProvider = 'tapPay';
      }

      if (shop !== null && shop !== void 0 && shop.isPayuni) {
        creditCardPaymentProvider = 'payuni';
      }

      if (shop !== null && shop !== void 0 && shop.isEcPay) {
        creditCardPaymentProvider = 'ecPay';
      }

      if (this.hasInstallments) {
        creditCardPaymentProvider = this.creditCardInstallmentProvider;
      }

      return creditCardPaymentProvider;
    },

    getRandomIntInclusive(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1) + min);
    },

    onSelectPaymentMethod(value) {
      if (!value) {
        return;
      }

      this.set('checkoutData.paymentMethod', value);
      this.preCheckoutValidation.perform();
    },

    setupTask: (0, _emberConcurrency.task)(function* () {
      const checkoutData = yield this.preCheckoutValidation.perform();
      this.setupPaymentMethod(checkoutData);
    }).restartable(),
    preCheckoutValidation: (0, _emberConcurrency.task)(function* () {
      // will redirect if has successful
      const shop = this.shop;
      yield this.checkoutService.queryCheckoutStatus.perform(shop);
      const checkoutData = this.checkoutData;
      const user = this.user;
      const hasAddress = this.hasAddress;
      yield this.shopService.get('validateCartItemsTask').perform({
        shop,
        checkoutData,
        user,
        hasAddress,
        isPaymentPage: true
      });
      return checkoutData;
    }).restartable(),

    resetAndUseExistingCreditCard() {
      const user = this.user;
      const checkoutCreditCardData = user.getAndReturnDefaultCreditCard({
        shop: this.shop
      });
      user.set('checkoutCreditCardData', checkoutCreditCardData);
      this.toggleProperty('isEditingCreditCard');
      this.set('isNewCreditCard', false);
      this.websiteService.clearErrors();
    },

    actions: {
      editCreditCard() {
        const user = this.user;
        const checkoutCreditCardData = user.get('checkoutCreditCardData');
        checkoutCreditCardData.toggleProperty('useExistingCreditCard');
        this.toggleProperty('isEditingCreditCard');
        this.set('isNewCreditCard', false);
      },

      resetAndUseExistingCreditCard() {
        this.resetAndUseExistingCreditCard();
      },

      changeCreditCard(ccNumber) {
        const user = this.user;
        const checkoutCreditCardData = user.getAndReturnDefaultCreditCard({
          shop: this.shop,
          ccId: ccNumber
        });
        user.set('checkoutCreditCardData', checkoutCreditCardData);
      },

      newCreditCard() {
        const user = this.user;
        const checkoutCreditCardData = user.getAndReturnDefaultCreditCard({
          shop: this.shop,
          newCard: true
        });
        user.set('checkoutCreditCardData', checkoutCreditCardData);
        user.set('checkoutCreditCardData.useExistingCreditCard', false);
        this.toggleProperty('isEditingCreditCard');
        this.set('isNewCreditCard', true);
        this.websiteService.clearErrors();
      },

      previous() {
        return this.router.transitionTo('shop.checkout.details');
      },

      clearCart() {
        const msg = this.intl.t('are you sure you want to clear cart?');

        if (window.confirm(msg)) {
          this.shop.cartSuccessClear(); // this.checkoutService.clearCheckoutId()
        }
      }

    },

    _clearPromoCode() {
      this.shop.set('promoCode', '');
      this.shopService.setPromoCode('');
      this.set('hasPromoCode', false);
      this.checkoutData.set('promoCode', '');
      this.storage.setValueWithShopKey('checkoutData', this.checkoutData);

      _nventor.default.removeAndUpdateQueryParams(['promo', '']);
    },

    isCheckoutButtonDisabled: (0, _object.computed)('hasCheckoutError', 'shop.{hasNotAllowedItems,hasCartValidationError}', function () {
      const hasCheckoutError = (this === null || this === void 0 ? void 0 : this.hasCheckoutError) || false;

      if (hasCheckoutError) {
        return true;
      }

      const shop = (this === null || this === void 0 ? void 0 : this.shop) || {};
      const hasNotAllowedItems = (shop === null || shop === void 0 ? void 0 : shop.hasNotAllowedItems) || false;

      if (hasNotAllowedItems) {
        return true;
      }

      const hasCartValidationError = (shop === null || shop === void 0 ? void 0 : shop.hasCartValidationError) || false;

      if (hasCartValidationError) {
        return true;
      }

      return false;
    }),

    setupInstallments() {
      const hasInstallments = (this === null || this === void 0 ? void 0 : this.hasInstallments) || false;

      if (!hasInstallments) {
        this.selectCreditCardInstallmentsBank('');
        return;
      }

      const creditCardInstallmentsDataByBank = R.pathOr([], ['shop', '_data', 'creditCardInstallmentsDataByBank'])(this);
      let bank = this.creditCardInstallmentsBank;

      if (bank) {
        bank = R.find(R.path(['bank']))(creditCardInstallmentsDataByBank) || '';
      }

      if (!bank) {
        bank = R.path([0, 'bank'])(creditCardInstallmentsDataByBank);
      }

      this.selectCreditCardInstallmentsBank(bank);
    },

    isTapPay: (0, _object.computed)('shop._data.creditCardProvider', 'hasInstallments', function () {
      var _this$shop3, _this$shop3$_data;

      let provider = this === null || this === void 0 ? void 0 : (_this$shop3 = this.shop) === null || _this$shop3 === void 0 ? void 0 : (_this$shop3$_data = _this$shop3._data) === null || _this$shop3$_data === void 0 ? void 0 : _this$shop3$_data.creditCardProvider;

      if (this.hasInstallments) {
        provider = this.creditCardInstallmentProvider;
      }

      if (provider === 'tapPay') {
        return true;
      }

      return false;
    }),
    isEcPay: (0, _object.computed)('shop.isEcPay', 'hasInstallments', function () {
      var _this$shop4;

      let isEcPay = this === null || this === void 0 ? void 0 : (_this$shop4 = this.shop) === null || _this$shop4 === void 0 ? void 0 : _this$shop4.isEcPay;

      if (this.hasInstallments) {
        const provider = this.creditCardInstallmentProvider;

        if (provider === 'ecPay') {
          return true;
        }

        return false;
      }

      return isEcPay;
    }),
    isPayuni: (0, _object.computed)('shop.isPayuni', 'hasInstallments', function () {
      var _this$shop5;

      let isPayuni = this === null || this === void 0 ? void 0 : (_this$shop5 = this.shop) === null || _this$shop5 === void 0 ? void 0 : _this$shop5.isPayuni;

      if (this.hasInstallments) {
        const provider = this.creditCardInstallmentProvider;

        if (provider === 'payuni') {
          return true;
        }

        return false;
      }

      return isPayuni;
    }),

    selectCreditCardInstallmentsBank(bank) {
      this.set('creditCardInstallmentsBank', bank); // const creditCardInstallmentsDataByBank = R.pathOr([], ['shop', 'creditCardInstallmentsDataByBank'])(this)
      // const installmentDataForBank = const selectedInstallmentDataForBank = this.selectedInstallmentDataForBank

      const selectedInstallmentDataForBank = this.selectedInstallmentDataForBank;

      if (!selectedInstallmentDataForBank) {
        this.creditCardInstallmentsBank = '';
      }

      const allowedInstallmentPeriodsForBank = this.allowedInstallmentPeriodsForBank;
      let minInstallmentPeriod = '';

      if (this.hasInstallments && _nventor.default.isNotNilOrEmpty(allowedInstallmentPeriodsForBank)) {
        minInstallmentPeriod = _nventor.default.safeHeadOr('', allowedInstallmentPeriodsForBank);
      }

      this.set('creditCardInstallmentPeriod', minInstallmentPeriod.value);
      this.set('creditCardInstallmentBank', (selectedInstallmentDataForBank === null || selectedInstallmentDataForBank === void 0 ? void 0 : selectedInstallmentDataForBank.bank) || '');
      this.set('creditCardInstallmentProvider', (selectedInstallmentDataForBank === null || selectedInstallmentDataForBank === void 0 ? void 0 : selectedInstallmentDataForBank.provider) || '');
    },

    showPromoCodeContainer() {
      this.set('isShowPromoCodeContainer', true);
      (0, _runloop.scheduleOnce)('afterRender', () => {
        _nventor.default.goToClassName('promo-code-container', -120);
      });
    },

    showReferralCodeContainer() {
      this.set('isShowReferralCodeContainer', true);
      (0, _runloop.scheduleOnce)('afterRender', () => {
        _nventor.default.goToClassName('referral-code-container', -120);
      });
    },

    validateCartItemsWithPromoCodeTask: (0, _emberConcurrency.task)(function* () {
      const user = this.user || {};
      const shop = this.shop || {};
      const hasAddress = this.hasAddress || {};
      const checkoutData = this.checkoutData || {};
      return yield this.shopService.get('validateCartItemsTask').perform({
        shop,
        user,
        hasAddress,
        checkoutData
      }).catch(e => {
        return this.shopService.get('validateCartItemsTask').perform({
          shop,
          user,
          hasAddress,
          checkoutData
        });
      });
    }),

    _resetPromoCodeOnCheckoutDataCache(checkoutData) {
      checkoutData.set('promoCode', '');
      this.storage.setValueWithShopKey('checkoutData', checkoutData);
      this.shopService.setPromoCode();
    },

    incorrectPromoCode: '',
    hasPromoCodeError: false,
    hasAppliedPromoCode: false,
    applyPromoCodeTask: (0, _emberConcurrency.task)(function* () {
      const shop = this.shop || {};
      const checkoutData = this.checkoutData || {};

      if (checkoutData !== null && checkoutData !== void 0 && checkoutData.promoCode) {
        (0, _object.set)(this, 'hasPromoCodeError', false);
        const promoCode = (checkoutData === null || checkoutData === void 0 ? void 0 : checkoutData.promoCode) || '';
        yield this.validateCartItemsWithPromoCodeTask.perform();

        if (shop.discountAmount) {
          (0, _object.set)(this, 'hasAppliedPromoCode', true);
          this.storage.setValueWithShopKey('checkoutData', checkoutData);
        } else {
          this.shopService.setPromoCode('');

          this._resetPromoCodeOnCheckoutDataCache(checkoutData);

          (0, _object.set)(this, 'hasAppliedPromoCode', false);
          (0, _object.set)(this, 'incorrectPromoCode', promoCode);
          (0, _object.set)(this, 'hasPromoCodeError', true);
        }
      }
    }).drop(),
    clearPromoCodeTask: (0, _emberConcurrency.task)(function* () {
      const user = this.user || {};
      const shop = this.shop || {};
      const hasAddress = this.hasAddress || false;
      const checkoutData = this.checkoutData || {};

      if (_nventor.default.isNotNilOrEmpty(checkoutData)) {
        this._resetPromoCodeOnCheckoutDataCache(checkoutData);
      }

      _nventor.default.removeAndUpdateQueryParams(['promo', '']);

      this.shopService.setPromoCode(''); // set(this, 'isSuccess', false)

      yield this.shopService.get('validateCartItemsTask').perform({
        shop,
        user,
        hasAddress,
        checkoutData
      });
      this.websiteService.clearMessages();
      (0, _object.set)(this, 'hasAppliedPromoCode', false);
      (0, _object.set)(this, 'isShowPromoCodeContainer', true); // const isLoggedIn = this.args?.website?.isLoggedIn || false
      // const promoCode = checkoutData?.promoCode
      // const isShowAvailablePromoCodes = this.isShowAvailablePromoCodes || false
      // if (isLoggedIn && !promoCode && !isShowAvailablePromoCodes) {
      //   this.getPromoCodesListTask.perform()
      // }
    }).drop(),
    hasClaimedReferralCode: false,
    referralCodeError: '',
    applyReferralCodeTask: (0, _emberConcurrency.task)(function* () {
      var _user$_data;

      const user = this.user || {};
      const shop = this.shop || {};
      const checkoutData = this.checkoutData || {};
      (0, _object.set)(this, 'hasClaimedReferralCode', false);
      (0, _object.set)(this, 'referralCodeError', '');
      const email = (user === null || user === void 0 ? void 0 : (_user$_data = user._data) === null || _user$_data === void 0 ? void 0 : _user$_data.email) || '';
      let referralCode = checkoutData.referralCode || '';

      if (R.includes('referral?code=', referralCode)) {
        const referralUrlData = R.split('referral?code=')(referralCode);
        referralCode = R.pathOr('', [1])(referralUrlData);
      }

      if (referralCode && email) {
        var _response$data, _response$data2;

        const shopUrl = shop.url;
        const response = yield this.websiteService.request(`/api/public/websites/members/${shopUrl}/referral`, {
          method: 'POST',
          data: {
            recipientEmail: email,
            referralCode,
            shopKey: shop.shopKey || shop._data._key,
            podKey: shop._data.podKey,
            isSendReferralEmail: true
          }
        });

        if (response !== null && response !== void 0 && (_response$data = response.data) !== null && _response$data !== void 0 && _response$data.errors) {
          (0, _object.set)(this, 'hasClaimedReferralCode', false);
          (0, _object.set)(this, 'referralCodeError', response.data.message);
        }

        if (response !== null && response !== void 0 && (_response$data2 = response.data) !== null && _response$data2 !== void 0 && _response$data2.model) {
          var _response$data3, _response$data3$model;

          (0, _object.set)(this, 'hasClaimedReferralCode', true);
          (0, _object.set)(this, 'referralCodeError', '');
          (0, _object.set)(this, 'createdReferralCodeData', response.data.model);

          const hasShopAndCheckoutData = _nventor.default.isNotNilOrEmpty(shop) && _nventor.default.isNotNilOrEmpty(checkoutData);

          const promoCodeData = (response === null || response === void 0 ? void 0 : (_response$data3 = response.data) === null || _response$data3 === void 0 ? void 0 : (_response$data3$model = _response$data3.model) === null || _response$data3$model === void 0 ? void 0 : _response$data3$model.promoCodeData) || {};
          const promoCode = promoCodeData.code || '';

          if (hasShopAndCheckoutData && promoCode) {
            checkoutData.set('promoCode', promoCode);
            shop.set('promoCode', promoCode);
            this.applyPromoCodeTask.perform();
          }
        }
      }
    }).drop()
  }, (_applyDecoratedDescriptor(_obj, "_getAllowedInstallmentPeriodsForBank", [_object.action], Object.getOwnPropertyDescriptor(_obj, "_getAllowedInstallmentPeriodsForBank"), _obj), _applyDecoratedDescriptor(_obj, "onAgreeToTerms", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onAgreeToTerms"), _obj), _applyDecoratedDescriptor(_obj, "toggleTapPayLinePay", [_object.action], Object.getOwnPropertyDescriptor(_obj, "toggleTapPayLinePay"), _obj), _applyDecoratedDescriptor(_obj, "getCreditCardPaymentProvider", [_object.action], Object.getOwnPropertyDescriptor(_obj, "getCreditCardPaymentProvider"), _obj), _applyDecoratedDescriptor(_obj, "onSelectPaymentMethod", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onSelectPaymentMethod"), _obj), _applyDecoratedDescriptor(_obj, "setupInstallments", [_object.action], Object.getOwnPropertyDescriptor(_obj, "setupInstallments"), _obj), _applyDecoratedDescriptor(_obj, "selectCreditCardInstallmentsBank", [_object.action], Object.getOwnPropertyDescriptor(_obj, "selectCreditCardInstallmentsBank"), _obj), _applyDecoratedDescriptor(_obj, "showPromoCodeContainer", [_object.action], Object.getOwnPropertyDescriptor(_obj, "showPromoCodeContainer"), _obj), _applyDecoratedDescriptor(_obj, "showReferralCodeContainer", [_object.action], Object.getOwnPropertyDescriptor(_obj, "showReferralCodeContainer"), _obj)), _obj));

  _exports.default = _default;
});