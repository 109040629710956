define("shop/pods/components/layouts/nav-menu-nested/nav-item/component", ["exports", "ramda", "@ember/component", "@ember/object", "@ember/service", "shop/utils/nventor"], function (_exports, R, _component, _object, _service, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_obj = {
    router: (0, _service.inject)(),
    websiteService: (0, _service.inject)('website'),
    tagName: 'div',
    // classNames: ['nav-tree__item'],
    translate: true,
    item: null,
    labelKey: 'label',
    toggle: null,

    didInsertElement() {
      this._super(...arguments);

      let pathName = window.location.pathname;
      let linkToUrl = this.item.linkToUrl || '';
      pathName = R.dropWhile(x => x === '/')(pathName);
      linkToUrl = R.dropWhile(x => x === '/')(linkToUrl);
      const deCodedPathName = decodeURIComponent(pathName);

      if (deCodedPathName === linkToUrl) {
        this.set('isActive', true);
      } else {
        this.set('isActive', false);
      }
    },

    hasTranslate: (0, _object.computed)('translate', 'item.translate', function () {
      const itemTranslate = this.get('item.translate');

      if (itemTranslate) {
        return true;
      }

      if (this.translate) {
        return true;
      }

      return false;
    }),
    label: (0, _object.computed)('item', 'labelKey', function () {
      return this.get('item.' + this.labelKey);
    }),
    toggleIcon: (0, _object.computed)('isExpanded', function () {
      const isExpanded = this.isExpanded;

      if (isExpanded) {
        return 'nav-item__toggler-icon fas fa-angle-up fa-rotate-180';
      }

      return 'nav-item__toggler-icon fas fa-angle-up';
    }),
    // linkToUrl: computed('item.{url,linkToItem}', function () {
    //   const url = this.get('item.url') || ''
    //   if (this.get('item.isAutoMenu')) {
    //     let route = this.get('item.route')
    //     route = R.split('.', route)
    //     if (!R.isEmpty(route)) {
    //       route = R.head(route)
    //     }
    //     if (url) {
    //       return `/${route}/${url}`
    //     }
    //     return `/${route}`
    //   }
    //   if (!url) {
    //     return '/'
    //   }
    //   if (this.get('item.linkToItemKey')) {
    //     const shopUrl = this.get('website.shopUrl')
    //     if (!shopUrl) {
    //       return ''
    //     }
    //     return `/shop/${shopUrl}/${url}`
    //   }
    //   if (this.isNewTabLink) {
    //     if (R.startsWith('http', url)) {
    //       return url
    //     }
    //     return `http://${url}`
    //   }
    //   return `/${url}`
    // }),
    isNewTabLink: (0, _object.computed)('item.{type,linkType}', function () {
      if (this.get('item.type') === 'link' && this.get('item.linkType') === 'url') {
        return true;
      }

      return false;
    }),
    isDisabled: (0, _object.computed)('item.hasTemplate', function () {
      if (this.get('item.type') === 'category') {
        return false;
      }

      if (!this.get('item.hasTemplate') && !this.get('item.isAutoMenu') && !this.get('item.isHome') && this.get('item.type') !== 'link') {
        return true;
      }

      return false;
    }),
    isCurrentRoute: (0, _object.computed)('label', 'item.route', function () {
      const routeName = this.item.route || this.label;
      const currentRoute = this.get('router').get('currentRouteName');
      const pathname = R.pipe(R.pathOr('', ['location', 'pathname']), R.split('/'), R.last)(window);
      const isHomeRoute = R.equals('website.index')(currentRoute);
      const isHome = this.item.isHome;

      if (isHome && isHomeRoute) {
        return true;
      }

      const deCodedPathName = decodeURIComponent(pathname);
      const isRouteNameEqualPathname = R.equals(routeName)(deCodedPathName);

      if (currentRoute === 'website.page' && isRouteNameEqualPathname) {
        return true;
      }

      const pathnameLength = R.pipe(R.split('/'), R.length)(window.location.pathname);

      if (R.startsWith(routeName)(currentRoute) && pathnameLength <= 3) {
        return true;
      }

      if (R.startsWith(routeName)(currentRoute) && R.includes(pathname)(routeName) && pathnameLength > 3) {
        return true;
      }

      return false;
    }),

    navItemTransition(item) {
      this.set('count', 0);

      if (item.isDynamicPage && item.route) {
        return this.router.transitionTo(item.route);
      }

      if (item.route) {
        const params = item.params || false;

        if (params) {
          return this.router.transitionTo(item.route, params);
        }

        return this.router.transitionTo(item.route);
      }

      const url = item.linkToUrl;

      if (url) {
        // const website = this.websiteService.website
        try {
          return this.router.transitionTo(url);
        } catch (e) {
          console.log(e);
        }
      }

      return this.router.transitionTo('/');
    },

    linkTo(item, e) {
      e.preventDefault();
      return this.navItemTransition(item);
    },

    toggleMenu(e) {
      e.preventDefault();

      if (!this.onToggle) {
        return false;
      }

      return this.onToggle();
    },

    setNavChildrenContainerPosition() {
      const element = this.element;
      const menuItem = element === null || element === void 0 ? void 0 : element.closest('.nav-nested:not(.is-sub)');
      const container = element === null || element === void 0 ? void 0 : element.closest('.nav-tree.nav-tree__children-container');

      if (menuItem && container) {
        var _window;

        const menuItemPosition = menuItem.getBoundingClientRect();
        const menuItemPositionX = (menuItemPosition === null || menuItemPosition === void 0 ? void 0 : menuItemPosition.x) || 0;
        const containerWidth = (container === null || container === void 0 ? void 0 : container.offsetWidth) || 0;
        const screenWidth = ((_window = window) === null || _window === void 0 ? void 0 : _window.innerWidth) || 0;
        const rightPart = screenWidth - menuItemPositionX;

        if (rightPart < containerWidth || containerWidth >= screenWidth) {
          container.style.right = '5px';
          container.style.maxWidth = `${screenWidth - 10}px`;
          container.isResized = true;
        }

        const isResized = container.isResized || false;
        const hasIsExpandableParent = container.closest('.nav-nested.is-sub');

        if (menuItemPosition !== 0 && !hasIsExpandableParent && !isResized) {
          container.style.right = '';
          container.style.left = `${menuItemPositionX}px`;
        }
      }
    },

    actions: {
      select(item) {
        try {
          this.websiteService.cleanupDrawerOnClose({
            prop: 'isShowDrawerForShopItem'
          });

          if (item.isHome) {
            if (this.websiteService.get('isBigOrdrShopRoute')) {
              return this.router.transitionTo('shop', {
                queryParams: {
                  query: null,
                  tag: null,
                  tags: null
                }
              });
            }
          }

          if (this.isNewTabLink) {
            window.open(item.linkToUrl, '_blank');
            return;
          }

          if (this.isDisabled) {
            if (this.onToggle) {
              return this.onToggle();
            }
          } else {
            if (item.route) {
              const params = item.params || false;

              if (params) {
                return this.router.transitionTo(item.route, params);
              }

              return this.router.transitionTo(item.route);
            }
            /**
             * @TODO: Refactor linkToUrl to return an object with route and params
             * @example
             * const linkToUrl = {
             *   url: url,
             *   route: 'shop.index',
             *   params: {
             *     query: null,
             *     tags: null
             *   }
             * }
             * */


            const url = item.linkToUrl;

            if (url) {
              if (url === '/shop/products' || item !== null && item !== void 0 && item.isAllProducts) {
                return this.router.transitionTo('shop.index', 'products', {
                  queryParams: {
                    query: null,
                    tags: null
                  }
                });
              }

              return this.router.transitionTo(url);
            }

            return this.router.transitionTo('/');
          }
        } catch (e) {// continue regardless of error
        }
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "linkTo", [_object.action], Object.getOwnPropertyDescriptor(_obj, "linkTo"), _obj), _applyDecoratedDescriptor(_obj, "toggleMenu", [_object.action], Object.getOwnPropertyDescriptor(_obj, "toggleMenu"), _obj), _applyDecoratedDescriptor(_obj, "setNavChildrenContainerPosition", [_object.action], Object.getOwnPropertyDescriptor(_obj, "setNavChildrenContainerPosition"), _obj)), _obj));

  _exports.default = _default;
});