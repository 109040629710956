define("shop/pods/components/payuni/card/component", ["exports", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/service", "@ember/runloop", "ember-concurrency-decorators", "ramda", "shop/utils/nventor"], function (_exports, _component, _object, _tracking, _service, _runloop, _emberConcurrencyDecorators, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PayUniCardComponent = (_dec = (0, _service.inject)('website'), _dec2 = (0, _service.inject)('members'), (_class = class PayUniCardComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "websiteService", _descriptor, this);

      _initializerDefineProperty(this, "membersService", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "isEditingCreditCard", _descriptor4, this);

      _initializerDefineProperty(this, "isNewCreditCard", _descriptor5, this);

      _defineProperty(this, "selectedCCId", '');

      _defineProperty(this, "availableCards", []);

      this.fetchCcTask.perform();
    }

    _setDefaultCard(validCards) {
      var _shop$_data;

      const defaultCard = R.find(R.propEq('isDefault', true))(validCards) || {};

      if (!R.isEmpty(defaultCard)) {
        this.setCard(defaultCard.ccId);
      }

      const user = this.args.user;
      const shop = this.args.shop;

      if (shop !== null && shop !== void 0 && shop.hasRecurringItems && user) {
        (0, _object.set)(user, '_data.checkoutCreditCardData.rememberCreditCard', true);
      }

      const creditCardProviderAllowRememberCard = (shop === null || shop === void 0 ? void 0 : (_shop$_data = shop._data) === null || _shop$_data === void 0 ? void 0 : _shop$_data.creditCardProviderAllowRememberCard) || false;

      if (!creditCardProviderAllowRememberCard && user) {
        (0, _object.set)(user, '_data.checkoutCreditCardData.rememberCreditCard', false);
      }
    }

    *fetchCcTask() {
      var _this$args$user, _this$args$user$trans;

      const url = '/api/protected/websites/members/credit-card';
      const response = yield this.websiteService.request(url, {
        method: 'GET',
        data: {}
      });
      let validCards = R.pipe(R.propOr([], 'data'), R.filter(R.allPass([R.propEq('provider', 'payuni'), R.propEq('isValid', true)])))(response);
      const transformedValidCards = (_this$args$user = this.args.user) === null || _this$args$user === void 0 ? void 0 : (_this$args$user$trans = _this$args$user.transformCreditCard) === null || _this$args$user$trans === void 0 ? void 0 : _this$args$user$trans.call(_this$args$user, {
        paymentMethods: validCards
      });
      validCards = (transformedValidCards === null || transformedValidCards === void 0 ? void 0 : transformedValidCards.paymentMethods) || [];
      validCards.push({
        ccId: '',
        ccDesc: this.intl.t('use new credit card')
      });

      this._setDefaultCard(validCards);

      (0, _object.set)(this, 'availableCards', validCards);
    }

    setCard(selectedCCId) {
      var _this$args$user2, _this$args$user2$sele;

      (_this$args$user2 = this.args.user) === null || _this$args$user2 === void 0 ? void 0 : (_this$args$user2$sele = _this$args$user2.selectExistingCreditCard) === null || _this$args$user2$sele === void 0 ? void 0 : _this$args$user2$sele.call(_this$args$user2, selectedCCId);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "membersService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isEditingCreditCard", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isNewCreditCard", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchCcTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchCcTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setCard", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setCard"), _class.prototype)), _class));
  _exports.default = PayUniCardComponent;
});