define("shop/pods/shop/model", ["exports", "ramda", "@ember/object", "@ember/service", "shop/mixins/big", "shop/mixins/date", "shop/pods/shop/automations", "shop/pods/shop/item/model", "shop/pods/shop/automation/model", "shop/utils/nventor"], function (_exports, R, _object, _service, _big, _date, _automations, _model, _model2, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const defaultParams = {
    query: null,
    prices: [],
    tags: [],
    sortBy: null,
    page: null,
    count: null
  };

  var _default = _object.default.extend(_big.default, _date.default, (_obj = {
    // fastboot: service(),
    storage: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    bigService: (0, _service.inject)('big'),
    imagesService: (0, _service.inject)('images'),
    googleService: (0, _service.inject)('google'),
    websiteService: (0, _service.inject)('website'),
    checkoutService: (0, _service.inject)('checkout'),
    dateService: (0, _service.inject)('date'),
    shopService: (0, _service.inject)('shop'),
    isShopLoginSuccess: false,

    init() {
      this._super(...arguments);

      this.set('checkoutSteps', ['checkout_summary', 'checkout_details', 'checkout_payment']);
      this.set('params', defaultParams);
    },

    populate(data = {}) {
      var _data, _data$_data, _data2, _data2$_data;

      if (!R.is(Object, data)) {
        return {};
      }

      if (data._data) {
        // already populated before
        data = data._data;
      }

      (0, _object.set)(data, 'showEnterPromoCode', R.propOr(true, 'showEnterPromoCode')(data)); // default to true

      data.showHeaderImg = R.propOr(false, 'showHeaderImg')(data);
      data.headerImgRatio = data.headerImgRatio || 'twoByOne';
      data.onSaleTagDisplayPosition = data.onSaleTagDisplayPosition || 'left-top';
      data.onSaleTagDisplayStyle = data.onSaleTagDisplayStyle || 'default'; // TODO: PICKUP METHODS SHOULD BE HANDLE BY THE API

      data = this._transformPickupMethods(data);
      data = {
        _data: data
      };
      const shopKey = (_data = data) === null || _data === void 0 ? void 0 : (_data$_data = _data._data) === null || _data$_data === void 0 ? void 0 : _data$_data._key;
      const url = (_data2 = data) === null || _data2 === void 0 ? void 0 : (_data2$_data = _data2._data) === null || _data2$_data === void 0 ? void 0 : _data2$_data.url;
      this.set('shopKey', shopKey);
      this.set('url', url);
      const cartItems = this.getFromStorage('cartItems');
      this.set('cartItems', this.persistToStorage('cartItems', cartItems));

      const automations = this._getAutomationsFromStorage('automations');

      this.set('automations', this._storeAutomations(automations));
      this.setProperties(data);
    },

    _createItemModel(item) {
      var _this$_data, _this$_data2;

      const itemModel = _model.default.create();

      itemModel.set('imagesService', this.imagesService);
      itemModel.set('bigService', this.bigService);
      (0, _object.set)(item, 'onSaleTagDisplayPosition', this === null || this === void 0 ? void 0 : (_this$_data = this._data) === null || _this$_data === void 0 ? void 0 : _this$_data.onSaleTagDisplayPosition);
      (0, _object.set)(item, 'onSaleTagDisplayStyle', this === null || this === void 0 ? void 0 : (_this$_data2 = this._data) === null || _this$_data2 === void 0 ? void 0 : _this$_data2.onSaleTagDisplayStyle);
      itemModel.populate(item);
      return itemModel;
    },

    _getAutomationsFromStorage(key) {
      try {
        const shopKey = this.shopKey;
        const value = this.storage.getValue(`${key}${shopKey}`) || [];
        return R.map(automation => {
          const automationModel = _model2.default.create();

          automationModel.populate(automation);
          return automationModel;
        })(value);
      } catch (e) {
        console.error('cannot get automations', e);
        return [];
      }
    },

    _storeAutomations(automations = []) {
      try {
        const toStoreAutomations = R.clone(automations);
        this.persistToStorage('automations', toStoreAutomations);
      } catch (e) {
        console.log('cannot store automations');
        console.error(e);
        this.persistToStorage('automations', []);
      }

      return automations;
    },

    isAllowShop: (0, _object.computed)('isShopLoginSuccess', function () {
      var _this$_data3;

      const hasCampaignPassword = (this === null || this === void 0 ? void 0 : (_this$_data3 = this._data) === null || _this$_data3 === void 0 ? void 0 : _this$_data3.hasCampaignPassword) || (this === null || this === void 0 ? void 0 : this.hasCampaignPassword) || false;

      if (!hasCampaignPassword) {
        return true;
      }

      const isShopLoginSuccess = (this === null || this === void 0 ? void 0 : this.isShopLoginSuccess) || false;
      const isAllowed = this.storage.getValueWithShopKey('isAllowed') || false;
      return isShopLoginSuccess || isAllowed;
    }),
    isShowPrices: (0, _object.computed)('_data.{isShowPrices,isDisallowShopping}', function () {
      var _this$_data4;

      const isDisallowShopping = (this === null || this === void 0 ? void 0 : (_this$_data4 = this._data) === null || _this$_data4 === void 0 ? void 0 : _this$_data4.isDisallowShopping) || false;

      if (!isDisallowShopping) {
        return true;
      }

      return R.pathOr(false, ['_data', 'isShowPrices'])(this);
    }),
    tabs: (0, _object.computed)('_data.{template,faqs.@each.isActive}', function () {
      var _this$_data5;

      const tabs = [];
      const template = (this === null || this === void 0 ? void 0 : (_this$_data5 = this._data) === null || _this$_data5 === void 0 ? void 0 : _this$_data5.template) || '';
      tabs.pushObject(_object.default.create({
        // Add icons to the tabs
        // icon: 'fas fa-info',
        name: 'info',
        isActive: true,
        template
      }));
      const hasFaqs = R.pipe(R.pathOr([], ['_data', 'faqs']), R.isEmpty, R.not)(this);

      if (hasFaqs) {
        // tabs.pushObject(EmberObject.create({
        //   // Add icons to the tabs
        //   // icon: 'fas fa-info',
        //   name: 'info',
        //   isActive: true
        // }))
        tabs.pushObject(_object.default.create({
          // icon: 'fas fa-question',
          name: 'faqs',
          isActive: false,
          componentName: 'faqs'
        }));
      }

      return tabs;
    }),
    isPortraitImages: (0, _object.computed)('_data.productsImagesRatio', function () {
      var _this$_data6;

      const productsImagesRatio = ((_this$_data6 = this._data) === null || _this$_data6 === void 0 ? void 0 : _this$_data6.productsImagesRatio) || 'oneByOne';
      return this.imagesService.isPortrait(productsImagesRatio);
    }),
    isLandscapeImages: (0, _object.computed)('isPortraitImages', function () {
      return !this.isPortraitImages;
    }),
    hasReward: (0, _object.computed)('_data.rewardData.name', function () {
      var _this$_data7;

      const rewardData = (this === null || this === void 0 ? void 0 : (_this$_data7 = this._data) === null || _this$_data7 === void 0 ? void 0 : _this$_data7.rewardData) || {};
      const rewardName = (rewardData === null || rewardData === void 0 ? void 0 : rewardData.name) || '';

      if (_nventor.default.isNotNilOrEmpty(rewardData) || rewardName) {
        return true;
      }

      return false;
    }),
    hasReferralRewardEvent: (0, _object.computed)('_data.{rewardData.memberLevels.@each.memberLevelId,isAllowReferrals}', function () {
      const hasReward = this.hasReward || false;

      if (hasReward) {
        var _this$_data8, _this$_data9;

        const rewardData = (this === null || this === void 0 ? void 0 : (_this$_data8 = this._data) === null || _this$_data8 === void 0 ? void 0 : _this$_data8.rewardData) || {};
        const referralRewardEvent = R.pipe(R.propOr([], 'memberLevels'), R.pluck('rewardsEvents'), R.reject(R.isEmpty), R.flatten, R.find(R.propEq('type', 'referral')))(rewardData) || {};
        const isAllowReferrals = (this === null || this === void 0 ? void 0 : (_this$_data9 = this._data) === null || _this$_data9 === void 0 ? void 0 : _this$_data9.isAllowReferrals) || false;

        if (_nventor.default.isNotNilOrEmpty(referralRewardEvent) && isAllowReferrals) {
          return true;
        }
      }

      return false;
    }),
    hasFaqs: (0, _object.computed)('_data.faqs.[]', function () {
      return R.pipe(R.pathOr([], ['_data', 'faqs']), R.isEmpty, R.not)(this);
    }),
    hasExpiredGracefully: (0, _object.computed)('_data.isExpired', function () {
      var _this$_data10;

      return (_this$_data10 = this._data) === null || _this$_data10 === void 0 ? void 0 : _this$_data10.isExpired; // const dateEndZ = this._data.dateEndZ || false
      // if (dateEndZ) {
      //   const now = this.getMoment()
      //   const dateEnd = this.getMoment(dateEndZ).endOf('day')
      //   // const gracePeriodInHrs = this._data.gracePeriodInHrs || 24
      //   // const gracePeriodExpiry = dateEnd.add(gracePeriodInHrs, 'hours')
      //   // if (now.isAfter(gracePeriodExpiry)) {
      //   //   return true
      //   // }
      //   if (now.isAfter(dateEnd)) {
      //     return true
      //   }
      // }
      // return false
    }),
    showLabelMore: (0, _object.computed)('_data.showLabelMore', function () {
      return R.pathOr(true, ['_data', 'showLabelMore'])(this);
    }),
    activeTab: (0, _object.computed)('tabs.@each.isActive', function () {
      return R.find(R.prop('isActive'))(this.tabs);
    }),
    faqsTab: (0, _object.computed)('tabs.[]', function () {
      return R.find(R.propEq('name', 'faqs'))(this.tabs);
    }),
    productsImagesHoverFilter: (0, _object.computed)('_data.productsImagesHoverFilter', function () {
      var _this$_data11;

      return `hover-filter--${(this === null || this === void 0 ? void 0 : (_this$_data11 = this._data) === null || _this$_data11 === void 0 ? void 0 : _this$_data11.productsImagesHoverFilter) || 100}`;
    }),
    productsImagesHoverTransform: (0, _object.computed)('_data.productsImagesHoverTransform', function () {
      var _this$_data12;

      return `hover-transform--${(this === null || this === void 0 ? void 0 : (_this$_data12 = this._data) === null || _this$_data12 === void 0 ? void 0 : _this$_data12.productsImagesHoverTransform) || 'bigger'}`;
    }),

    setTabActive(tabs, currentTab) {
      R.forEach(tab => {
        tab.set('isActive', false);
      })(tabs);
      currentTab.set('isActive', true);
    },

    createAndPopulateShopItem(detail) {
      detail = this._populateIsSetWithChoicesItem(detail);
      detail = this._populateVariantItem(detail);
      detail = this._populateCustomizationsItem(detail);

      const itemModel = this._createItemModel(detail);

      return itemModel;
    },

    _populateIsSetWithChoicesItem(data) {
      if (data.isSetWithChoices) {
        const choices = data.choices || [];
        const updatedChoices = choices.map((choice, choiceIndex) => {
          const children = choice.details || [];
          choice.details = R.map(child => {
            child.qty = 0;

            if (child.hasVariants) {
              child = this._populateVariantItem(child);
            }

            child.choiceIndex = choiceIndex;

            const itemModel = this._createItemModel(child);

            return itemModel;
          }, children); // TODO: FIND A BETTER SOLUTION

          const choiceData = R.propOr(choice, '_data')(choice);

          const itemModel = this._createItemModel(choiceData);

          return itemModel;
        });
        data.choices.clear();
        data.choices.pushObjects(updatedChoices);
      }

      return data;
    },

    _populateVariantItem(data) {
      if (data.hasVariants) {
        const variantChildren = R.propOr([], 'variantChildren')(data);
        const activeVariantChildren = R.reject(R.propEq('calculatedStatusBeforeCart', 'inactive'))(variantChildren);
        const variants = data.variants || [];
        const updatedVariants = R.map(variant => {
          variant.options = R.pipe(R.propOr([], 'options'), R.filter(R.propEq('status', 'active')), R.filter(option => {
            const optionString = `${variant.name}:${option.name}`;
            const matchWithAnyActiveVariant = R.find(activeVariant => {
              const variantChildId = R.propOr('', 'variantChildId')(activeVariant);
              return R.includes(optionString, variantChildId);
            })(activeVariantChildren) || {};
            return !R.isEmpty(matchWithAnyActiveVariant);
          }))(variant);

          const itemModel = this._createItemModel(variant);

          return itemModel;
        })(variants);
        data.variants.clear();
        data.variants.pushObjects(updatedVariants);
        const updatedVariantChildren = R.pipe(R.propOr([], 'variantChildren'), R.map(child => _object.default.create(child)))(data);
        data.variantChildren.clear();
        data.variantChildren.pushObjects(updatedVariantChildren);
      }

      return data;
    },

    _populateCustomizationsItem(data) {
      if (data.hasCustomizations) {
        var _data$customizations, _data$customizations$, _data$customizations2, _data$customizations3;

        const customizations = data.customizations || [];
        const updatedCustomizations = R.pipe(R.map(customization => {
          customization.options = R.pipe(R.propOr([], 'options'), R.filter(R.propEq('status', 'active')), R.filter(option => {
            const priceFinal = (option === null || option === void 0 ? void 0 : option.priceFinal) || '';

            if (priceFinal) {
              return true;
            }

            return false;
          }))(customization);

          if (R.isEmpty(customization.options)) {
            return false;
          }

          const itemModel = this._createItemModel(customization);

          return itemModel;
        }), R.reject(R.equals(false)))(customizations);
        data === null || data === void 0 ? void 0 : (_data$customizations = data.customizations) === null || _data$customizations === void 0 ? void 0 : (_data$customizations$ = _data$customizations.clear) === null || _data$customizations$ === void 0 ? void 0 : _data$customizations$.call(_data$customizations);
        data === null || data === void 0 ? void 0 : (_data$customizations2 = data.customizations) === null || _data$customizations2 === void 0 ? void 0 : (_data$customizations3 = _data$customizations2.pushObjects) === null || _data$customizations3 === void 0 ? void 0 : _data$customizations3.call(_data$customizations2, updatedCustomizations);
      }

      return data;
    },

    persistToStorage(key, array) {
      const shopKey = this.shopKey;
      const value = R.map(item => {
        if (item.toJSON) {
          // @TODO: check if this is used?
          return item.toJSON();
        }

        const itemData = R.omit(['recurringSchedulesEnabled', 'seoDescription', 'seoKeywords', 'templates', 'theme', 'mainColors', 'faqs'])(item);
        return itemData;
      }, array);
      this.storage.setValue(`${key}${shopKey}`, value);
      return value;
    },

    getFromStorage(key) {
      const shopKey = this.shopKey;
      const value = this.storage.getValue(`${key}${shopKey}`) || [];
      return R.map(item => {
        if (item.selected) {
          item.selected = R.pipe(R.propOr([], 'selected'), R.map(detail => {
            return _object.default.create(detail);
          }))(item);
        }

        return _object.default.create(item);
      }, value); // return this.get('storage').getValue(`${key}${shopKey}`) || []
    },

    pickupLocations: (0, _object.computed)('_data.{allowShipping,pickupLocations.[]}', function () {
      let locations = this.get('_data.pickupLocations') || [];
      locations = R.map(location => {
        return _object.default.create({
          name: location,
          address: location
        });
      }, locations);

      if (this.get('_data.allowShipping')) {
        const noOption = _object.default.create({
          name: 'no',
          address: '',
          translate: true
        });

        return R.concat([noOption], locations);
      }

      return locations;
    }),
    paymentMethods: (0, _object.computed)('_data.paymentMethods.[]', 'updated', function () {
      const paymentMethods = this.get('_data.paymentMethods') || [];
      return R.map(paymentMethod => {
        return _object.default.create({
          label: paymentMethod,
          value: paymentMethod
        });
      })(paymentMethods);
    }),
    isAllowInstallments: (0, _object.computed)('_data.paymentMethodsAllowInstallments', 'hasRecurringItems', function () {
      var _this$_data13;

      if (this !== null && this !== void 0 && this.hasRecurringItems) {
        return false;
      }

      return (this === null || this === void 0 ? void 0 : (_this$_data13 = this._data) === null || _this$_data13 === void 0 ? void 0 : _this$_data13.paymentMethodsAllowInstallments) || false;
    }),
    creditCardInstallmentsDataByBank: (0, _object.computed)('_data.creditCardInstallmentsDataByBank', 'updated', 'isAllowInstallments', function () {
      const updated = this.updated;
      const isAllowInstallments = this.isAllowInstallments;

      if (!isAllowInstallments) {
        return [];
      }

      return R.pipe(R.pathOr([], ['_data', 'creditCardInstallmentsDataByBank']), R.map(installmentDataByBank => {
        const periods = installmentDataByBank.periods || [];
        installmentDataByBank.periodsModels = R.pipe(_nventor.default.sortAscending, R.map(period => {
          return _object.default.create({
            label: period,
            value: period
          });
        }))(periods);
        return installmentDataByBank;
      }))(this);
    }),
    showName: (0, _object.computed)('_data.productsOverviewShowName', function () {
      const showName = this.get('_data.productsOverviewShowName');

      if (R.isNil(showName)) {
        return true;
      }

      return showName;
    }),
    hasCampaignPassword: (0, _object.computed)('_data.hasCampaignPassword', function () {
      return this.get('_data.hasCampaignPassword') || false;
    }),
    isOverViewStyleCondensed: (0, _object.computed)('_data.productsOverviewStyle', function () {
      return true;
    }),
    campaignDates: (0, _object.computed)('_data.{dateStartZ,dateEndZ}', function () {
      const dateStartZ = this.get('_data.dateStartZ');
      const dateEndZ = this.get('_data.dateEndZ');
      let campaignDates = '';

      if (dateEndZ) {
        campaignDates = this.intl.t('limited_campaign_until', {
          dateEndZ: this.formatDate(dateEndZ)
        });
      }

      if (dateEndZ && dateStartZ) {
        campaignDates = this.formatDate(dateStartZ) + ` ~ ${campaignDates}`;
      }

      return campaignDates;
    }),
    hasBankingDetails: (0, _object.computed)('_data.{bankingDetails,paymentMethods}', function () {
      const paymentMethods = this.get('_data.paymentMethods') || [];
      const bankingDetails = this.get('_data.bankingDetails');

      if (paymentMethods.indexOf('cashBeforeDelivery') > -1 && bankingDetails) {
        return true;
      }

      return false;
    }),
    showCountdownTimer: (0, _object.computed)('_data.{showCountdownTimer,dateEndZ}', function () {
      const dateEndZ = this.get('_data.dateEndZ');

      if (this.get('_data.showCountdownTimer') === 'on' && dateEndZ) {
        return true;
      }

      return false;
    }),
    showCampaignHeader: (0, _object.computed)('_data.showCampaignHeader', function () {
      return true; // if (nventor.isNilOrEmpty('_data.showCampaignHeader') ){
      //   return true
      // }
      //
      // if (this._data.showCampaignHeader === 'on') {
      //   return true
      // }
      // return false
    }),
    isPayuni: (0, _object.computed)('_data.creditCardProvider', function () {
      if (this._data.creditCardProvider === 'payuni') {
        return true;
      }

      return false;
    }),
    isTapPay: (0, _object.computed)('_data.creditCardProvider', function () {
      if (this._data.creditCardProvider === 'tapPay') {
        return true;
      }

      return false;
    }),
    isGmo: (0, _object.computed)('_data.creditCardProvider', function () {
      return false;
    }),
    isEcPay: (0, _object.computed)('_data.creditCardProvider', function () {
      var _this$_data14;

      if ((this === null || this === void 0 ? void 0 : (_this$_data14 = this._data) === null || _this$_data14 === void 0 ? void 0 : _this$_data14.creditCardProvider) === 'ecPay') {
        return true;
      }

      return false;
    }),
    expectedDateMethodLabel: (0, _object.computed)('_data.expectedDateMethod', function () {
      const expectedDateMethod = R.pathOr('expectedArrivalDate', ['_data', 'expectedDateMethod'])(this);
      return expectedDateMethod;
    }),

    calculateCartLineAmt(item) {
      const roundingCurrency = 0;
      const qty = item.qty;
      let price = item.price || 0;

      if (item.onSale === 'on') {
        if (item.priceDiscounted) {
          price = item.priceDiscounted;
        }
      }

      if (item.isSetWithChoices) {
        price = item.inclAmt;
      }

      const qtyBig = this.newBig(qty);
      return qtyBig.times(price).toFixed(roundingCurrency);
    },

    getCartItemsData() {
      // @TODO: remove schedules?
      let cartItems = this.cartItems || [];
      cartItems = JSON.parse(JSON.stringify(cartItems));

      if (!R.is(Array, cartItems)) {
        // reset cart items
        this.saveCartItems([]);
        window.location.reload();
        return [];
      }

      const cartItemsData = R.pipe(R.map(cartItem => {
        const data = R.pick(['_key', '_rev', 'cartIndex', 'chosenQty', 'customId', 'inclAmt', 'isRecurring', 'isSet', 'isSetWithChoices', 'isVariantChild', 'isVariantChildOf', 'item', 'itemCode', 'itemKey', 'onSale', 'parent', 'price', 'priceDiscounted', 'priceFinal', 'qty', 'recurringScheduleSelected', 'selectedChoices', 'selectedCustomizations', 'selectedVariantOptions', 'status'])(cartItem);
        data.selectedChoices = R.pipe(R.propOr([], 'selectedChoices'), R.map(R.pick(['_key', '_rev', 'amountCurrent', 'amountOld', 'customId', 'isSet', 'isSetWithChoices', 'isVariantChild', 'isVariantChildOf', 'item', 'itemKey', 'onSale', 'overSalesLimitedQtyStatus', 'price', 'priceDiscounted', 'qty', 'setDetails', 'status' // 'parent'
        ])))(data);
        return data;
      }))(cartItems);
      return cartItemsData;
    },

    getCartAutomationsData() {
      // @TODO: remove schedules
      let automations = this.get('automations') || [];
      return R.pipe(R.map(automation => {
        const hasDataPath = R.hasPath(['_data'])(automation);

        if (automation instanceof _model2.default) {
          automation = automation.serialize();
        } else {
          if (hasDataPath) {
            automation = R.prop('_data')(automation);
            automation.selected = R.map(R.prop('_data'))(automation.selected);
            automation.details = R.map(R.prop('_data'))(automation.details);
          }
        }

        automation.selected = R.pipe(R.propOr([], 'selected'), R.map(R.omit(['description', 'template', 'uploadedFiles'])))(automation);

        if (_nventor.default.isNilOrEmpty(automation.selected)) {
          return false;
        }

        return R.omit(['details'])(automation);
      }), R.reject(R.equals(false)))(automations);
    },

    hasRecurringItems: (0, _object.computed)('cartItems.[]', function () {
      const cartItems = this.cartItems || [];
      const found = R.find(R.propEq('isRecurring', true))(cartItems); // requires a boolean to be returned

      if (found) {
        return true;
      }

      return false;
    }),

    addCartItem({
      item,
      qty,
      automation,
      isPreOrder = false,
      isRecurring = false,
      recurringSchedule,
      chosenQty
    }) {
      var _item$_data, _item$_data2;

      const cartItems = this.getFromStorage('cartItems');
      let itemData = item.serialize();

      if (automation) {
        itemData.isAutomation = true;
        itemData.isAutomationWhen = automation.get('_data.when');
        itemData.automationThen = automation.get('_data.thenAction');
        itemData.chosenQty = chosenQty;
      }

      itemData.qty = String(qty);
      itemData.isPreOrder = isPreOrder;
      itemData.isRecurring = isRecurring;
      itemData.recurringScheduleSelected = recurringSchedule;
      let found; // @TODO: find should be found by campaignsDetailsKey

      if (item !== null && item !== void 0 && (_item$_data = item._data) !== null && _item$_data !== void 0 && _item$_data.isSetWithChoices) {
        itemData = this._addIsSetWithChoicesItem(item, itemData);
      } else if (item !== null && item !== void 0 && (_item$_data2 = item._data) !== null && _item$_data2 !== void 0 && _item$_data2.hasVariants) {
        const selectedVariant = item.get('selectedVariant'); // found = R.find(R.propEq('itemKey', selectedVariant.itemKey))(cartItems)

        found = R.find(R.allPass([R.propEq('itemKey', selectedVariant.itemKey), R.pathEq(['recurringScheduleSelected', 'recurringScheduleKey'], recurringSchedule === null || recurringSchedule === void 0 ? void 0 : recurringSchedule.recurringScheduleKey), this.shopService.isCartItemCustomizationsSimilar(item) // R.propEq('_key', detailKey)
        ]))(cartItems);
        itemData = this._addVariantItem(item, itemData, found); // adding customizations data after getting the variantChildren product

        itemData = this._addCustomizations(item, itemData);
      } else {
        // adding customizations data for general product
        itemData = this._addCustomizations(item, itemData); // found = R.find(R.propEq('itemKey', itemData.itemKey))(cartItems)

        found = R.find(R.allPass([R.propEq('itemKey', itemData.itemKey), R.propEq('_key', itemData._key), R.pathEq(['recurringScheduleSelected', 'recurringScheduleKey'], recurringSchedule === null || recurringSchedule === void 0 ? void 0 : recurringSchedule.recurringScheduleKey), this.shopService.isCartItemCustomizationsSimilar(item)]))(cartItems);
      }

      if (!found) {
        found = _object.default.create(itemData);
        cartItems.pushObject(found);
      }

      if (_nventor.default.isNotNilOrEmpty(automation)) {
        const isMustAddBetweenWithOneDetail = (automation === null || automation === void 0 ? void 0 : automation.isMustAddBetweenWithOneDetail) || false;

        if (isMustAddBetweenWithOneDetail) {
          const foundChosenQty = found.chosenQty;

          if (foundChosenQty > chosenQty) {
            this.updateCartItemQty({
              group: 'cartItems',
              cartItem: found,
              qty,
              chosenQty
            });
          }
        }
      }

      this.saveCartItems(cartItems);
      return found;
    },

    /**
     * increase cart item (when add-btn is condensed, only for normal products)
     * @param {class} item - item class
     * @param {number} qty
     * @param {class} automation - optional
     */
    increaseCartItem({
      item,
      qty,
      automation,
      isRecurring = false,
      recurringSchedule
    }) {
      var _itemData, _itemData$recurringSc;

      const cartItems = this.getFromStorage('cartItems');
      let itemData = item.serialize();

      if (automation) {
        itemData.isAutomation = true;
        itemData.isAutomationWhen = automation.when;
      }

      itemData.qty = String(qty);
      itemData.isRecurring = isRecurring;
      itemData.recurringScheduleSelected = recurringSchedule;
      let found = R.find(R.allPass([R.propEq('itemKey', itemData.itemKey), R.propEq('_key', itemData._key), R.pathEq(['recurringScheduleSelected', 'recurringScheduleKey'], (_itemData = itemData) === null || _itemData === void 0 ? void 0 : (_itemData$recurringSc = _itemData.recurringScheduleSelected) === null || _itemData$recurringSc === void 0 ? void 0 : _itemData$recurringSc.recurringScheduleKey), this.shopService.isCartItemCustomizationsSimilar(item)]))(cartItems);

      if (item.selectedVariant) {
        var _item$selectedVariant, _item$selectedVariant2, _item$selectedVariant3;

        found = R.find(R.allPass([// R.propEq('_key', ''),
        R.propEq('itemKey', item === null || item === void 0 ? void 0 : (_item$selectedVariant = item.selectedVariant) === null || _item$selectedVariant === void 0 ? void 0 : _item$selectedVariant.itemKey), R.pathEq(['recurringScheduleSelected', 'recurringScheduleKey'], item === null || item === void 0 ? void 0 : (_item$selectedVariant2 = item.selectedVariant) === null || _item$selectedVariant2 === void 0 ? void 0 : (_item$selectedVariant3 = _item$selectedVariant2.recurringScheduleSelected) === null || _item$selectedVariant3 === void 0 ? void 0 : _item$selectedVariant3.recurringScheduleKey), //@TODO: check this
        this.shopService.isCartItemCustomizationsSimilar(item)]))(cartItems);

        if (!found) {
          itemData = this._addVariantItem(item, itemData, found);
        }
      }

      if (found) {
        let newQty = parseInt(found.get('qty')) || 1;
        newQty = newQty + parseInt(qty);
        found.set('qty', newQty);
      } else {
        // when recurring schedules creating new cartItem data when using add more btn, will not include customizations, need to add
        itemData = this._addCustomizations(item, itemData);
        found = _object.default.create(itemData);
        cartItems.pushObject(found);
      }

      this.saveCartItems(cartItems);
      return found;
    },

    saveCartItems(cartItems) {
      cartItems.forEach((cartItem, index) => {
        cartItem.set('cartIndex', index);
      });
      this.set('cartItems', this.persistToStorage('cartItems', cartItems));
    },

    _addIsSetWithChoicesItem(item, itemData) {
      if (item._data.isSetWithChoices) {
        const selectedChoices = R.pipe(R.pathOr([], ['_data', 'choices']), R.pluck('_data'), R.pluck('details'), R.flatten, R.filter(detail => R.gt(R.pathOr(0, ['_data', 'qty'], detail))(0)), R.map(detail => {
          if (detail.get('hasVariants')) {
            const selectedVariantChoices = detail.selectedVariantChoices || [];
            const variantParent = R.merge({}, detail._data);
            return R.pipe(R.reject(choice => {
              const qty = parseInt(choice.qty) || 0;
              return qty <= 0;
            }), R.map(choice => {
              const selectedVariantOptions = R.values(choice.selectedOptions);

              const variantChildData = this.__addVariantItem(variantParent, choice.selectedVariantChild, selectedVariantOptions);

              variantChildData.qty = choice.qty;
              return variantChildData;
            }))(selectedVariantChoices);
          } else {
            detail.set('_data.amountCurrent', detail.amountCurrent);
            detail.set('_data.amountOld', detail.amountOld);
            const detailData = R.propOr({}, '_data')(detail);
            return R.merge({}, detailData);
          }
        }), R.flatten)(item);
        itemData.selectedChoices = selectedChoices;
      }

      return itemData;
    },

    _addCustomizations(item, itemData) {
      var _item$_data3;

      if (item !== null && item !== void 0 && (_item$_data3 = item._data) !== null && _item$_data3 !== void 0 && _item$_data3.hasCustomizations) {
        const selectedCustomizations = item.get('selectedCustomizations');
        itemData.selectedCustomizations = R.clone(selectedCustomizations);
      }

      return itemData;
    },

    _addVariantItem(item, itemData, found) {
      if (item._data.hasVariants) {
        if (found) {
          const qty = parseInt(itemData.qty) || 1;
          const existingQty = parseInt(found.get('qty')) || 1;
          const newQty = qty + existingQty;
          found.set('qty', newQty);
        } else {
          const selectedVariant = item.get('selectedVariant');
          const selectedVariantOptions = item.get('selectedVariantOptions');
          itemData = this.__addVariantItem(itemData, selectedVariant, selectedVariantOptions);
        }
      }

      return itemData;
    },

    __addVariantItem(variantParentData, selectedVariant, selectedVariantOptions) {
      const price = selectedVariant.price;
      const priceDiscounted = selectedVariant.priceDiscounted;

      if (!price && !priceDiscounted) {
        selectedVariant.price = variantParentData.price;
        selectedVariant.priceDiscounted = variantParentData.priceDiscounted;
      }

      selectedVariant.parent = R.pick(['item', 'itemKey', 'uploadedFiles', 'defaultImage', 'personLimitedQty'], JSON.parse(JSON.stringify(variantParentData)));
      selectedVariant.isVariantChildOf = variantParentData.itemKey;
      selectedVariant.isVariantChild = true;
      selectedVariant.qty = variantParentData.qty;
      selectedVariant.selectedVariantOptions = selectedVariantOptions;
      return R.merge({}, selectedVariant);
    },

    updateCartItemQty({
      group,
      cartItem,
      qty,
      chosenQty
    }) {
      const groupData = this.get(group);

      if (qty != null) {
        if (cartItem.set) {
          cartItem.set('qty', qty);

          if (cartItem.isAutomation && chosenQty) {
            cartItem.set('chosenQty', chosenQty);
          }
        }
      }

      this.set('groupData', this.persistToStorage(group, groupData));
    },

    removeCartItem(group, cartItem) {
      if (_nventor.default.isNilOrEmpty(cartItem)) {
        return;
      }

      const groupData = this.get(group);
      const toRemove = R.find(R.propEq('cartIndex', cartItem.cartIndex))(groupData);
      groupData.removeObject(toRemove);
      this.set(group, this.persistToStorage(group, groupData));
      this.googleService.removeFromCart(cartItem);
    },

    addAutomationItem(automation) {
      const groupData = this.get('automations') || [];
      const toCreate = R.find(R.pathEq(['_data', '_key'], automation.get('_data._key')))(groupData);

      if (!toCreate) {
        groupData.pushObject(automation);
        this.set('automations', this._storeAutomations(groupData));
      }
    },

    removeAutomationItem(automation) {
      const groupData = this.get('automations') || [];
      const toRemove = R.find(R.pathEq(['_data', '_key'], automation.get('_data._key')))(groupData);
      groupData.removeObject(toRemove);
      this.set('automations', this._storeAutomations(groupData));
    },

    hasCartItems: (0, _object.computed)('cartItems.{[],@each._key}', function () {
      const cartItems = this.cartItems || [];

      if (cartItems.length > 0) {
        return true;
      }

      return false;
    }),
    hasNotAllowedItems: (0, _object.computed)('cartItems.@each.isNotAllowed', function () {
      const cartItems = this.cartItems || [];

      if (_nventor.default.isNotNilOrEmpty(cartItems)) {
        return R.pipe(R.pluck('isNotAllowed'), R.reject(R.isNil), R.includes(true))(cartItems);
      }

      return false;
    }),

    setParams(params = {}) {
      const newParams = R.mergeRight(defaultParams)(params);
      this.set('resetParams', Math.random());
      this.set('params', newParams);
      return newParams;
    },

    clearCart() {
      const msg = this.intl.t('are you sure you want to clear cart?');

      if (window.confirm(msg)) {
        this.set('cartItems', this.persistToStorage('cartItems', []));
        this.set('automations', this._storeAutomations());
      }
    },

    cartSuccessClear() {
      this.set('cartItems', this.persistToStorage('cartItems', []));
      this.set('automations', this._storeAutomations());
      this.checkoutService.clearCheckoutId();
    },

    /**
     * @deprecated: can only deprecate once normal shipping fee is refactored
     */
    subTotalAmt: (0, _object.computed)('cartItems.@each._key', 'automations.[]', 'updated', function () {
      const roundingCurrency = 0;
      const cartItems = this.getFromStorage('cartItems');
      let totalAmt = this.newBig(0);
      cartItems.forEach(cartItem => {
        totalAmt = totalAmt.plus(this.calculateCartLineAmt(cartItem));
      });
      const automations = this.automations;
      const automationsTotalAmt = R.pipe(_automations.default.onlyForMayAdd, _automations.default.getSelected, R.reduce((acc, item) => acc.plus(this.calculateCartLineAmt(item)), this.newBig(0)))(automations);
      totalAmt = totalAmt.plus(automationsTotalAmt);
      return totalAmt.toFixed(roundingCurrency);
    }),

    /**
     * @deprecated: can only deprecate once normal shipping fee is refactored
     */
    totalAmt: (0, _object.computed)('cartItems.@each._key', 'subTotalAmt', 'websiteService.hasShippingFee', 'discountAmount', function () {
      const roundingCurrency = 0;
      let totalAmt = this.newBig(this.subTotalAmt);
      const hasShippingFee = this.get('websiteService.hasShippingFee');

      if (hasShippingFee) {
        totalAmt = totalAmt.plus(hasShippingFee);
      }

      const discountAmount = this.discountAmount;

      if (discountAmount) {
        totalAmt = totalAmt.minus(discountAmount);
      }

      return totalAmt.toFixed(roundingCurrency);
    }),

    /**
     * @deprecated: can only deprecate once normal shipping fee is refactored
     */
    totalAmtIncFees: (0, _object.computed)('cartItems.@each._key', 'totalAmt', '_data.cashPaymentMethodFee', 'checkoutData.paymentMethod', function () {
      const roundingCurrency = 0;
      const paymentFee = this.get('_data.cashPaymentMethodFee') || 0;

      if (paymentFee > 0) {
        let totalAmt = this.newBig(this.totalAmt);
        totalAmt = totalAmt.plus(paymentFee);
        return totalAmt.toFixed(roundingCurrency);
      }

      return this.totalAmt;
    }),
    automationsForMustAdd: (0, _object.computed)('automations.[]', 'updated', function () {
      const automations = this.automations;
      return R.pipe(_automations.default.onlyForMustAdd)(automations);
    }),
    automationsForIsMustAddAndChooseBetween: (0, _object.computed)('automations.[]', 'updated', function () {
      const automations = this.automations;
      return _automations.default.onlyForMustAddAndChooseBetween(automations);
    }),
    automationsAddedForIsMayAdd: (0, _object.computed)('automations.[]', 'updated', function () {
      const automations = this.automations;
      return R.pipe(_automations.default.onlyForMayAdd, _automations.default.onlyHasSelected)(automations);
    }),
    automationsForMayAdd: (0, _object.computed)('automations.[]', 'updated', function () {
      const automations = this.automations;
      return _automations.default.onlyForMayAdd(automations);
    }),
    hasIncompleteAutomationsMustAddAndChooseBetween: (0, _object.computed)('automationsForIsMustAddAndChooseBetween.{[],@each.isComplete}', function () {
      const automations = this.automationsForIsMustAddAndChooseBetween || [];

      if (_nventor.default.isNilOrEmpty(automations)) {
        return false;
      }

      return R.pipe(R.map(automation => {
        return (automation === null || automation === void 0 ? void 0 : automation.isComplete) || false;
      }), R.includes(false))(automations);
    }),
    bulkDiscounts: (0, _object.computed)('_data.bulkDiscounts', function () {
      const bulkDiscounts = this.get('_data.bulkDiscounts') || [];
      return bulkDiscounts;
    }),

    getMustAddAutomationsForItemByProduct(item) {
      const automations = this.automations;
      return _automations.default.getMustAddAutomationsForItemByProduct(automations, item);
    },

    chooseMustAddItem(automation, item) {
      var _item$_data4, _item$_data5;

      const automations = this.automations;
      const automationIndex = automations.indexOf(automation);
      const cartItems = (this === null || this === void 0 ? void 0 : this.cartItems) || [];
      const alreadyAdded = R.find(R.allPass([R.propEq('itemKey', item === null || item === void 0 ? void 0 : (_item$_data4 = item._data) === null || _item$_data4 === void 0 ? void 0 : _item$_data4.itemKey), R.propEq('_key', item === null || item === void 0 ? void 0 : (_item$_data5 = item._data) === null || _item$_data5 === void 0 ? void 0 : _item$_data5._key)]))(cartItems);

      if (alreadyAdded) {
        return false;
      } // previous choice, only allow one choice at a time


      const automationDetails = automation.get('_data.details') || [];
      const automationDetailsKeys = R.map(R.path(['_data', '_key']))(automationDetails);
      const cartItemsToRemove = R.filter(cartItem => {
        return R.includes(cartItem._key)(automationDetailsKeys);
      })(cartItems);
      R.forEach(cartItemToRemove => {
        this.removeCartItem('cartItems', cartItemToRemove);
      })(cartItemsToRemove); // remove from automation.selected

      automation._data.selected.removeObject(automation.selected.get('firstObject'));

      automation._data.selected.pushObject(item._data);

      const chosenQty = item.get('_data.qty') || 0;
      const automationQty = item.get('_data.automationQty') || 0;
      automations[automationIndex] = automation;
      this.set('automations', this._storeAutomations(automations));
      this.addCartItem({
        item,
        qty: chosenQty * automationQty,
        automation,
        isPreOrder: false,
        chosenQty
      });
    },

    addMayAddItem(automation, item, qty) {
      let automations = this.automations;
      const automationIndex = automations.indexOf(automation);
      item.set('_data.qty', qty);
      const itemData = item._data;
      itemData.isAutomation = true;

      if (!_automations.default.isAlreadyAdded(itemData, automation)) {
        automation._data.selected.pushObject(_object.default.create(itemData));
      }

      automations[automationIndex] = automation;
      automations = this._storeAutomations(automations);
      this.set('automations', []);
      this.set('automations', automations);
      return automation;
    },

    increaseMayAddItem(automation, item, qty) {
      let automations = this.automations;
      const automationIndex = automations.indexOf(automation);
      const itemData = item._data;
      itemData.isAutomation = true;

      const found = _automations.default.findAdded(itemData, automation);

      const currentQty = found.qty;
      const newQty = currentQty + qty;
      const personLimitedQty = parseInt(itemData.personLimitedQty) || 0;

      if (newQty <= personLimitedQty || personLimitedQty === 0) {
        item.set('_data.qty', newQty); // const newItemData = item._data

        found.set('qty', newQty); // automation.selected.removeObject(found)
        // automation.selected.pushObject(newItemData)

        automations[automationIndex] = automation;
        automations = this._storeAutomations(automations);
        this.set('automations', []);
        this.set('automations', automations);
        return itemData;
      }

      return {
        qty: ''
      };
    },

    isSupportedRegion: (0, _object.computed)('shippingFeeData.zoneNotFound', '_data.shippingFeeType', function () {
      const shippingFeeType = this.get('_data.shippingFeeType');

      if (shippingFeeType === 'zones') {
        if (this.get('shippingFeeData.zoneNotFound')) {
          return false;
        }
      }

      return true;
    }),
    isMinShippingRateReached: (0, _object.computed)('shippingFeeData.zoneMinRateNotReached', '_data.shippingFeeType', function () {
      const shippingFeeType = this.get('_data.shippingFeeType');

      if (shippingFeeType === 'zones') {
        if (this.get('shippingFeeData.zoneMinRateNotReached')) {
          return false;
        }
      }

      return true;
    }),
    minShippingRate: (0, _object.computed)('shippingFeeData.{zoneMinRateNotReached,zoneMinRate}', '_data.shippingFeeType', function () {
      const shippingFeeType = this.get('_data.shippingFeeType');

      if (shippingFeeType === 'zones') {
        if (this.get('shippingFeeData.zoneMinRateNotReached')) {
          return this.get('shippingFeeData.zoneMinRate');
        }
      }

      return 0;
    }),

    removeMayAddItem(automation, itemData) {
      const automations = this.automations;
      const automationIndex = automations.indexOf(automation);

      automation._data.selected.removeObject(itemData);

      automations[automationIndex] = automation;
      this.set('automations', []);
      this.set('automations', this._storeAutomations(automations));
    },

    validateCartItems(validatedData) {
      const validatedCartItems = R.path(['cart'])(validatedData) || [];
      const shopCartItems = R.pipe(R.propOr([], 'cartItems'), R.map(shopCartItem => {
        const validatedCartItem = R.find(R.propEq('cartIndex', shopCartItem.cartIndex))(validatedCartItems);

        if (!validatedCartItem) {
          return false;
        }

        const keys = R.keys(validatedCartItem);
        R.forEach(key => {
          const newValue = validatedCartItem[key];

          if (key === 'selectedChoices') {
            const validatedSelectedChoices = R.pipe(R.propOr([], 'selectedChoices'), _nventor.default.mapIndexed((cartSelectedChoice, index) => {
              const validatedSelectedChoices = newValue || [];
              const validatedSelectedChoice = validatedSelectedChoices[index] || {};
              return _nventor.default.mergeRightWhenExists(cartSelectedChoice, validatedSelectedChoice);
            }))(shopCartItem);
            shopCartItem.set('selectedChoices', validatedSelectedChoices);
          } else {
            shopCartItem.set(key, newValue);
          }
        })(keys);
        return shopCartItem;
      }), R.reject(R.equals(false)))(this);
      this.saveCartItems(shopCartItems);
      const inclTotal = R.prop('inclTotal')(validatedData);
      this.set('inclTotal', inclTotal);
      const inclTotalExclPaymentFees = R.prop('inclTotalExclPaymentFees')(validatedData);
      this.set('inclTotalExclPaymentFees', inclTotalExclPaymentFees);
      const inclTotalExclPaymentFeesAndShippingFees = R.prop('inclTotalExclPaymentFeesAndShippingFees')(validatedData);
      this.set('inclTotalExclPaymentFeesAndShippingFees', inclTotalExclPaymentFeesAndShippingFees);
      this.set('updated', Math.random());
      return shopCartItems;
    },

    validateAutomationItems(validatedData) {
      try {
        const shopAutomations = this.automations || [];
        const validatedAutomations = R.path(['automations'])(validatedData) || [];
        const automations = R.map(automation => {
          const previouslyLoadedAutomationModel = R.find(R.pathEq(['_data', '_key'], automation._key))(shopAutomations);

          const automationModel = _model2.default.create();

          automationModel.populate(automation);

          if (!previouslyLoadedAutomationModel) {
            return automationModel;
          }

          const selected = R.pipe(R.pathOr([], ['_data', 'selected']), R.map(detail => {
            const automationItemKey = detail.get('_data.itemKey');
            const previouslySelectedAutomation = R.pipe(R.propOr([], 'selected'), R.find(R.propEq('itemKey', automationItemKey)))(previouslyLoadedAutomationModel);

            if (_nventor.default.isNilOrEmpty(previouslySelectedAutomation)) {
              return detail;
            }

            if (_nventor.default.isNotNilOrEmpty(previouslySelectedAutomation)) {
              const keys = R.keys(detail);
              R.forEach(key => {
                previouslySelectedAutomation.set(key, detail[key]);
              })(keys);
              return previouslySelectedAutomation;
            }

            return detail;
          }))(previouslyLoadedAutomationModel) || [];
          automationModel.set('_data.selected', selected);
          return automationModel;
        })(validatedAutomations);
        this.set('automations', automations);
        this.set('updated', Math.random());
      } catch (e) {
        console.error(e); // Empty block statement
      }
    },

    addMustAddBetweenAutomationItemsToCart() {
      const mustAddBetweenAutomations = this.automationsForIsMustAddAndChooseBetween || [];
      const cartItems = this.cartItems || [];
      R.forEach(automation => {
        const details = automation.get('_data.details') || [];

        if (R.length(details) === 1) {
          let selected = automation.get('_data.selected') || [];

          if (_nventor.default.isNilOrEmpty(selected)) {
            selected = details;
          }

          const thenSelectionQty = automation.get('_data.thenSelectionQty') || 0;

          if (_nventor.default.isNotNilOrEmpty(selected)) {
            R.forEach(item => {
              var _item$_data6;

              this.addCartItem({
                item,
                qty: (_item$_data6 = item._data) === null || _item$_data6 === void 0 ? void 0 : _item$_data6.automationQty,
                automation,
                isPreOrder: false,
                chosenQty: thenSelectionQty
              });
            })(selected);
          }
        }

        R.forEach(item => {
          const key = item.get('_data._key');
          const itemKey = item.get('_data.itemKey');
          const cartItem = R.find(R.allPass([R.propEq('_key', key), R.propEq('itemKey', itemKey)]))(cartItems);
          let chosenQty = this.newBig(0);

          if (cartItem) {
            chosenQty = this.newBig(cartItem.chosenQty);
          }

          if (R.length(details) === 1) {
            var _automation$_data;

            chosenQty = this.newBig((automation === null || automation === void 0 ? void 0 : (_automation$_data = automation._data) === null || _automation$_data === void 0 ? void 0 : _automation$_data.thenSelectionQty) || 0);
          }

          item.set('_data.qty', chosenQty.toFixed(0));
        })(details);
        automation.set('updated', Math.random());
      })(mustAddBetweenAutomations);
    },

    addMustAddAutomationItemsToCart() {
      const mustAddAutomations = this.automationsForMustAdd || [];
      const mustAddAllAutomations = R.filter(R.pathEq(['_data', 'thenSelectionType'], 'all'))(mustAddAutomations);
      R.forEach(automation => {
        var _automation$_data2;

        const selected = automation.get('_data.selected') || [];
        const thenSelectionQty = (automation === null || automation === void 0 ? void 0 : (_automation$_data2 = automation._data) === null || _automation$_data2 === void 0 ? void 0 : _automation$_data2.thenSelectionQty) || 0;

        if (_nventor.default.isNotNilOrEmpty(selected)) {
          R.forEach(item => {
            var _item$_data7;

            this.addCartItem({
              item,
              qty: (_item$_data7 = item._data) === null || _item$_data7 === void 0 ? void 0 : _item$_data7.automationQty,
              automation,
              isPreOrder: false,
              chosenQty: thenSelectionQty
            });
          })(selected);
          const details = automation.get('_data.details') || [];
          R.forEach(detail => {
            detail.set('_data.qty', thenSelectionQty);
          })(details);
        }
      })(mustAddAllAutomations);
    },

    _setValidated(shopCartItem, validatedCartItem, prefix) {
      shopCartItem.set(`${prefix}status`, validatedCartItem.status);
      shopCartItem.set(`${prefix}price`, validatedCartItem.price);
      shopCartItem.set(`${prefix}priceDiscounted`, validatedCartItem.priceDiscounted);
      shopCartItem.set(`${prefix}priceFinal`, validatedCartItem.priceFinal);
      shopCartItem.set(`${prefix}isSoldOut`, validatedCartItem.isSoldOut);
      shopCartItem.set(`${prefix}overSalesLimitedQtyStatus`, validatedCartItem.overSalesLimitedQtyStatus);
      return shopCartItem;
    },

    _validateVariantItem(shopCartItem, validatedCartItems = []) {
      const validatedCartItem = R.find(R.propEq('itemKey', shopCartItem.selectedVariant.itemKey))(validatedCartItems);

      if (validatedCartItem) {
        return this._setValidated(shopCartItem, validatedCartItem, 'selectedVariant.');
      }

      return false;
    },

    _validateSetWithChoices(shopCartItem, validatedCartItems) {
      const validatedCartItem = R.find(R.propEq('itemKey', shopCartItem.itemKey))(validatedCartItems);

      if (validatedCartItem) {
        let setDetails = shopCartItem.get('setDetails'); // const choices = validatedCartItem.choices
        // let parentStatus = 'active'

        setDetails = _nventor.default.mapIndexed((selectedChoiceItem, index) => {
          selectedChoiceItem = _object.default.create(JSON.parse(JSON.stringify(selectedChoiceItem))); // const choiceIndex = selectedChoiceItem.choiceIndex

          const validatedSetDetail = _nventor.default.safeViewLensPathOr(false, ['setDetails', index])(validatedCartItem);

          if (selectedChoiceItem.hasVariants) {
            const selectedVariantChoices = R.pipe(R.propOr([], 'selectedVariantChoices'), R.map(variant => {
              variant = _object.default.create(JSON.parse(JSON.stringify(variant)));
              const variantChildItemKey = R.path(['selectedVariantChild', 'itemKey'])(variant);
              const validatedVariant = R.find(R.propEq('itemKey', variantChildItemKey))(validatedSetDetail.selectedVariantChoices);
              return this._validateVariantItem(variant, validatedVariant.selectedVariantChoices);
            }))(selectedChoiceItem);
            selectedChoiceItem.set('selectedVariantChoices', selectedVariantChoices);
            return selectedChoiceItem;
          } else {
            return this._setValidated(selectedChoiceItem, validatedCartItem);
          }
        })(setDetails);

        if (R.includes(false, setDetails)) {
          return false;
        }

        shopCartItem.set('setDetails', setDetails);
        return shopCartItem;
      }

      return false;
    },

    resetRecheckDates() {
      this.set('recheckDates', Math.random());
    },

    maintenanceWindowsForCreditCard: (0, _object.computed)('_data.maintenances.[]', 'recheckDates', function () {
      const recheckDates = this.recheckDates; //this is just to ensure computed property is rerun when recheckDates is reset

      const now = this.dateService.getMoment();
      return R.pipe(R.pathOr([], ['_data', 'maintenances']), R.filter(maintenance => {
        const service = maintenance.service || '';
        const isCreditCard = R.startsWith('creditCard_')(service);

        if (!isCreditCard) {
          return false;
        }

        const startDateTimeZ = maintenance.startDateTimeZ;
        const endDateTimeZ = maintenance.endDateTimeZ;

        if (now.isAfter(startDateTimeZ) && now.isBefore(endDateTimeZ)) {
          return true;
        }

        return false;
      }))(this);
    }),
    // isCreditCardUnderMaintenance: computed('maintenanceWindowsForCreditCard.[]', function () {
    //   return nventor.isNotNilOrEmpty(this.maintenanceWindowsForCreditCard)
    // }),
    topBanner: (0, _object.computed)('_data.topBanner', function () {
      return R.path(['_data', 'topBanner'])(this) || {};
    }),
    bottomBanner: (0, _object.computed)('_data.bottomBanner', function () {
      return R.path(['_data', 'bottomBanner'])(this) || {};
    }),
    topBannerImageBackgroundColor: (0, _object.computed)('_data.topBanner.mainColors.[]', function () {
      var _this$imagesService, _this$imagesService$g;

      return this === null || this === void 0 ? void 0 : (_this$imagesService = this.imagesService) === null || _this$imagesService === void 0 ? void 0 : (_this$imagesService$g = _this$imagesService.getBackgroundRGBColor) === null || _this$imagesService$g === void 0 ? void 0 : _this$imagesService$g.call(_this$imagesService, {
        pathArray: ['_data', 'topBanner', 'mainColors'],
        model: this
      });
    }),
    bottomBannerImageBackgroundColor: (0, _object.computed)('_data.bottomBanner.mainColors.[]', function () {
      var _this$imagesService2, _this$imagesService2$;

      return this === null || this === void 0 ? void 0 : (_this$imagesService2 = this.imagesService) === null || _this$imagesService2 === void 0 ? void 0 : (_this$imagesService2$ = _this$imagesService2.getBackgroundRGBColor) === null || _this$imagesService2$ === void 0 ? void 0 : _this$imagesService2$.call(_this$imagesService2, {
        pathArray: ['_data', 'bottomBanner', 'mainColors'],
        model: this
      });
    }),
    showItemDescriptionLinesCount: (0, _object.computed)('_data.showItemDescriptionLinesCount', function () {
      return this._data.showItemDescriptionLinesCount || 2;
    }),
    hasAutomationsError: (0, _object.computed)('automations.@each.hasError', function () {
      const automations = (this === null || this === void 0 ? void 0 : this.automations) || [];
      return R.pipe(R.map(automation => {
        return (automation === null || automation === void 0 ? void 0 : automation.hasError) || false;
      }), R.includes(true))(automations);
    }),

    _transformPickupMethods(data) {
      const allowShipping = R.prop('allowShipping')(data);
      return R.over(R.lensProp('pickupMethods'), pickupMethods => {
        if (allowShipping) {
          return pickupMethods;
        }

        return R.reject(R.propEq('value', 'homeDelivery'))(pickupMethods);
      })(data);
    },

    hasCategoriesFilters: (0, _object.computed)('_data.{filterTags,groupByTags}', function () {
      var _this$_data15, _this$_data16;

      if (!((_this$_data15 = this._data) !== null && _this$_data15 !== void 0 && _this$_data15.groupByTags)) {
        return false;
      }

      if (((_this$_data16 = this._data) === null || _this$_data16 === void 0 ? void 0 : _this$_data16.filterTags) === 'onlyCategories') {
        return true;
      }

      if ((this === null || this === void 0 ? void 0 : this._data.filterTags) === 'all') {
        return true;
      }

      return false;
    }),
    hasTagsFilters: (0, _object.computed)('_data.{filterTags,groupByTags}', function () {
      var _this$_data17, _this$_data18;

      if (!((_this$_data17 = this._data) !== null && _this$_data17 !== void 0 && _this$_data17.groupByTags)) {
        return false;
      }

      if (((_this$_data18 = this._data) === null || _this$_data18 === void 0 ? void 0 : _this$_data18.filterTags) === 'onlyTags') {
        return true;
      }

      if ((this === null || this === void 0 ? void 0 : this._data.filterTags) === 'all') {
        return true;
      }

      return false;
    }),
    totalQtyWithoutAutomations: (0, _object.computed)('cartItems.@each.qty', function () {
      const cartItems = this.cartItems || [];
      return R.pipe(R.reject(R.propEq('isAutomation', true)), R.pluck('qty'), R.reject(_nventor.default.isNilOrEmpty), R.sum)(cartItems);
    })
  }, (_applyDecoratedDescriptor(_obj, "setTabActive", [_object.action], Object.getOwnPropertyDescriptor(_obj, "setTabActive"), _obj)), _obj));

  _exports.default = _default;
});