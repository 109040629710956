define("shop/translations/zh-tw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "1000_points": "+1000點",
    "100_points": "+100點",
    "500_points": "+500點",
    "50_points": "+50點",
    "ATM_BOT": "台灣銀行 ATM",
    "ATM_CATHAY": "國泰世華銀行 ATM (暫不提供)",
    "ATM_CHINATRUST": "中國信託 ATM",
    "ATM_ESUN": "玉山銀行 ATM (暫不提供)",
    "ATM_FIRST": "第一銀行 ATM",
    "ATM_FUBON": "台北富邦 ATM (暫不提供)",
    "ATM_LAND": "土地銀行 ATM",
    "ATM_TACHONG": "大眾銀行 ATM (2018 年已併到元大銀行)",
    "ATM_TAISHIN": "台新銀行 ATM",
    "Family Mart": "全家",
    "MM/YY": "MM/YY",
    "OTHER": "其他",
    "SHIPPING_FEE": "運費",
    "Seven Eleven": "7-11",
    "Taiwan": "台灣",
    "TapPay is out of service.": "信用卡銀行發生錯誤錯誤，請稍後再試",
    "Validation Error": "輸入錯誤",
    "account": "帳戶",
    "accumulated member points": "累積會員點數",
    "add": "加入購物車",
    "add another": "再選取內容",
    "add another set": "再選取內容",
    "add more": "再加入",
    "add to cart": "加入購物車",
    "add to cart again": "再加入購物車",
    "added": "已加入",
    "address": "地址",
    "after noon": "14-18時",
    "afterNoon": "14-18時",
    "agree and checkout": "同意並結帳",
    "all": "全部",
    "all products": "所有商品",
    "already a member, please login": "已經是會員，請登入",
    "always use tax number on checkout": "結帳時套用統編",
    "amex": "AMEX",
    "an email will be sent to you shortly if an account exists for this email address": "稍後會寄一封重設密碼信到此信箱",
    "an error has occurred on your previous transaction, please contact us. or retry with the checkout, but may lead to duplicate orders": "您目前交易可能發生錯誤，如果結帳成功，將收到訂單明細email通知，如果沒有收到通知，請再重試結帳，但有可能因此產生重複訂單，如果有此情形我們的客服將會協助您排除問題。",
    "an error occurred with your credit card processing. please try entering your credit card again manually without autocomplete": "信用卡資料爲自動帶入時，可能會遇到系統無法辨識，請您嘗試改爲手動輸入",
    "anytime": "任何時段",
    "applied bulk discounts": "已達折扣門檻",
    "apply all bulk discount when buy items": "當全店商品數量{criteria}{criteriaValue}件折扣{discount}",
    "apply all bulk discount when items value": "當全店商品金額{criteria}{criteriaValue}件折扣{discount}",
    "apply promo code": "套用優惠代碼",
    "are you sure you want to clear cart?": "您確定要清空購物車嗎?",
    "are you sure you want to delete stored credit card": "是否要忘記已儲存的信用卡資料？",
    "are you sure you want to remove from cart?": "您確定要從購物車中刪除?",
    "area": "行政區",
    "atm_attentionRequired": "人工處理中",
    "atm_overpaid": "ATM 超額付款",
    "atm_underpaid": "ATM 付款不足額",
    "automation_error_message": "Something wrong with the selected items",
    "available promo codes": "搜尋現有優惠代碼",
    "available qty": "庫存量",
    "awaitingCreditCardPayment": "等待付款",
    "awaitingPayment": "等待付款/對帳中",
    "back": "回到上一頁",
    "back to checkout": "回到結帳頁面",
    "back to store": "繼續選購",
    "bank processing error": "信用卡銀行發生錯誤，請稍後再試",
    "bankCode_004": "臺灣銀行(004)",
    "bankCode_005": "土地銀行(005)",
    "bankCode_006": "合作金庫銀行(006)",
    "bankCode_007": "第一銀行(007)",
    "bankCode_008": "華南銀行(008)",
    "bankCode_009": "彰化銀行(009)",
    "bankCode_011": "上海商業儲蓄銀行(011)",
    "bankCode_012": "台北富邦銀行(012)",
    "bankCode_013": "國泰世華銀行(013)",
    "bankCode_016": "高雄銀行(016)",
    "bankCode_017": "兆豐國際商業銀行(017)",
    "bankCode_018": "全國農業金庫(018)",
    "bankCode_020": "瑞穗銀行(020)",
    "bankCode_021": "銀行(021)",
    "bankCode_022": "美國銀行(022)",
    "bankCode_025": "首都銀行(025)",
    "bankCode_039": "銀行(039)",
    "bankCode_050": "臺灣企銀(050)",
    "bankCode_052": "渣打商業銀行(052)",
    "bankCode_053": "台中商業銀行(053)",
    "bankCode_054": "京城商銀(054)",
    "bankCode_073": "德意志銀行(073)",
    "bankCode_075": "東亞銀行(075)",
    "bankCode_081": "銀行(081)",
    "bankCode_082": "法國巴黎銀行(082)",
    "bankCode_086": "法國東方匯理銀行(086)",
    "bankCode_101": "瑞興銀行(101)",
    "bankCode_102": "華泰銀行(102)",
    "bankCode_103": "臺灣新光商業銀行(103)",
    "bankCode_108": "陽信銀行(108)",
    "bankCode_118": "板信商業銀行(118)",
    "bankCode_147": "三信商業銀行(147)",
    "bankCode_700": "中華郵政(700)",
    "bankCode_803": "聯邦銀行(803)",
    "bankCode_805": "遠東國際商業銀行(805)",
    "bankCode_806": "元大銀行(806)",
    "bankCode_807": "永豐銀行(807)",
    "bankCode_808": "玉山銀行(808)",
    "bankCode_809": "凱基銀行(809)",
    "bankCode_810": "銀行(810)",
    "bankCode_812": "台新銀行(812)",
    "bankCode_815": "日盛國際商業銀行(815)",
    "bankCode_816": "安泰商業銀行(816)",
    "bankCode_822": "中國信託(822)",
    "before noon": "13點前",
    "beforeNoon": "13點前",
    "billing telephone": "付款人電話",
    "birthday": "生日",
    "birthday can only be set once": "生日只能設置一次！",
    "blog": "文章",
    "blogs": "文章",
    "bulk discount": "折扣",
    "bulk discount applied": "已達折扣門檻",
    "bulk discount applied to": "{bulkDiscountName}已套用至{itemsName}",
    "bulk discount item qty description": "數量{criteria}{criteriaValue}件折扣{discount}",
    "bulk discount item value description": "全店商品數量{criteria}{criteriaValue}件折扣{discount}",
    "bulk discount total": "總共幫您省下",
    "bulk discount when buy items": "當數量{criteria}{criteriaValue}件折扣{discount}",
    "bulk discount when items value": "當金額{criteria}{criteriaValue}件折扣{discount}",
    "buy": "購買",
    "buy now": "直接購買",
    "by": "由 {company}提供",
    "campaign expired": "已結團",
    "cancel": "取消",
    "cancel redirect": "取消自動導入",
    "cancelled": "已取消",
    "cannot apply referral code": "無法使用推薦代碼，請再試一次",
    "cannot refer to someone that already refer you": "您無法推薦已經推薦您的人",
    "cannot reset password": "無法重製您的密碼因為您是由Facebook/Line登入的",
    "cannot send promo code": "無法送出優惠代碼",
    "cannot send referral code": "無法傳送推薦代碼",
    "cannot update subscription": "發生錯誤，無法更新訂閱狀態，請檢查連結或稍後再試",
    "card": "信用卡",
    "cart": "購物車",
    "cash": "貨到付款",
    "cash payment fee": "貨到付款手續費",
    "cashBeforeDelivery": "銀行轉帳",
    "categories": "分類",
    "cc_approved": "刷卡已付款",
    "cc_error_contact_bank": "信用卡授權失敗，請使用另一張信用卡",
    "cc_error_declined": "信用卡授權失敗，請使用另一張信用卡",
    "cc_error_expiration_month": "信用卡到期日輸入錯誤",
    "cc_error_expiration_year": "信用卡到期日輸入錯誤",
    "cc_error_expired": "信用卡已過期，請使用另一張信用卡",
    "cc_error_holder": "信用卡輸入錯誤",
    "cc_error_invalid_card": "信用卡號輸入錯誤",
    "cc_error_number": "信用卡號輸入錯誤",
    "cc_error_security_code": "信用卡安全碼輸入錯誤",
    "cc_error_use_existing_card": "請選擇已存在信用卡，使用另一張信用卡",
    "cc_refundRequested": "刷卡申請退款中",
    "cc_refunded": "刷卡已退款",
    "cc_scheduledPayment": "信用卡自動扣款日",
    "change": "更改",
    "check order status": "訂單查詢",
    "checkout": "結帳",
    "checkout as guest": "免註冊，直接購買",
    "checkout now": "結帳送出",
    "checkout_details": "顧客資料",
    "checkout_payment": "付款",
    "checkout_summary": "購物車內容",
    "checkout_unsuccessful_msg": "很抱歉，結帳發生錯誤，請再試一次！",
    "choose": "選取",
    "choose customizations": "選取客製化",
    "choose expected pickup date": "請選擇取貨日期",
    "choose expectedArrivalDate": "請選擇到貨日期",
    "choose expectedDispatchDate": "請選擇出貨日期",
    "choose options": "選取內容",
    "choose qty": "選取數量",
    "chosen qty": "已選擇",
    "city": "縣市",
    "claim promo code": "取得優惠代碼",
    "clear": "清除",
    "clear all": "全部清除",
    "clear cart": "清除購物車",
    "clear promo code": "清除優惠代碼",
    "clear search conditions": "清除搜尋條件",
    "close": "關閉",
    "company": "公司",
    "companyName": "公司抬頭",
    "completed formStep": "已完成",
    "confirm": "確認",
    "confirmPassword": "確認密碼",
    "connection error": "連線錯誤，請稍後再試",
    "contact us": "聯絡我們",
    "contains an invalid value": "輸入錯誤",
    "contains duplicate value": "輸入錯誤，有重複值",
    "contains recurring products text": "購物車包含定期購產品，是否同意條款並結帳? (詳細條款請點選『顯示更多』)",
    "continue with checkout": "繼續結帳",
    "convenience store brand": "超商品牌",
    "convenience store more": "超商取貨有寄件材積與重量之限制，若超過限制將另行通知並協助修改配送方式，敬請見諒。",
    "convenience store pick up": "超商取貨",
    "copied": "已複製",
    "copied, please wait": "已複製，請稍候...",
    "copy": "複製",
    "copy and track waybill": "自動複製查詢貨態",
    "country": "國家",
    "credit card error": "信用卡錯誤",
    "credit card was entered incorrectly": "信用卡輸入錯誤（請確認信用卡的到期日和其他資料或使用其他信用卡）",
    "creditCard": "線上刷卡 (Visa, Mastercard & JCB)",
    "creditCardExpiryDate": "信用卡到期日 (MM/YY)",
    "creditCardHolder": "信用卡持有人",
    "creditCardNumber": "信用卡號",
    "creditCardOrCreditCardWithInstallments": "信用卡 / 信用卡分期付款 (Visa, Mastercard & JCB)",
    "creditCardSecureCode": "信用卡安全碼",
    "credit_card_bind_required": "Recurring items require credit card to be remembered",
    "credit_card_bind_test_transaction_msg": "提醒您，綁定信用卡時會進行一元的測試交易，並立即退款給您。",
    "credit_card_declined": "信用卡授權失敗，請使用另一張信用卡",
    "credit_card_expired": "信用卡已過期，請使用另一張信用卡",
    "credit_card_failed_possible_reasons": "交易失敗，可能原因為",
    "current_lang": "繁體中文",
    "customer additional info": "其他資料",
    "customer info": "顧客資料",
    "customer service": "顧客服務",
    "customizations": "客製化",
    "date end": "結束日期",
    "date start": "開始日期",
    "dateNewToOld": "日期由新至舊",
    "dateOldToNew": "日期由舊至新",
    "day_closed": "休息",
    "days": "天",
    "delete": "刪除",
    "delivery info": "送貨資訊",
    "delivery time": "配送時間",
    "deliveryTime": "配送時間",
    "description": "敘述",
    "did you unsubscribe by accident": "您是不小心退訂電子報嗎？",
    "discount": "折扣",
    "discount when buy items": "折扣當購買商品",
    "discount when items value": "當購物滿 <金額> 折扣",
    "dispatched": "已出貨",
    "dispatched date": "出貨日",
    "district": "區域",
    "does not contain required value": "輸入錯誤",
    "ecPay": "綠界科技 (分期付款)",
    "edit": "編輯",
    "email": "電子信箱",
    "email already verified": "已完成電子信箱驗證",
    "email unverified": "電子信箱未驗證",
    "email verification required": "須驗證電子信箱",
    "email_verification_code_invalid": "信箱驗證碼無效，請登入並按下重新發送驗證信",
    "email_verification_required_message": "Email驗證未成功，認證信已送達，趕快到信箱裡查看吧。提醒您: 如查無註冊認證信，可能是被歸類為垃圾郵件了，請到垃圾郵件資料夾查看",
    "email_verification_sent": "已發送電子郵件驗證",
    "email_verification_success": "已成功驗證電子郵件",
    "enter again": "再次輸入",
    "enter_reward_points": "輸入會員點數",
    "equals": "等於",
    "error occurred": "發生錯誤，請稍後再試",
    "error occurred, please contact us": "發生錯誤，請與我們聯繫",
    "error occurred, please refresh page": "發生錯誤，請更新頁面",
    "error_address": "地址",
    "error_alternateAddressData": "收件人地址",
    "error_alternateAddressData.name": "收件人姓名",
    "error_alternateAddressData.telephone": "收件人手機號碼",
    "error_ccExpiry": "信用卡到期日輸入錯誤",
    "error_ccNumber": "信用卡號輸入錯誤",
    "error_ccSecureCode": "信用卡安全碼(CCV)輸入錯誤",
    "error_companyName": "公司抬頭",
    "error_country": "國家",
    "error_countryCode": "國家",
    "error_district": "區域",
    "error_email": "電子信箱",
    "error_name": "姓名",
    "error_notes": "訂單備註",
    "error_password": "密碼輸入錯誤",
    "error_paymentLastFiveDigits": "匯款帳號後五碼",
    "error_postCode": "郵編",
    "error_region": "地區",
    "error_street": "地址",
    "error_surchargeId": "附加費用",
    "error_taxNumber": "統一編號",
    "error_telephone": "手機號碼",
    "error_twEInvoiceCarrierId": "載具編號輸入錯誤",
    "error_unverifiedEmail": "未認證電子信箱",
    "error_useDefaultTaxNumber": "統一編號",
    "expected pickup date": "預計取貨日",
    "expectedArrivalDate": "預計到貨日",
    "expectedDispatchDate": "預計出貨日",
    "expired": "已過期",
    "expiring reward points": "即將到期的會員點數",
    "expiry date": "會員獎勵到期日",
    "extras": "加購",
    "facebook customer service": "Facebook客服",
    "familyMart": "全家",
    "faqs": "常見問題",
    "female": "女",
    "fetch user convenience store data": "從使用者資料擷取",
    "filters": "進階篩選",
    "flow_done": "已完成！謝謝！",
    "flow_invalid_input": "invalid input",
    "forgot password": "忘記密碼?",
    "friday": "星期五",
    "game_scratch_card_sorry": "Sorry...",
    "game_scratch_card_title": "刮刮卡",
    "gender": "性別",
    "generating": "產生中",
    "generating promo code": "產生優惠代碼中...",
    "go to home page": "回首頁",
    "greaterThan": "大於",
    "greaterThanOrEqualTo": "大於或等於",
    "have already referred": "Have already referred",
    "hide": "隱藏",
    "home": "首頁",
    "home delivery": "宅配",
    "home page": "首頁",
    "homeDelivery": "宅配",
    "i agree to terms and conditions": "我同意購物規範與隱私權說明",
    "i have a promo code": "我有優惠代碼",
    "i have a referral code": "我有推薦代碼",
    "if you have any concerns, please contact us": "如對訂單有任何疑慮，可聯繫我們",
    "inactive": "已售完",
    "incorrect": "輸入錯誤",
    "incorrect array order": "輸入錯誤",
    "incorrect array order and/or incorrect number of items": "輸入錯誤",
    "incorrect byte": "輸入錯誤",
    "incorrect bytes": "輸入錯誤",
    "incorrect credit card details": "信用卡輸入錯誤",
    "incorrect date": "輸入日期錯誤",
    "incorrect date format": "輸入日期格式錯誤",
    "incorrect date range": "輸入日期範圍不正確",
    "incorrect decimal places": "輸入錯誤，須為數值，小數位不正確",
    "incorrect length": "輸入錯誤",
    "incorrect number": "輸入錯誤，須為數值",
    "incorrect number of items": "輸入錯誤",
    "incorrect uri scheme": "輸入錯誤，網址格式有誤",
    "incorrect value": "輸入錯誤",
    "info": "主頁",
    "input password to preview page": "密碼",
    "installment periods": "分期付款",
    "installmentPayment": "分期付款",
    "installments": "分期付款",
    "insufficient inventory qty": "庫存量不足",
    "invalid": "輸入錯誤",
    "invalid access code": "無效的報表連結",
    "invalid amount of reward points": "會員點數不足兌換購物金數量",
    "invalid credit card": "信用卡號輸入錯誤",
    "invalid credit card or bank processing error": "信用卡輸入錯誤或銀行授權錯誤",
    "invalid login credentials": "您輸入的密碼或電子信箱錯誤",
    "invalid scheduled payment credit card": "使用您的信用卡 (末4碼: {cardNumber}) 時出現錯誤",
    "is not allowed": "輸入錯誤",
    "item": "品項",
    "item is not supported by shipping zones": "該產品不在運送區域內",
    "jcb": "JCB",
    "keep me up to date on news and exclusive offers": "訂閱電子報(接收最新消息及優惠資訊)",
    "language_en": "English",
    "language_tw": "繁體中文",
    "last 1 month": "過去一個月",
    "last 1 year": "過去一年",
    "last 3 months": "過去三個月",
    "last 5 digits of your account": "匯款帳號後五碼",
    "last 6 months": "過去六個月",
    "leave a message": "留言",
    "limited qty per checkout": "銷售數量限制: {total}",
    "limited qty per person": "每人限量",
    "limited_campaign": "限時優惠",
    "limited_campaign_ended": "已結團",
    "limited_campaign_until": "至{dateEndZ}",
    "line customer service": "Line客服",
    "line pay": "Line Pay",
    "linePay": "Line Pay",
    "loading": "loading",
    "login": "登入",
    "login-remind-message": "只需註冊一次，就可以在所有{BigordrLogo}的網站上輕鬆結帳！",
    "loginByBigOrdr": "會員登入",
    "loginByFB": "Facebook 登入",
    "loginByLine": "Line 登入",
    "logout": "登出",
    "male": "男",
    "mastercard": "MasterCard",
    "max characters": "字數限制",
    "maximum reward points that can be applied on this order": "本次訂單可使用的會員點數上限",
    "member country is not supported, please send to different address": "無法支援會員的國家，請寄送至不同地址或前往會員頁面修改資料",
    "member_level": "會員等級",
    "message": "訊息",
    "minimum order to enable shipping": "訂單最低「{rate}」才能配送到您的地址",
    "minimum spend to apply reward points": "使用會員點數最低消費金額",
    "minimum total for installment": "使用分期付款，最低總金額：{amount}",
    "mobile number": "手機號碼",
    "monday": "星期一",
    "more": "更多",
    "multiplied": "Multiplied",
    "must be a bigger number": "輸入錯誤，必須是一個更大的數字",
    "must be a boolean": "輸入錯誤",
    "must be a buffer or a string": "輸入錯誤",
    "must be a credit card": "必須是信用卡號",
    "must be a float or double": "輸入錯誤，須為數值，可有小數點",
    "must be a negative number": "輸入錯誤，須為數值，必須是負數",
    "must be a number": "輸入錯誤，須為數值",
    "must be a positive number": "輸入錯誤，須為數值，必須是正數",
    "must be a smaller number": "輸入錯誤，必須是一個更小的數字",
    "must be a string": "輸入錯誤，須為字串",
    "must be a valid GUID": "必須是有效的GUID",
    "must be a valid ISO 8601 date": "必須是有效的ISO 8601日期",
    "must be a valid base64 string": "必須是有效的base64字串",
    "must be a valid date": "輸入日期格式錯誤",
    "must be a valid email": "輸入錯誤，電子信箱格式不正確",
    "must be a valid hostname": "必須是有效的主機名",
    "must be a valid ip address": "必須是有效的IP地址",
    "must be a valid port": "輸入錯誤，必須是有效的連接埠",
    "must be a valid relative uri": "輸入錯誤，必須是網址",
    "must be a valid timestamp or number of milliseconds": "輸入錯誤，時間格式須為毫秒數",
    "must be a valid timestamp or number of second": "輸入錯誤，時間格式須為秒數",
    "must be a valid uri": "輸入錯誤，必須是有效的網址",
    "must be an array": "輸入錯誤",
    "must be an integer": "輸入錯誤，須為數值，不可有小數點",
    "must be unicode normalized": "必須是unicode",
    "must exclude value": "輸入錯誤",
    "must not have leading or trailing whitespace": "前後不可有空格",
    "must only contain alpha-numeric and underscore characters": "輸入錯誤，只可包含字母、數字與底線",
    "must only contain alpha-numeric characters": "輸入錯誤，只可包含字母與數字",
    "must only contain hexadecimal characters": "必須只包含十六進制字串",
    "must only contain uppercase characters": "只能包含大寫字母",
    "name": "姓名",
    "new password": "新密碼（至少8個字符)",
    "newestFirst": "上架日期由新到舊",
    "news": "最新消息",
    "next page": "下一頁",
    "next step": "下一步",
    "night": "晚上",
    "no": "否",
    "no email or ref value": "電子信箱或訂單標號為空白",
    "no matched results": "無符合結果",
    "no more choices, please decrease other item qtys": "無法繼續選擇，請先減少其他商品數量",
    "no order found": "沒找到訂單",
    "no product found": "沒有找到產品，重新導回至商店頁。",
    "no products found": "沒有找到產品",
    "none": "無",
    "noon": "下午",
    "not email": "電子郵件格式錯誤",
    "not matching any of the allowed alternatives": "輸入錯誤",
    "not numeric": "輸入錯誤",
    "notes": "訂單備註",
    "notes_eg": "訂單備註 (如：管理員代收)",
    "number of games remaining": "Number of games remaining",
    "ok": "確定",
    "onSale": "特價中",
    "onlyOnSale": "特價中",
    "ooops, page not found": "很抱歉，目前我們無法找到此頁面",
    "oops sorry": "啊~~Sorry",
    "open hours": "營業時間",
    "opening points": "初始點數",
    "optional": "非必填",
    "or": "或",
    "order date": "訂購日期",
    "order details": "訂單資料",
    "order history": "訂單歷史",
    "order number": "訂單號碼",
    "order status": "訂單狀態",
    "order was emailed to": "已發送到此信箱",
    "order_messages_sent": "感謝您的留言！",
    "order_successful_msg": "謝謝您！已收到您的訂購資料",
    "other": "其他",
    "out of stock": "缺貨中",
    "out of stock: removed from cart": "已售完: 已移除",
    "outOfStock": "缺貨中",
    "over limited qty": "已售完",
    "over limited qty per checkout": "已超過銷售數量限制",
    "page status": "狀態",
    "page_active": "有效的",
    "page_inactive": "無效的",
    "password": "密碼",
    "password (at least 8 characters)": "密碼（至少8個字符）",
    "password updated successfully": "密碼更新成功",
    "passwords error": "您輸入的密碼有錯誤",
    "pay before payment due date": "付款已排定於 {date}，若變更完成，將會改為現在付款，您確定要變更預設卡片?",
    "pay in installments": "我要分期付款",
    "pay now": "付款",
    "pay to": "收款人資料",
    "pay_by_link": "變更預設卡片",
    "payer": "付款人",
    "payment banking details": "選擇ATM轉帳的客戶，請匯款至以下帳號",
    "payment due date": "下期自動扣款日",
    "payment method": "付款方式",
    "payment successful": "付款成功",
    "paymentMethod_cash": "貨到付款",
    "paymentMethod_cashBeforeDelivery": "ATM轉帳",
    "paymentMethod_creditCard": "信用卡",
    "paymentMethod_free": "免費",
    "paymentMethod_linePay": "Line Pay",
    "paymentMethod_other": "其他",
    "payment_unsuccessful_msg": "很抱歉，您所輸入的信用卡資料有誤，請確認您的信用卡資料或以其他方式付款",
    "payuni_card_redirect": "{companyName}串接PAYUNi統一金流，將於下個頁面跳轉至信用卡資訊輸入頁面。",
    "pending": "未完成",
    "pending_reward_points": "待定會員點數",
    "pick up method": "取貨方式",
    "pick up store": "取貨商店",
    "please enter full name": "請務必輸入真實姓名，確保收件資料正確",
    "please make a selection": "您有需選擇項目，尚未選取！",
    "please search again": "請重新查詢",
    "please select": "請選擇",
    "please wait": "請稍候",
    "point can be used directly": "點數可以在結帳時直接以購物金使用",
    "point cannot be used directly": "點數在使用前必須先驗證",
    "points": "{point} 點",
    "popular": "熱銷商品",
    "postCode": "郵遞區號",
    "pre order": "預購",
    "preOrder": "預購",
    "preferred delivery time": "偏好的送達時間",
    "preparingForDispatch": "處理中",
    "previous page": "上一頁",
    "previous step": "上一步",
    "price": "單價",
    "priceAsc": "價格由低至高",
    "priceDesc": "價格由高至低",
    "prices": "價格",
    "prices_starting_from": "{price} 起",
    "prices_starting_from_only_one": "{price}",
    "privacy policy": "隱私權政策",
    "problems with auto filling credit card details": "有些瀏覽器可能無法自動填入信用卡 (請手動輸入信用卡資料，或是嘗試使用其他瀏覽器，例如Safari或Chrome，避免在Instagram、Facebook或其他社交媒體網站瀏覽本網站)",
    "proceed with payment": "點此轉入銀行刷卡頁面",
    "processing": "準備中",
    "product code": "產品編碼",
    "product info": "產品介紹",
    "productsArea": "商品區",
    "profile": "個人資料",
    "promo code": "優惠代碼",
    "promo code applied": "我們已經幫您設定好優惠代碼",
    "promo code change email warning": "更改信箱將導致優惠代碼無法使用",
    "promo code claimed": "已取得優惠代碼",
    "promo code has been sent": "優惠代碼已送出",
    "promo code may be expired or invalid for items in cart": "您的優惠代碼錯誤，也有可能優惠效期已過，或是您選的商品已為優惠價，不可再折價！",
    "promo code may be expired, invalid for items in cart or has been used": "您的優惠代碼錯誤，也有可能已使用，或優惠效期已過，或是您選的商品已為優惠價，不可再折價！",
    "promo codes": "優惠代碼",
    "promo_code_applied": "優惠代碼已被使用",
    "promo_code_can_be_used_on_sale": "可和特價商品共同使用",
    "promo_code_cannot_be_used_on_sale": "不可和特價商品共同使用",
    "promo_code_date_start": "可使用起始日",
    "promo_code_description": "敘述",
    "promo_code_discount": "折扣",
    "promo_code_expiry_date": "到期日",
    "promo_code_from": "從",
    "promo_code_minimum_amount": "最低消費金額",
    "promo_code_not_found": "查無優惠代碼",
    "promo_code_onsale_discount": "特價中商品折扣",
    "promo_code_usage": "使用",
    "promo_code_usage_limit": "使用限度",
    "promo_code_usage_limit_per_person": "每人使用限度",
    "promo_code_valid_from": "Valid From",
    "promo_code_valid_till": "Valid Till",
    "promotion is already over": "活動已結束",
    "qty": "數量",
    "qty sold": "已售出",
    "re-subscribe": "重新訂閱",
    "receivers address": "收件人地址",
    "receivers mobile number": "收件人手機",
    "receivers name": "收件人姓名",
    "recent": "最新排序",
    "recipient": "收件人",
    "recommended": "推薦排序",
    "recurring": "定期購",
    "recurring order": "定期購訂單",
    "recurring orders": "定期購訂單",
    "redirect to another page": "立即導入其他優惠商品",
    "redirecting to another page": "頁面即將導入其他優惠商品",
    "referenced field is not a date": "輸入日期格式錯誤",
    "referenced field is not a number": "輸入錯誤，須為數值",
    "referral code": "推薦代碼",
    "referral code does not exist": "推薦代碼不存在",
    "referral code has been sent": "已傳送推薦代碼",
    "referral code limit reached": "已達到推薦代碼上限",
    "referral link": "推薦連結",
    "referral reward": "推薦獎勵",
    "referral_code_apply": "使用推薦代碼",
    "referral_code_email_required": "需先填入信箱來使用推薦代碼",
    "referral_code_enter": "輸入推薦代碼或推薦連結",
    "referral_code_minimum_order": "訂單須達到「{spendRequirementAmount}」才能使用優惠代碼",
    "referral_code_recipient_benefit_amount": "獲得折「{rewardAmount}」的優惠代碼",
    "referral_code_recipient_benefit_rate": "獲得折扣「{rewardRate}%」的優惠代碼",
    "referral_code_recipient_benefit_rate_onsale": "獲得折扣「{rewardRate}%」(特價商品則是 「{rewardRateOnSale}%」) 的優惠代碼",
    "referral_code_refer_friends": "推薦好友",
    "referral_code_referred_by": "您由{name}所推薦",
    "referral_code_referrer_benefit_amount_as_points": "獲得「{earnAmount}」的獎勵點數",
    "referral_code_referrer_benefit_amount_as_promo_code": "獲得折「{earnAmount}」的優惠代碼",
    "referral_code_referrer_benefit_for_every_success_order": "每筆使用您推薦代碼的成功訂單",
    "referral_code_referrer_benefit_rate_as_points": "獲得好友訂單總額「{earnRate}%」的獎勵點數",
    "referral_code_referrer_benefit_rate_as_promo_code": "獲得折扣「{earnRate}%」的優惠代碼",
    "referral_code_share_by_email": "Email 分享推薦代碼",
    "referral_code_share_by_link": "使用連結分享推薦代碼",
    "referral_reward_recipient": "被推薦人",
    "referral_reward_referrer": "推薦人",
    "refresh page error message": "發生錯誤，請重新整理頁面。如果問題持續存在，請聯繫我們。",
    "region": "地區",
    "register": "註冊",
    "related": "相關",
    "remaining choices": "未選取數量",
    "remaining qty": "剩餘可選數量",
    "rememberCreditCard": "記住信用卡",
    "remove item": "移除",
    "renew credit card": "請更新您的信用卡資訊",
    "required": "必填",
    "resend verification email": "再次寄出確認信",
    "reset password": "重設密碼",
    "resetFilters": "清除條件",
    "reveal": "顯示",
    "reward event": "獎勵類型",
    "reward history": "獎勵歷史紀錄",
    "reward point cannot be used with promo code": "會員點數不能與折扣碼同時使用",
    "reward points available after registration": "已紀錄會員點數，但需要先註冊會員才能使用",
    "reward points earned": "獲得的會員點數",
    "rewardSpendDifference": "差「{spendAmount}」就可使用會員點數囉!",
    "reward_adjustment": "調整",
    "reward_adjustment_points": "調整點數",
    "reward_birthday": "生日",
    "reward_birthday_points": "生日點數",
    "reward_current_points": "現有點數",
    "reward_earned_points": "會員點數",
    "reward_expire_soon": "即將到期",
    "reward_no_data": "該期間並無獎勵歷史紀錄",
    "reward_opening": "期初點數",
    "reward_opening_points": "初始點數",
    "reward_order": "訂單",
    "reward_points": "會員點數",
    "reward_promotion": "促銷",
    "reward_promotion_points": "促銷點數",
    "reward_referral": "介紹朋友",
    "reward_referral_points": "推薦點數",
    "reward_return": "返還",
    "reward_returns": "返還",
    "reward_signup": "註冊",
    "reward_signup_points": "註冊點數",
    "reward_spent_points": "使用點數",
    "reward_use_points_before_expired": "請您在過期前使用點數！",
    "saturday": "星期六",
    "save": "儲存",
    "search": "搜尋",
    "search by district": "依行政區搜尋",
    "search by store": "依商店搜尋",
    "search results": "搜尋結果",
    "select": "選擇",
    "select below": "請從下列選擇",
    "select convenience store": "請選擇取貨超商門市",
    "select periods": "選擇期間",
    "selected": "已選取",
    "send": "發送",
    "send email": "寄電子信",
    "send message": "留言",
    "send to different address": "寄送到其他地址",
    "sevenEleven": "7-11",
    "shipping details": "配送細節",
    "shipping fee": "運費",
    "shipping fees is quoted later": "運費會在下單後報價",
    "shipping fees will be calculated after your address is selected": "運費將會在選擇配送地址後計算",
    "shippingFeeDifference": "差「{amount}」就可免運費嘍!",
    "shippingFeeDifferenceQty": "再買 {amount} 的產品就可以免運!",
    "shippingFeeDifferenceValue": "差「{amount}」就可免運費嘍!",
    "shippingFeeDifferenceWeight": "再買 {amount} 重量的產品就可以免運!",
    "shop": "商店",
    "shop_dot_com_message_default": "提醒您，以下都是詐騙：訂單設錯、訂單金額有誤、設成分期每月扣款、重覆訂購多筆、宅配或超商出錯、簽收單簽成簽帳單、條碼刷錯、重覆扣款。歹徒會以更改訂單要求退費給您為由，要求您操作 ATM，這些都是詐騙手法。若遇可疑來電，隨時可撥打 165 反詐騙諮詢專線。",
    "shop_dot_com_message_header": "來自美安台灣(SHOP.COM)的訊息",
    "show more": "顯示更多",
    "show reward history": "顯示獎勵歷史紀錄",
    "signupByBigOrdr": "會員註冊",
    "single time purchase": "單次購買",
    "sitemap": "網站地圖",
    "sold out": "已售完",
    "soldOut": "已售完",
    "sorry, no products found": "很抱歉，目前找不到該商品",
    "sorting": "排序",
    "specifications": "規格",
    "spent reward points": "套用會員點數",
    "start": "開始",
    "start game": "開始遊戲",
    "status": "狀態",
    "store location": "實體通路",
    "store location pick up": "實體通路取貨",
    "store pick up": "到店取貨",
    "storeId": "店號",
    "storeLocationPickup": "實體通路取貨",
    "storeName": "店名",
    "street or address": "街道或地址",
    "submit": "確定送出",
    "subscribe": "訂閱",
    "subscribe mailing list": "您已經成功重新訂閱電子報，接下來您會開始收到我們的信件",
    "subtotal": "小計",
    "successfully submission": "您的表單已成功提交!",
    "sunday": "星期日",
    "support": "支援服務",
    "surcharge": "附加費用",
    "tap the right side to skip": "點擊圖片右邊到下一頁",
    "tax number": "統一編號",
    "tax number may be incorrect": "找不到統編",
    "tax number short": "統編",
    "telephone": "手機號碼",
    "terms and conditions": "條款與規範",
    "the bank may be under maintenance": "銀行可能正在維護中（請稍後再次嘗試）",
    "the merchant may have changed this product": "賣家可能有改此產品，請重新選擇",
    "threw an error when running default method": "輸入錯誤",
    "thursday": "星期四",
    "to": "收方",
    "total": "總計",
    "total periods": "總共期數",
    "track waybill": "托運單查詢",
    "transporter": "物流公司",
    "try again": "再試一次",
    "tuesday": "星期二",
    "twEInvoice": "電子發票",
    "twEInvoiceCarrierId": "載具編號",
    "twEInvoiceCarrierId_b2b": "統一編號 (共8碼數字)",
    "twEInvoiceCarrierId_citizenDigitalCertificate": "載具編號 (共16碼大寫英數字，前2碼為大寫英文，後14碼為數字)",
    "twEInvoiceCarrierId_mobile": "載具編號 (請輸入包含 \"/\" ，共8個位元碼)",
    "twEInvoiceCarrierType": "電子發票類別",
    "twEInvoiceCarrierType_optional": "電子發票類別",
    "tw_eInvoice_carrierType_b2b": "營業人發票",
    "tw_eInvoice_carrierType_b2c": "個人發票",
    "tw_eInvoice_carrierType_mobile": "手機條碼",
    "tw_eInvoice_carrierType_person": "自然人憑證",
    "tw_eInvoice_carrier_gov_link": "財政部電子發票流程說明",
    "tw_eInvoice_carrier_help": "*依法規定，個人發票一經開立，不得更改或改開公司戶發票。",
    "tw_eInvoice_print": "列印電子發票",
    "tw_eInvoice_upload": "預設會員載具",
    "unlock": "解鎖",
    "unsubscribe": "退訂",
    "unsubscribe mailing list": "您已經成功退訂電子報，您不會再收到我們的信件",
    "update qty": "更新數量",
    "use a different card": "使用另一張卡",
    "use default tax number": "使用預設統編",
    "use different delivery info": "使用不同的送貨資訊",
    "use id name when choosing convenience store pick up": "超商取貨請務必填寫與證件相符之真實姓名",
    "use new credit card": "新增信用卡資訊",
    "use stored card": "使用已儲存信用卡",
    "valid_reward_points": "有效會員點數",
    "validation error": "{key}: 輸入錯誤",
    "validity date": "有效期限",
    "value is required": "必填",
    "view details": "詳細資料",
    "view or leave messages": "查看或留言",
    "virtual account bank code": "分行",
    "virtual account bank name": "銀行",
    "virtual account expiry date": "最後可轉帳日期",
    "virtual account number": "虛擬帳號",
    "virtual_account_generate_info": "當您按下「結帳送出」按鈕後，就會出現虛擬帳號，再請以此帳號進行繳費。",
    "virtual_account_provider_bsp": "永豐商業銀行(807)",
    "virtual_account_provider_eSun": "玉山銀行虛擬帳號",
    "virtual_account_provider_ecPay": "綠界科技虛擬帳號",
    "virtual_account_provider_payuni": "PAYUNi統一金流虛擬帳號",
    "virtual_account_provider_ubot": "聯邦銀行虛擬帳號(803)",
    "visa": "VISA",
    "waybill": "托運單",
    "we currently do not ship to this region": "我們目前無法運送到您的地區",
    "wednesday": "星期三",
    "you have already got a promo code": "您已經領過優惠代碼",
    "you have already played the game": "你已經玩過了!",
    "your last 5 digits of your account": "請提供匯款帳號後五碼（會計對帳使用）",
    "your login link is no longer valid": "您的登入連結已失效"
  };
  _exports.default = _default;
});